/* 7.5 */
/* 11.24.24 Login-form and AII styles should be here */

:root {
	/*cat*/
	--blue: #202a67;
	--myyellow: #f9a01c;
	--white: #fffdeb;
	--ochre: #d6761b;
	--light-blue: #938e9d;
	--light-yellow: #fccf85;
	/*bird*/
	--mainbody: #00bcd4;
	--mouth: #000000;
	--feather: #b9b7b7;
	--beak-top: #e9581a;
	--beak-down: #aa4c21;
	--leg: #00adef;
	--body-color: #e3edf7;
	--topOffset: -9rem; /*for consierge*/
	color: black;
	--form-control-color: #00adef;
	--catcolor: #B3A7D9;
	/* --catcolor: #00ccff; */
	/* --catcolor: #80e6ff; */
	--koeff : 1;
	--aiTextareaHeight : 70;
	--aiVisibility : visible;
	--aiOpacity : 1;
	--aiArrowOpacity : 1;
	--aiSendArrowHeight : 30;
	--conciergeLignHeight : 2.5;
	--conciergeImageHeight : 100vh;
    --conciergeImageBackgroundSize : 118%;
}

*, *:before, *:after {
	box-sizing: border-box;
}

.rootdiv{
	width: 100%;
	/* height: 100%; */
	margin: 0;
	padding: 0;
}

body {
	color: #333;
	width: 100%;
	margin: 0!important;
	padding: 0!important;
	background-image: url(./images/vectorstock_25083512_edit_bkgr-original.svg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	overflow: auto; 
	font-size: 16px !important;
	font-weight: 400;	
}

.App {
	text-align: center;
}

.App-div{
	background-color: #fff;
	height: 100vh;
}

/* this style if for shared branding image */
/* Remove if causing any issues */
img{
	background-color: transparent;
}

h6{
	margin-bottom: 1rem!important;
	margin-top: 1rem!important;
}

a{
  	color:rgb(22, 99, 214)!important;
}

a:hover {
	color: #01448b;
	text-decoration: none!important;
}

.modal {
	cursor:default;
}

p, textarea{
  margin-bottom: 0.5rem!important;
  word-break:break-word;
}

.modal-body{
	cursor:default;
	color: #333;
}

.modal-myheader{
	display: flex;
	align-items: center;
	line-height: unset!important;	
	cursor: default;
}	

.modal-myheader .modal-close{
	float: left;
	cursor: pointer;
}

.modal-myheader p{
	float: left;
	font-size: 18px;
	font-weight: 400;
	margin: 0;
	margin-bottom: 0!important;
}

.modal-myheader h5{
	padding: 1rem 0.3rem!important;
}

.pointer-view {
  	cursor: pointer;
}

.pointer-noview{
  	cursor: default!important;
}

.hidden, .hide{
  	display: none;
}

.hide-with-v{
	visibility: hidden;
}

.btn.disabled, .btn:disabled {
    opacity: .4!important;
}

.x-input::placeholder {
	color: rgb(51, 51, 51, 0.5) !important;
}

.x-input {
	color: #333 !important;
} 

input:-webkit-autofill {
  -webkit-text-fill-color: #333 !important;
  font-size: calc(1rem * var(--koeff))!important;
}

.attention{
	color: var(--beak-top)!important;
}

.bb-1, .post-row{
	border-bottom: 1px solid #f7f7f7;
}

.bt-1{
	border-top: 1px solid #f7f7f7;
}

.br-1{
	border-right: 1px solid #f7f7f7;
}

::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder  { 
	color: #555!important;
}

.btn-mea{
	font-family: 'Mea Culpa'; 
	/*src: url('https://ocafprod.s3.us-east-2.amazonaws.com/font/MeaCulpa-Regular.ttf') format('truetype');*/ 
	src: url('css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
	font-size: calc(2.5rem * var(--koeff))!important;
	font-weight: 400!important;
	padding: 0 10px!important;
	border: 1px solid #fff!important;
	background-color: transparent!important;
	color: #fff!important;
	width: 100%;
}

/*Edit Profile*/

.alignflex{
	line-height: unset!important;	
	padding-bottom: 1rem;
}

.alignflex .modal-myheader h6, .modal-myheader h6{
	font-size: 1.125rem;
	margin-top: 0!important;
	margin-bottom: 0!important;
	color: rgb(22, 99, 214);
}

.alignflex .action-name{
	margin-top: 0.5rem!important;
    float: left;
}

.alignflex .btn{
	float: right;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input:focus, textarea:focus, select:focus{
	outline: none;
}

span{
  margin-left: 0!important;
}

label{
	margin-bottom: 0!important; 
}

.left{
  	float: left;
}

.right{
  	float: right;
}

.font-small{
  	font-size: 0.9rem;
}

textarea[name=createpost], [name=createcomment]{
	resize: none;
	min-height: 22vh;
  	height: auto;
	border: none;
	width: 100%;
  	color: #333;
	font-size: 1rem;
	line-height: 1.5;
	padding: 0.5rem;
} 

.createtextarea{
	padding-left: 0.5rem!important;
	width: 90%;
}

.bottom-bar{
	width: 90%;
}

.bottom-bar-placeholder{
	width: 40px;
}

.createcommentarea{
	padding-left: 0.5rem!important;
	width: 90%;
}

.counter-d{
	border: 2px solid;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	font-size: 0.7rem;
	line-height: 2.2;
	float: right;
}

.counter-d p{
	margin-bottom: 0;
}

#counter{
	text-align: center;
}

.row{
	margin-right: 0!important;
	margin-left: 0!important;
}

.center-vert{
   vertical-align: middle;
}

.pl-0{
 	 padding-left: 0!important;
}

.after-app-wrap{
	width:1200px;
	margin:auto;
}

.after-app-wrap-minus-nav{
	position: relative;
	margin-left: 250px;
	padding: 0;
} 

.after-app-wrap-minus-nav-groups{
	position: relative;
	margin-left: 250px;
	padding: 1rem;
}

.after-app-wrap-center-div, .after-app-wrap-center-div-gotop, .after-app-wrap-center-div-comments {
	position: relative;
	margin: auto;
	width: 1000px;
	padding: 1rem;
	text-align: left;
	top: 55px;
	margin-bottom: 2rem;
} 

.after-app-wrap-center-div-comments {
	height: 100vh;
}

.after-app-wrap-center-div-cu{
	position: relative;
	margin: auto;
	width: 1000px;
	padding: 1rem;
	text-align: center;
	margin-bottom: 2rem;
}

.after-app-wrap-images-div {
   position: relative;
	margin: auto;
	width: 968px;
	text-align: left;
	top: 55px;
	margin-bottom: 2rem;
}

.after-app-wrap-center-div-for-profile{
	position: relative;
	margin: auto;
	width: 1000px;
	background: rgba(255,255,255,0.9); 
	padding-top: 50px;
	padding: 1rem;
	text-align: left;
}

.after-app-wrap-center-div h5{
	padding: 1rem 0;
	margin-bottom: 0;
}

.after-app-wrap-center-div-inst{
	position: relative;
	margin: auto!important;
	width: 1040px;
	padding: 0!important;
}  

.after-app-wrap-walk-newuser-container{
	width: 1000px;
	margin: auto;
	height: auto;
}

.after-app-wrap-walk-newuser {
	background: url(images/vectorstock_25083512_edit_newuser2.svg) -22px -20px;
	background-repeat: no-repeat;
	position: relative;
	margin: auto;	
	padding-top: 1rem;
	height: 420px;
	overflow: hidden;
	background-size: contain;
} 

 .after-app-wrap-center-basic{
	position: relative;
	margin: auto;
	width: 1000px;
}

/*Version with images on the site*/

.after-app-wrap-center-login-div-walk-signup{
	position: relative;
	margin: auto;
	width: 1400px;
	padding-top: 1rem;
	height: 700px;
} 

.after-app-wrap-center-login-div-walk-signup img{
  	height: auto!important;
}

/*these styles are on only for walk*/

.App-logo {
 	pointer-events: none;
}

.suggested{
  	padding: 0.8rem;
}

.suggested button{
	appearance: none;
	background: 0;
}

.suggested img{
  	position: relative;
}

.suggested p{
  	margin: auto;
}

.suggested a p{
	margin:auto;
	font-size: 15px;
}

.suggested .username{
	font-weight: 500;
}

.suggested .tagline, .postcard .small{
	color: #333;
}

.suggested .username, .suggested .tagline{
	text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
}

.App-header {
	background-color: #282c34;
	min-height: 20vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Medeata styles*/

/*General*/

a {
	word-break: break-all;
}

ul{
  list-style-type: none;
  margin-bottom: 0;
  width: 100%;
}

.center{
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
} 

.center-eup{
	text-align: center;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.search-center{
	text-align: center;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 650px;
	margin: auto;
}

.search-center input{
	border: 1px solid #00bcd4;
}

.medium{
	font-size: 14px;
}

.text-left{
  text-align: left;
}

.bold{
  	font-weight: 600!important;
}

.mt-45{
  	margin-top: 2rem!important;
}

.mt-6{
  	margin-top: 4rem!important;
}

.bl-0{
  	border-left: none!important;
}

.bt-1{
  	border-top: 1px solid #ccc!important;
}

.bb-1{
	border-bottom: 1px solid #ccc!important;
}

.pr-3{
	padding-right: 1.5rem;
}

.white{
  	background-color: #fff;
}

.vertalign {
  	margin: auto 0.5rem;
}

textarea{
	width: 100%;
}

.modal-header{
	border-bottom: 0!important;
}

/*Nav*/

.nav{
	padding: 0rem 1rem!important;
	height: 55px;
	background: #fff;
	box-shadow: 0px 1px 2px rgb(207 207 212 / 50%);
	color: #333;
	position: fixed;
	width: 100%;
	z-index: 1000;
	vertical-align: middle;
	line-height: 0.5;
	margin-bottom: 1rem!important;
} 

.nav .nav-item{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 2rem;
}

.nav .navitem-dropdown{
	padding-right: 1rem;
}

.nav .nav-item .dropdown {
	line-height: 1;
	padding: 0!important;
	padding-right: 0;
}

.nav .dropdown button, .nav .dropdown .btn{
	padding: 0;
	line-height: 1;
}

.nav .medeata-desktop{
	height: 55px;
	position: fixed;
	left: 0;
} 

.medeata-desktop{
	height: 50px;
	position: relative;
	margin: auto;
}

.nav .medeata-mobile{
	display: none;
}

.nav-link{
	display: block;
	padding: 0!important;
}

.nav-link .nav-svg{
	width: 25px!important;
	height: 25px!important;
	color: rgb(22, 99, 214)!important;
}

.nav-item .nav-settings{
  	float: left;
}

.nav-links{
	position: fixed;
	background: #fff;
	box-shadow: 0px 1px 2px rgb(207 207 212 / 50%);
	width: 100%;
	z-index: 1000;
}

.nav .clicked-user{
	z-index: 1000;
	box-sizing: border-box;
}

.nav .nav-item .clicked-user .avatar-small-landscape, 
.nav .nav-item .clicked-user .avatar-small-portrait{
	border: 1px solid #45AAFF!important;
}

ul li{
  display: inline;
}

.nav-links a{
	padding: 0 1rem;
	float: left;
	color: #f2f2f2;
	text-align: center;
	text-decoration: none;
	font-size: 17px;
}

.nav-links a p{
	padding: 0.7rem;
	margin-bottom: 0;
}

.nav ul{
  	margin-bottom: 0;
}

.nav-avatar{
  	width: 7vw;
}

.nav .nav-icon, .nav-links .nav-icon {
	font-size: 1.7rem;
	color: rgb(22, 99, 214)!important;
}

/*Login*/

.login-background .login-form{
	width: 25vw;
	margin: 20% 0;
	float: right;
	/* display: flex; */
	/* justify-content: center;  Horizontally center */
	/* align-items: center;     Vertically center */
	/* height: 100vh; */
	position: relative;
	border: 0.5px solid #d9d9d9;
	padding: 1.5rem;
	background-color: rgb(0 88 129 / 26%);
} 

.login-background h1{
	display: flex;
	text-align: left;
	justify-content: center; 
	align-items: center;     
	height: 100vh;
	color: white;
	font-size: 4rem;
}

.signup-form{
	width: 25vw;
	margin: 5rem auto;
	position: relative;
} 

.login-form-row-label{
  	height: 87px;
}

.login-form-row-label-bio{
	height: 105px;
	word-wrap: break-word;
}

.login-form-row-label-tag{
	height: 85px;
	word-wrap: break-word;
 }

.login-form-row-label-bio textarea{
	border: 1px solid #ccc;
	padding: 0.3rem 0.75rem;
	font-size: 15px;
}

.login-form-row-no-label {
  	height: 72px;
} 

.a-login-signup {
	margin-left: -2.5rem;
}

.login-form-row{
  	height: 82px;
}

.login-form-row label, .login-form-row-label-bio label,
.login-form-row-label-tag label {
	float:left;
	font-size: 14px;
	margin: 0.1rem 0!important;
}

.login-nav{ 
	height: 2em;
	width: 50vW;
	margin: 0.5rem auto!important;
	padding-left: 0;
} 

.login-nav li{ 
	display: inline; 
	margin: 0 1rem;
} 

/*Lists*/

.item{
  	list-style: none;
}

/*Comments*/

.comment-div {
	width: 100%;
	border-top: 1px solid #ccc;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin: 0;
	padding: 0;
	position: relative;
} 

.comment-div-noborder {
  	width: 100%;
  	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin: 0;
	padding: 0;
	position: relative;
} 

.comment{
	padding: 1rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
	position: relative;
}

.comment .emoji{
  	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background: 0 0;
	border: 0;
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 8px;
}

.comment textarea{
  width: 100%;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  resize: both; 
  resize: vertical;
}

.comment button, .suggested button{
  	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: 0 0;
	border: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	padding: 5px 9px;
	text-align: center;
	text-transform: inherit;
	text-overflow: ellipsis;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: auto;
	color:rgb(22, 99, 214);
}

.comment-new{
	text-align: left;
	background-color: #fff;
	border-radius: 5px;
	margin-bottom: 0.5rem;
	padding: 1rem;
	width: 95%;
	float: right;
}

/*Overwriting forms*/

.form-control{
	padding-bottom: 0.6rem!important;
	/* o color: #333!important; */
}

.login-form-row-label-bio textarea:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
 }

.form-control, .login-form-row-no-label input:focus, .login-form-row-no-label input{
	font-size: 1rem!important;
}

/*Posts*/

.row-margin-0{
	margin-right: 0!important;
	margin-left: 0!important;
}

.talkbubble {
	max-width: 47vw;
	background: #fff;
	position: relative;
	border-radius: 10px;
	text-align: left;
	padding:0.5rem 0.5rem 1rem;
	border: 1px solid #e3e1e1;
	margin-bottom: 0.5rem;
}

.talkbubble .row{
  margin: 0;
}

.triangle-left {
	position: relative;
	top: 10px;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right: 15px solid #e3e1e1;
}

.inner-triangle {
	position: relative;
	top: -23px;
	left: 1px;
	width: 0;
	height: 0;
	border-top: 23px solid transparent;
	border-bottom: 23px solid transparent;
	border-right: 23px solid #fff;
	z-index: 900;
}

.row-margin-15{
	margin-left: 1rem!important;
	margin-right: 1rem!important;
}

.row-margin-post{
	margin-left: 3rem!important;
	margin-right: 1rem!important;
}

.row-less-margin .follow, .icon-div button, button{
  	appearance: none;
	background: 0 0;
	border: 0;
}

.other-mind{
  	min-height:15vh;
}

.other-mind img, .comment-div img{
  	width: 100%;
}

.image-div{
	max-width: 295px!important;
	max-height: 295px!important;
	padding:30px!important;
	overflow: hidden;
	position:relative;
}

.image-top-div{
	width: 100%;
	float: left;
	border: 1px solid #ccc;
	text-align: left;
	background-color: #fff;
	margin: auto;
	margin-bottom: 1rem;
}

.image-top-div-collection{
	width: 78%;
	float: right;
	height: 100%;
	margin-bottom: 0.5rem;
	border: 1px solid #ccc; 
	text-align: left;
	background-color: #fff;
}


.image-top-div .communique {
	width: 30%;
	float: right;
	padding: 1rem;
}

.cropped {
	float: left;
	width: 70%; 
	height: 100%; 
	object-fit: cover;
}

.image-text-div{
	overflow: hidden;
	position:relative;
	padding-bottom: 2rem;
	margin: 0!important;
	float: left;
	height: 319px;
	padding-bottom: 1px;
	background-color: #fff;
}

.image-text-div img{
	width: 319px!important;
	height: 319px;
	position:relative;
	object-fit: cover;
}

.image-text-div button{
	padding: 1px;
}

.image-text-div a:hover{
  	background-color: black;
}

.image-text-div .card, .image-text-div a{
	text-align: left;
	color: #333!important;
}

.image-text-div .card{
	width: 315px!important;
	height: 315px!important;
	position:relative;
	padding: 0!important;
	margin: 0!important;
}

.image-text-div .card .card-border{
	border-bottom: 2px solid #1a5ab9;
	width: 315px;
	height: 315px;
}

.image-text-div .card p{
  	padding: 1rem;
}

.image-text-div button{
	background: none;
	border: 0;
}

/*Image hover2*/

.image {
	display: block;
	width: 100%;
	height: auto;
}

.overlay {
	width: 320px;
	height: 320px;
	position: absolute;
	bottom: 0;
	right: 0;
	opacity: 0;
	transition: .5s ease;
	background-color: #008CBA;
}

.image-text-div:hover .overlay {
  	opacity: 0.7;
}

.text {
	color: white;
	font-size: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
}

.image-text-div a:hover{
	background-color: black;
	text-decoration: none; 
	z-index: 1000;
}

/*Hidden inst icons*/

.image-text-div a span{
  	opacity: 0;
}

.image-text-div a:hover span {
  	opacity: 1;
}

.hidden-icon {
	font-size: 1.5rem;
	margin: 1rem 0;
	color: #fff;
	display: none;
}

.image-text-div:hover .hidden-icon, .image-text-div:hover .hidden-count{
 	display: block;
}

.hidden-count{
	font-size: 1rem;
	margin: 1rem;
	color: #Fff;
	display: none;
}

/*Cards*/

.card-image-div{
	overflow: hidden;
	position:relative;
	float: left;
}

.post-div, .post-div-gotop{
	overflow: hidden;/*if I comment it helps Copy URL be on top*/
	position: relative;
	text-align: left;
	width: 41.5vw;
	width: 43vw;
	border-radius: 10px;
	padding-bottom: 0.175rem;
}

.post-div-themes{
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 65vw;
  background: #fff;
  background: rgba(255,255,255,0.9);
  padding: 0.5rem;
  top: 45px;
  border-radius: 10px;
}

.post-div-profile {
	overflow: hidden;
	position: relative;
	text-align: left;
	width: 43.2vw;
	margin-right: 0.5rem;
}

.right-div{
	text-align: left;
	border-radius: 10px;
	position: fixed;
	right: 18.3vw;
 }

 .post-div p, .post-div-profile p{
	word-break: break-word;
 }

.library-div-profile {
	overflow: hidden;
	position: relative;
	text-align: left;
	width: auto;
	margin-bottom: 1rem;
}

/*Collections*/

.collection-main{
	overflow: hidden;
	position: relative;
	text-align: left;
	width: 65%;
	left: 20vw;
}

.collection-card{
	width: 250px;
	margin: 0 0 0.5rem 0;
}

.collection-card .view{
  	width: 125px!important;
}

.left-div{
	padding-top: 0.25rem;
	overflow: hidden;
	text-align: left;
	width: 330px;
	border-radius: 10px;
	height: 100vh;
	position: fixed;
	left: 15vw;
	top: 46px;
}

.comment-div{
	overflow: hidden;
	position: relative;
	text-align: left;
	width: 65%;
	height: 100%;
}

.comment-from-me{
	position: fixed;
	width: 100%;
	background-color: #fff;
	height: 100px;
	bottom: 1rem;
	height: 200px;
	border-top: 1px solid #ccc;
	width: 64%;
	margin: 0;
}

.post-avatar{
  	width:10%;
}

.post-avatar-nonav{
	width:10%;
	float: left;
	padding: 0.5rem;
}

.post-content{
	width: 90%;
	padding: 1rem;
}

.post-content-nonav{
  	padding: 1rem;
}

.post-avatar img{
  	margin: 0 0.5rem!important;
}

.shell{
	margin: 0;
	margin-bottom: 0.5rem!important;
}

.right-div-profile{
	position: relative;
	overflow: hidden;
	text-align: left;
	width: 22.5vw;
	border-radius: 10px;
	height: auto;
	right: 0;
	top: 1.1rem;
	margin-bottom: auto;
}

.right-div-profile .card, .right-div .card {
  	margin-right: 0;
}

.right-div-profile .username, .right-div-profile .tagline{
	word-wrap: break-word;
}

.right-div-create-post{
	padding-top: 0.25rem;
	overflow: hidden;
	text-align: left;
	width: 315px;
	border-radius: 10px;
	height: 70px;
	position: fixed;
	right: 18.3vw;
	top: 42px;
	padding: 0.5rem;
	margin-top: 1rem;
}

.btn-mobile{
	display:none;
}

/*Cards*/

.card {
  	margin: 0.5rem;
}

.card .card-text{
  	margin-bottom: 0.5rem;
}

.card-body{
  	padding: 0.5rem;
}

.card-post{
  padding: 1rem;
}

.img-fluid-my{
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-wrapper{
	background: #fff;
	padding : 0.5rem 0.5rem 0.25rem 0.5rem;
}

.card-wrapper .card {
	margin: 0rem;
}

/*Pages*/

.home,
.profile,
.products,
.team {
	display: flex;
	height: 90vh;
	align-items: top;
	justify-content: center;
	font-size: 3rem;
}

/*Icons*/

.close-icon{
  	display:none;
}

.icon-div{
	display: flex;
	align-items: center;
	width: 100%;
	color:rgb(22, 99, 214)!important;
}

.icon-count{
	margin-top: 3px;
}

.icon-count-gallery{
  	padding-right: 1.5rem;
}

.icon-count-separate{
	padding-left: 0.5rem;
	margin-bottom: 0;
}

.home-icons{
	font-size: 1.2rem;
	color:rgb(22, 99, 214)!important;
}

.home-icons-medium{
	font-size: 1.5rem;
	color:rgb(22, 99, 214)!important;
	padding-right: 3px;
}

.home-icons-small{
	font-size: 1.3rem;
	color:rgb(22, 99, 214)!important;
	padding-right: 3px;
}

.contactus{
	color:rgb(22, 99, 214)!important;
	font-size: 2.5rem;
	top: -2px;
	position: relative;
}

.like{
	margin-top: -5px;
	margin-right: 3px;
}

.home-icons-large{
	font-size: 1.7rem;
	margin: 0.5rem 0;
	color:rgb(22, 99, 214)!important;
}

.arrows{
	font-size: 2rem;
	color:rgb(22, 99, 214)!important;
}

.icon-div .profile-icons{
	font-size: 1.2rem;
	margin: 0.5rem 1rem;
	color:rgb(22, 99, 214)!important;
}

.image-icons{
	font-size: 1.5rem;
	margin: 1rem 0;
	color:rgb(22, 99, 214);
}

.btn-primary .home-icons-medium {
	color: #fff!important;
}

/*Buttons*/

button, .btn {
	outline: none;
}

*:focus {outline:none !important}

.btn:focus, .btn.focus {
	outline: none !important;
	box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important; 
}

.btn-primary{
	background-color: #00bcd4 !important;
	border: 1px solid #00bcd4 !important;
}

.btn-outline-primary {
	background-color: #fff!important;
	outline: none;
}

.btn-width{
	width: 10vw!important;
	padding: 0;
}

.btn-outline-primary:hover{
	color:rgb(22, 99, 214)!important;
}

.btn-new-group{
	float: left;
	padding: 1rem;
	background-color: #fff!important;
	margin: 1rem;
}

.btn-imitation{
	background: transparent!important;
	border: none;
	color: rgb(22, 99, 214)!important;
	letter-spacing: 0;
	vertical-align: revert!important;
	padding: 0 0.3rem!important;
}

.btn-imitation:hover, .btn-imitation:focus{
	box-shadow: none;
	color: #01448b;
}

/*Images*/

.avatar-big{
	width: 101px;
	height: 100px;
}

.avatar-medium {
	width: 70px;
	height: 70px;
}

.avatar, .avatar-big, .avatar-md, .avatar-sm, .avatar-ai-landscape, .avatar-ai-portrait{
	display: inline-block!important;
   -webkit-border-radius: 50%!important;
   -moz-border-radius: 50%!important;
   border-radius: 50% 50% 50% 50% !important;
   -khtml-border-radius: 50%!important;
	border-top: 0.5px solid #ccc;
   border-bottom: 0.5px solid #ccc;
   border-left: 0.5px solid #ccc;
   border-right: 0.5px solid #ccc;
	overflow: hidden!important;
	justify-content: center;
	text-align: center;
}

.avatar-mobile{
	width: 40px!important;
	height: 40px!important;
}

.gopa{
	width: 100px;
	height: 100px;
}

.gopa{
	display: inline-block!important;
   -webkit-border-radius: 50%!important;
   -moz-border-radius: 50%!important;
   border-radius: 50% 50% 50% 50% !important;
   -khtml-border-radius: 50%!important;
	border-top: 1px solid #ccc;
   border-bottom: 1px solid #ccc;
   border-left: 1px solid #ccc;
   border-right: 1px solid #ccc;
	overflow: hidden!important;
	justify-content: center;
	text-align: center;
}

.gopa1{
	width: 100px;
	display: inline-block!important;
	border-radius: 50% 50% 50% 50% !important;
	-khtml-border-radius: 50%!important;
	border: 1px solid red;
	background-image: url(https://ocai.s3.us-east-2.amazonaws.com/prf/chessmaster/1657239545);
	background-size: contain;
	background-repeat: no-repeat;
	background-size: 101%;
	height: 100px;
	background-position: 0 0;
	background-repeat: no-repeat;
	width: 100px;
}

.gopa img{
	height: auto;
}

.avatar img, .avatar-sm img, .avatar-md img,.avatar-big img {
	height: auto;
}

.avatar{
	width: 50px;
	height: 50px;	
}

.avatar-collection{
	width: 82px!important;
	height: 100%;
}

.avatar-myPosts{
	width: 50px!important;
	height: 50px;
	border-radius: 50%;
	margin: 0 1rem 0 0;
}

.avatar-md{
	width: 45px!important;
	height: 45px;
}

.avatar-sm{
	width: 40px!important;
	height: 40px;
}

/*Profile*/

.profile-block, .profile-block-gotop{
	background: #fff;
	padding: 0.5rem;
	height: 222px;
	border: 1px solid rgba(0,0,0,.125);
	border-radius: 0.25rem;
	margin-bottom: 1rem;
}

.profile-block .change-pic{
	margin-bottom: 0.5rem!important;
}

/*Class created from .profile-block for tags*/

.profile-block-for-tags {
	position: relative; 
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
}

.profile-block-for-tags::before {    
    content: "";
    background: #fff;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;  
}

h1{
	position: relative;
	color: #000;  
	font-size: 14rem;
	line-height: 0.9;
	text-align: center;
}

h1, .profile-block-for-tags ul li{
	position: relative;
	color: #000;  
}

.profile-info{
	text-align: left;
	word-break: break-all;
	padding: 0!important; 
}

.profile-info-account h3{
  	margin-left: 0;
}

@font-face { 
	font-family: Mea Culpa; 
	/* src: url('https://ocafprod.s3.us-east-2.amazonaws.com/font/MeaCulpa-Regular.ttf') format('truetype'); */
	src: url('css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
} 

.profile-info h4{
	color: #0088aaff;
	font-size: 1.5rem;
   font-weight: 300;
}

.profile-posts-and-channels{
	position: relative;
	margin: auto;
	width: 100%;
	text-align: left;
	top: 10px;
	margin-bottom: 2rem;
}

.showlibrary{
  	width: 65.5%;
}

.showlibrary button{
  	margin: 0rem 0.75rem 0 0;
}

.profile-post-avatar{
  	padding: 0.5rem;
}

.username{
	margin: 0 0.5rem;
	font-weight: 600;
}

.div-over{
	position: absolute;
	top: 90px;
	left: 70px;
	right: 0;
	color: #333;
	padding: 12%;
	text-align: left;
	visibility: visible;
	opacity: 1;
}

/*Modal*/

.modal-backdrop{
  	background-color: #f4f4f4!important;
}

.modal-header {
  	padding: 0 0 0.5rem 0!important;
}

.modal-dialog{
	border-radius: 25px;
	background: #fff;
	padding: 1rem;
	margin: 0.5rem auto!important;
	box-shadow: 0 12px 28px 0 #e3e1e1,0 2px 4px 0  #f4f4f4,inset 0 0 0 1px  #f4f4f4;
	width: 600px!important;
	max-height: 99vh;
}

.modal-dialog2{
	border-radius: 25px;
	background: #f7f7f7;
	padding: 2rem;
	margin: 1rem auto!important;
	box-shadow: 0 12px 28px 0 #e3e1e1,0 2px 4px 0  #f4f4f4,inset 0 0 0 1px  #f4f4f4;
}

.modal-dialog-centered{
  	height: 200px;
}

.modal-content{
	border: none!important;
	background-color: transparent;
}

.modal-content .modal-close{
	font-size: 2rem;
	color: #1a5ab9;
	margin-right: 0.5rem;
}

.modal-align-right{
	text-align: right;
	width: 29%;
	padding-right: 1.5rem;
}

.modal-align-left{
	text-align: left;
	width: 65%;
}

/*Modal for likes*/

.likes{
  	max-width: 360px!important;
}

.center-vert-like{
	vertical-align: middle;
	margin: 0.6rem 0;
}

/*Modal for comments*/

.modal-for-comments{
  	max-height: 700px!important;
}

.comments{
	width: 570px!important;
	height: 500px!important;
}

.user-comment{
	display: flex;
	padding: 1rem 0;
	border: 1px solid #e3e1e1;
	border-radius: 7px;
}

.user-comment h5{
  	line-height: 1.3;
}

.user-comment button{
	appearance: none;
	background: 0;
	color: rgb(22, 99, 214)
}

.modal-footer-comment{
  	width: 100%;
}

label{
	margin-bottom:0;
	float: right;
}

/*Edit profile*/

input[type="text"]:disabled, .form-control:disabled{
  	background-color:#f7f7f7!important;
}

.edit-profile{
	width: 100%;
	margin: auto;
	word-break:break-all;
}

.edit-profile textarea{
  	resize: none;
}

.edit-profile2{
	width: 530px;
	margin: auto; 
}

.close{
  	float: left;
}

/*Vertical scroll*/

div.vertical-scroll::-webkit-scrollbar {
	width: 6px;
	padding:0;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

div.vertical-scroll {
	height: 500px;
	overflow: auto;
}

.dropdown button, 
.dropdown button:active,
.dropdown button:visited,
.dropdown button:focus,
.dropdown button:hover,
.btn-seconary:focus {
	background-color: #fff!important;
	border-color: #fff!important;
	color: #333!important;
	box-shadow: none!important;
	border: none!important;
}

.dropdown-toggle::after{
  	display:none!important;
}

/*Search*/

.search{
	margin: 0.5rem;
	width: 95%!important;
}

/*Tooltip*/

.tooltip { 
	display: none; 
}

.tooltip-on-hover:hover + .tooltip { 
	display: block; 
}

/*Error messages*/

.error-message, .error-message span{
	font-family: Arial, Helvetica, sans-serif;
	font-Size: 14px!important; 
	color: #fff!important;
	text-align: left; 
	font-weight: 400;;
	line-height: 1;
}

.error-message span{
	left: 0!important; 
	top: -32px!important; 
}

/*User*/

.user{
	right: 2rem;
	position: fixed;
}

/*dropdown*/

.dropdown-menu, .dropdown-menu.show{
	width: 210px!important;
	transform: translate(30px,39px)!important;
	border: none;
	background: #0d3d7a;
	padding: 0;
	left: -185px!important;
	top: 5px!important;
	padding: 0.5rem;
 }

.dropdown-menu a{
	float: left;
	width: 100%;
	padding: 12px;
	color: #fff!important;
	font-size: 16px;
	font-weight: 300;
	padding: 1rem;
}

.dropdown-menu a:hover{
	text-decoration: none;
	color:rgb(22, 99, 214);
	background:rgba(101, 162, 219, 0.3);
}

/*Report*/

.dropdown-menu-report, .dropdown-menu-report.show {
   width: 70px!important;
	min-width: 70px!important;
   transform: translate(104px,12px)!important;
   background: #fff!important;
   padding: 0;
   padding: 0;
	border-radius: 0;
	border: 1px solid rgba(0, 0, 0, 0.04);
   box-shadow: 0 6px 12px 2px rgb(0 0 0 / 8%);
}

.dropdown-menu-report p, .dropdown-menu-report a{
	float: left;
	width: 100%;
	padding: 0.4rem;
	color: #1663D6!important;
	font-size: 14px;
	margin-bottom: 0!important;
}

.dropdown-menu-report .attention{
	color:#F94706!important;
}

.dropdown-report .btn{
	padding: 0.375rem 0!important;
}

.dropdown-menu-report .home-icons-medium{
	padding-left: 0.3rem;
}

.dropdown-menu-report .reportcheck{
	display: none;
}

/*Tabs*/
/*For Collections*/

.tabs {
	float: left;
	width: 27%;
	height: auto;
	background: rgba(255,255,255,0.9);
	padding: 0.5rem;
	margin-right: 1rem;
}

.tab {
	display: block;
	background-color: inherit;
	color: black;
	width: 100%;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
}

.tabcontent {
	float: left;
	width: 70%;
	border-left: none;
	background: rgba(255,255,255,0.9);
	padding: 0 0.5rem;
}

.tabcontent-home{
  	width: 100%;
}

.theme{
	background-color: #fff;
	border: 1px solid #47a1ed9c;
	width: 100px;
	border-radius: 10px;
	padding: 4px;
	float: left;
	margin-right: 0.1rem;
}

/*Collections dropdown*/

.menu-container{
	padding: 30px;
	background: #f5f5f5;
	height: 100vh;
}

.menu-bar{
  position: relative;
}

.menu-bar-item{
  	position: relative;
}

.menu-bar-link{
	text-decoration: none;
	color: rgb(22, 99, 214)!important;
}

.mega-menu{
  	display: none;
}

.mega-menufalse{
	display: none;
}

.mega-menu.true{
  	display: block;
}

.mega-menu-content{
	background: #ffffff;
	padding: 16px;
	border: 1px solid rgba(0,0,0,.125);
	position: absolute;
	top: 47px;
	width: 130px;
	right: 13px;
	z-index: 1000;
}

.mega-menu-share{
	background: #ffffff;
	padding: 8px;
	margin: 0;
	left: 50px;
    top: -7px;
	border: 1px solid rgba(0,0,0,.125);
	position: absolute;
	width: 175px;
	z-index: 1000;
}

.social{
	float: left;
	padding-right: 0.5rem;
}

/*Radio*/

.radio{
  	float:left;
}

.radio input{
	vertical-align: middle;
	margin:5px;
}

.collection{
  	margin-left:1rem!important;
}

/*GetImages*/

.getimages{
	width: 1070px;
	height: 700px;
	margin: auto;
	border: white;
	border: 1px solid white;
	height: 300vh;
	background: rgba(255,255,255,0.9);
	padding: 60px 1rem 0;
}

/*Login*/

.password{
 	width: 100%;
}

.btn-login {
	margin-left: 0;
	display: block;
}

.info {
	height: 2.5rem;
	width: 1.5rem;
}

input[class="blue"] {
	border: solid 1px #ced4da;
	background: #fff;
	height: 18px;
	width: 18px;
}

input[type="checkbox"] {
  	border-radius: 2px;
}

.check span{
  	vertical-align: top;
	line-height: 1;
	padding: 0 0.5rem;
}

.signup-form-row {
  	height: 60px;
}

.input-with-icon {
  	padding: 0!important;
}

.input-align {
	width: 88%!important;
	margin-left: 1rem;
}

.margin-against-row {
  	margin-left: -15px;
}

.btn-login-signup {
	width: 92%!important;
	margin-left: 0!important;
	font-size: calc(1.125rem * var(--koeff))!important;
}

.a-login-signup, .login-form .btn-dark {
	font-size: calc(1.125rem * var(--koeff))!important;
}

/*My post*/

.mypostform label{
	width: 100%;
	float: left;
}

.mypostform textarea:focus{
  	outline: none;
}

/*Upload multiple images*/

.media-row{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.media1{
	padding-top: 0.7rem;
	height: 40px;
}

.image-item{
	width: 33%;
	float: left;
}

.media-close-icon{
	position: relative;
	margin-left: 11rem;
	margin-top: -3rem;
	width: 1.5rem;
	height: 1.5rem;
	color: #ff4081;
}

.media-icon{
	color: rgb(22, 99, 214);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 30px;
	margin: auto;
	margin-top: 0.3rem;
	cursor: default;
}

.media-icon svg{
	cursor: pointer;
}

.icon{
  	font-size: 20px;
}

.uploading-img{
	width: 90%;
	margin: 5%;
	padding: 10px;
	background: #edf2f6;
	box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
	border: 1px solid #d0dbe4;
	position: relative;
	float: left;
}

.uploading-img img{
	width: 100%;
	object-fit:cover;
}

/*Upload one image*/
 
.fileInput{
	padding: 10px;
	margin: 15px;
	cursor: pointer;
}
   
.imgPreview{
  	text-align: center;
}
    
.previewText{
	width: 100%;
	margin-top: 20px;
}
  
.submitButton{
	padding: 12px;
	margin-left: 10px;
	background: white;
	border: 4px solid lightgray;
	border-radius: 15px;
	font-weight: 700;
	font-size: 10pt;
	cursor: pointer;
	background: #efefef;
}
  
.centerText{
	text-align: center;
	width: 500px;
}
 
.media{
	pointer-events: none;	
}

.media-align{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: default;
	margin-top: 0.5rem;
}

.media-align .media-icon{
	pointer-events: auto;
}

.previewComponent{
  	width: 100%;
}

/*Image popup*/

.dialog {
	box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
	z-index: 1000;
	padding: 10px;
	border: none;
	z-index: 1000;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100%;
}

.dialog-post {
	box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
	position: static;
	top: 0;
	z-index: 1000;
	padding: 1rem;
	border: none;
	max-width: 550px!important;
	z-index: 1000;
	margin: auto;
}

.image {
	width:100%;
	height: 90%;
	object-fit: cover;  
}

/*Account*/

.account-form{
	padding: 1rem 9rem;
}

.edit-password {
	width: 530px;
	margin: auto;
	padding: 1rem 2rem;
}

.password {
	width: 100%;
	height: 38px;
}

.account .btn{
	margin: 0;
}

.notice{
	background-color: #007bff;
	border: 1px solid #007bff;
	border-radius: 30px;
	width: 130px;
	float: right;
}

.notice p{
	margin-top: 0;
	margin-bottom: 0.4rem;
	color: #fff;
}

.white {
  	color: #FFFFFF;
}

@keyframes overlay-anim {
  from {
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
}

.overlay-anim {
	animation-duration: 0.25s;
	animation-fill-mode: forwards;
	animation-name: overlay-anim;
	opacity: 0;
} 

/*************************************PTICHKA LOADER*****************************/

* {
	margin: 0;
	padding: 0;
}

.birdy-body{
	background: var(--body-color);
	font-family: sans-serif;
	height: 100vh!important;
	width: 100vw!important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.birdy-container {
	width: 200px;
	height: 200px;
	position: relative;
}

.mainbody,  .mouth,  .feather,  .tail, .beak, .mouth::before, .mouth::after, .leg, .leg::after, .leg::before {
  	position: absolute;
}
.mainbody,  .mouth, .feather, .beak, .mouth::before, .mouth::after  {
  	border-radius: 50%;
}

.mainbody {
	top: 26px;
	left: 50px;
	height: 90px;
	width: 100px;
	transform: rotate(-17deg);
	background: linear-gradient(to bottom, transparent 0%, transparent 20%, var(--mainbody) 20%);
	animation: animate-mainbody 1.5s forwards;
	animation-delay: 0.003s
}

@keyframes animate-mainbody {
  100% {
      top: 25px;
  }
}

@keyframes animate-mouth {
  100% {
      left: 115px;
  }
}

@keyframes animate-eye {
  50% {
      height: 5px;
  }
}

.feather {
	z-index: 4;
	left: 44px;
	top: -206px;
	width: 75px;
	height: 75px;
	background: linear-gradient(to bottom, transparent 0%, transparent 50%, var(--feather) 50%);
	transform: rotate(-17deg);
	animation: animate-feather 1.5s forwards;
	animation-delay: 0.003s
}

@keyframes animate-feather {
  100% {
      top: 7px;
  }
}

@keyframes animate-tail {
  100% {
      left:0px;
  }
}
.beak {
	height: 50px;
	width: 50px;
	background: linear-gradient(to bottom, var(--beak-top) 0%, var(--beak-top) 15%, transparent 15% );
	left: 143px;
	top: 240px;
	z-index: 1;
	transform: rotate(-17deg);
	animation: animate-beak 1.5s forwards;
	animation-delay: 0.003s
}

@keyframes animate-beak {
  100% {
      top: 30px;
  }
}

.leg {
	background: var(--leg);
	height: 25px;
	width: 5px;
	border-radius: 10px;
	top: 515px;
	left: 95px;
	transform: rotate(-15deg);
	z-index: 1;
	animation: animate-leg 1.5s forwards;
	animation-delay: 0.003s
}

@keyframes animate-leg {
  100% {
      top: 110px;
  }
}

.leg::before {
	bottom: -8px;
	content: "";
	width: 40px;
	height: 9px;
	background: var(--leg);
	border-radius: 10px;
	transform: rotate(15deg);
	z-index: -1;
}

.leg::after {
	top: -8px;
	content: "";
	width: 30px;
	height: 9px;
	background: var(--leg);
	border-radius: 10px;
	transform: rotate(-15deg);
	z-index: -1;
}

/*Create post*/

.fill {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden
}

.fill img {
	flex-shrink: 0;
	min-width: 100%;
	min-height: 100%
}

/*Cat loader*/

.cat-container {
	width: 200px;
	height:187px;
	position: relative;
}

.cat {
	height: 112px;
	width: 169px;
	position: relative;
	top: 20%;
}

.head {
	background: var(--catcolor);
	position: absolute;
	height: 31px;
   width: 55px;
   border-radius: 60px;
	left: 72%;
	top: 45%;
	animation: 3s linear head infinite;
}

.earl {
	background: var(--catcolor);
	position: absolute;
	height: 6px;
	width: 6px;
	top: -15%;
	right: 39%;
	border-radius: 100% 0 0 0 ;
	animation: 1s swing  alternate-reverse infinite; 
	transform-origin: bottom left;
}

.earr {
	background: var(--catcolor);
	position: absolute;
	height: 6px;
	width: 6px;
	top: -15%;
	right: 20%;
	border-radius: 100% 0 0 0 ;
}

.eyel {
	background: transparent;
	position: absolute;
	height: 10px;
   width: 8px;
   top: 4%;
	right: 32%;
	border-top: 1px solid transparent;
	border-right: 1px solid transparent; 
	border-left: 1px solid transparent;
	border-bottom: 1px solid black;
	border-radius: 100%;
}

.eyer {
	background: transparent;
	position: absolute;
	height: 10px;
   width: 8px;
   top: 4%;
	right: 13%;
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	border-bottom: 1px solid black;
	border-radius: 100%;
}

.m {
	position: relative;
	background: var(--white);
	height: 10px;
	width: 12px;
	top: 45%;
	right: -50%;
	border-radius: 100% 0 100% 100%;
	z-index: 100; 
}

.m::after {
	content: '';
	position: absolute;
	background: var(--white);
	height: 10px;
	width: 12px;
	top: 0%;
	right: -100%;
	border-radius: 0 100% 100% 100%;
}

.mouth {
	position: absolute;
	background: black;
	border-radius: 0% 0% 48% 30%;
	height: 8.5px;
	width: 7.5px;
	left: 63%;
   top: 55%;
	animation: 3s mouth linear infinite;
}

.tail {
	background: var(--catcolor);
	position: relative;
	height: 12px;
	width: 50px;
	border-radius: 50px;
	top: 62%;
   left: 33%;
	animation: 3s linear infinite tail;
}
 
.cat-body {
	background: var(--catcolor);
	position: absolute;
	height: 45px;
   width: 70px;
   left: 46%;
   top: 33.6%;
	border-radius: 100% 100% 0% 65%;
	animation: 3s body linear infinite;
}

.feetl {
	background: var(--white);
	position: absolute;
	height: 10px;
	width: 20px;
	border-radius: 20px;
	top: 77%;
	left: 20%;
	animation: feet 3s infinite linear;
}

.feetr {
	background: var(--white);
	position: absolute;
	height: 10px;
	width: 20px;
	border-radius: 20px;
	top: 77%;
	left: 50%;
	animation: feet 3s linear infinite;
}

.bubble {
	position: relative;
	width: 15px;
	height: 15px;
	opacity: 0.6;
	background: var(--light-blue);
	border-radius: 100%;
	right: -88%;
	top: -9%;
	animation: 3s infinite linear bubble;
}

.bubble::before {
	content: '';
	position: absolute;
	background: var(--light-blue);
	height: 5px;
	width: 15px;
	left: -24%;
	top: 65.7%;
	border-radius: 50px;
}

.shadow {
	background: #765dc5;
	position: absolute;
	width: 80px;
	height: 2.5px;
	border-radius: 50px;
	top: 63%;
	left: 40%;
	animation: 3s shadow linear infinite;
}

@keyframes shadow {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1) translate(0,0);
	}
	100% {
	  transform: scale(1);
	}
}

@keyframes bubble {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(0.7) translate(-17px, 0px);
	}
	100% {
	  transform: scale(1) translate(0,0);
	}
}

@keyframes mouth {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1) translate(0, 2px);
	}
	100% {
	  transform: scale(1);
	}
}

@keyframes feet {
	0% {
	  transform: translate(0,0);
	}
	50% {
	  transform: translate(10px, 0);
	}
	100% {
	  transform: translate(0,0);
	}
}

@keyframes body {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: scale(1.05) translate(-4px, -3px);
		border-radius: 100% 100% 0 0;
	}
	100% {
		transform: translate (0px,0);
	}
}

@keyframes head {
	0% {
		transform: scale(1); 
	}
	50% {
		transform: scale(1.02) translate(0, -1.8px);  
	}
	100% {
		transform: scale(1); 
	}
}

@keyframes tail {
	0% {
	  transform: translate(0, 0);
	}
	50% {
	  transform: scale(1.1);
	}
}

@keyframes swing {
	85% {
	  transform: rotate(-10deg);
	}
	100% {
	  transform: rotate(0deg);
	}
} 

/*Image upload*/

.too-big{
   display: none;
}

/*Posts*/

.postimage-container{
	max-height: 460px; 
	overflow: hidden;
}

.poststyle{
	margin-bottom: 0;
	padding: 0.5rem;
	word-break: break-word;
}

 /*Post image*/

img{
  height: 100%;
}

.image-parent{
	background: #f7f7f7;
  	display: inline-block;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
 }

 .image-parent img{
	overflow: hidden;
 }

.image-parent-dialog{
	background: #f7f7f7;
	display: inline-block;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 616px;
}

/*Avatar image*/

.avatar-big img{
	height: auto;
}

/*Bootstrap overwrite*/

.modal-dialog .img-fluid{
	max-height: 40vh!important;
}

/*centering vertical image*/

.parent {
	width: 614px;
	height: 460px;
	background: orange;
  	display: inline-block;
}
 
.container-image {
	width: 614px;
   height: 460px;
	display: flex;
	flex-direction: column;
}

.container-image > .middle {
	flex: 0 1 100%;
	background: #f7f7f7;
	max-height: 100%;
	max-width: 100%;
	display: block;
	object-fit:contain;
}

.container-image > .marge {
	flex: auto;
	background: blue;
	font-size: 6px;
}

/*Picture Library View*/

.img-match{
	top:-34px;
} 

/*Comments*/

.comments-div{
  word-wrap: break-word;
}

.comments-div .postrow div{
  	padding: 0;
}

.comments-div .postrow p{
	padding-left: 1.5rem;
}

.comments-div .postrow button{
  width: 100%;
}

.comments-topost{
  background: #fff;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  height: auto;
}

/*GetChannels*/

.scroll{
	overflow: auto;
	height: 85vh;
	position: fixed;
	width: 21.3vw;
	right: 18vw;
}

div.scroll::-webkit-scrollbar {
	width: 6px;
	padding:0;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	box-shadow: inset 0 0 6px rgba(0,0,0,0);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
	box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.info-f{
	width: 79%;
	padding-left: 0.5rem;
	word-break: break-word;
}

/*Get Likes*/

.likes{
   display: block !important;
}

.likes .suggested{
	padding: 0.5rem 0;
}

.likes .modal-myheader{
	padding-bottom: 1.5rem;
}

.likes .modal-dialog{
   overflow-y: initial !important
}

.likes .modal-body{
	height: 80vh;
	overflow-y: auto;
	padding: 0;
}

.scroll-likes{
	overflow: auto;
	height: calc(100% - 100px);
}

 div.scroll-likes::-webkit-scrollbar {
	width: 6px;
	padding:0;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	box-shadow: inset 0 0 6px rgba(0,0,0,0);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
	box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

/*Conditions*/

.conditions .modal-myheader{
	padding-bottom: 1.5rem;
}

.conditions .modal-dialog{
   overflow-y: initial !important
}

.conditions .modal-body{
	height: 100vh;
	overflow-y: auto;
	padding: 0;
	/* added for qa and production */
	max-width: 830px;
}

.scroll-conditions{
	overflow: auto;
	height: calc(100% - 100px);
	position: fixed;
}

div.scroll-conditions::-webkit-scrollbar {
	width: 6px;
	padding:0;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	box-shadow: inset 0 0 6px rgba(0,0,0,0);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
	box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

/*Modals*/

.modal-company-individual{
	width: 860px!important;
	max-width: 860px!important;
	text-align: left;
	/* needed for qa and production */
	height: 100%;
}

.modal-company-individual p{
	white-space:pre-wrap;
   word-break:break-word;
}

.modal-company-individual ul, ul {
  	list-style-type: disc;
}

.modal-dialog-centered{
	top: 10rem!important;
	min-height: calc(45% - 5rem)!important;
}

.modal-sm{
	min-height: 155px!important;
	max-width: none!important;
}

.modal-sm p{
	margin-bottom: 0!important;
}

.modal-sm button{
	margin-top: 1rem;
}

/*Tooltip*/

.mytooltip{
	background-color: #383e4b!important;
	border-radius: 10px!important;
	vertical-align: middle!important;
	padding: 0.5rem!important;
	text-align: center;
	align-self: center;
	justify-content: center;
	align-items: center;
	width: auto;
	z-index: 1000;
}

.info-tooltip{
	width: 300px;
}

.info-mobile{
	display: none;
}

.info-desktop{
	display: block;
}

.red{
	color: red;
}

.green{
	color: #03ad03;
}

.btn-mobile-comments{
	display: none;
}

.edit-post svg{
	font-size: 1.4rem;
}

.eye {
	cursor: pointer;
	float: right;
	right: 1rem;
	top: -2rem;
	position: relative;
}

.contact-us{
	width: 1000px;
	margin: auto;
	font-size: 14px;
	padding-top: 1rem;
	position: relative;
	text-align: center;
}

.contact-us-sticky{
	width: 1000px;
	margin: auto;
	font-size: 14px;
	bottom: .2rem;
	position: fixed;
	left: 50%;
   transform: translate(-50%, 0);
	text-align: center;
}

.delete-account .profile-info h3, .about h3{
	margin-bottom: 2rem;
}

.delete-account .profile-info h5, .about h5{
	margin-bottom: 1.5rem;
}

.likes{
   display: block !important;
}
.likes .modal-dialog{
   overflow-y: initial !important
}
.likes .modal-body{
	height: 60vh;
	overflow-y: auto;
}

.message-modal .modal-body p{
	text-align: left;
	padding-left: 2rem;
}

.message-modal .modal-body{
	padding: 0.5rem;
}

.about{
	/* margin-top: 2rem; */
	text-align: left;
	padding: 1rem;
	margin: 0 6rem;
	position: relative;
	word-break: break-word;
}

.about .small{
	font-size: 16px;
}

.about a {
	word-break: break-word;
}

.newuser-mobile h3{
	font-family: 'Mea Culpa';
	color: #0088aaff;
	font-size: calc(3rem * var(--koeff));
	line-height: 1;
	margin-top: 7rem;
}

.avatar-small-landscape, .avatar-small-portrait {
	width: 45px;
	height: 45px;
	border: 0.5px solid #ccc;
   border-radius: 50%; 
	overflow: hidden; 
}

.avatar-small-landscape img {
	height : 100%;
	width: auto;	
}
 
.avatar-small-portrait img {
	width : 100%;
	height: auto;
}

.login-advertise h3{
	font-family: 'Mea Culpa';
	color: #0088aaff;
	font-size: 3rem;
	line-height: 1;
}

.ifollow{
	font-family: 'Mea Culpa';
   color: #0088aaff;
   font-size: 2rem;
}

/*New dropdown*/

.containerd {
	position: relative;
	display: inline-block;
}

.button {
	padding: 0;
	width: 50px;
	border: 0;
	background-color: #fff;
	color: #333;
	cursor: pointer;
	outline: 0;
	font-size: 40px;
}

.report p{
	margin-top: 0.5rem!important;
	color: #1a5ab9;
}

.report .row:hover{
	background:rgba(101, 162, 219, 0.3);
	color:#fff;
}

.bkgrnd {
	width: 100vw;
	height: 100vh;
	margin: 0!important;
	padding: 0!important;
	background-color: #f7f7f7!important;
	background-image: url("images/vectorstock_25083512_edit_bkgr.svg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
}

.buttonsDiv{
	position: absolute; /* Use absolute positioning relative to #NavBar */
   top: 50%;
   right: 1%; 
	/* chatGpt */
   transform: translateY(-50%); 
	/* Claude */
	/* transform: translate(-50%, -50%); */
}

.btn-info{
	color: #fff!important;
}

.buttonsClass{
	padding: 0.5rem 1rem;
	margin-right:1rem;
	float: right;
	line-height: 1;
	border-radius: 0;
}

.sharedComments{
	margin-top: 1rem;
}

.myToast{
	position: fixed;
	top: 0;
	height: 50px;
	width: 100%;
	z-index: 1000;
}

.toast{
	background-color: #17a2b8!important;
	color: #fff!important;
}

.copy{
	border: 1px solid rgb(22, 99, 214)!important;
   border-radius: 50%;
   padding: 1px;
   margin-right: 0.5rem;
   width: 30px;
}

.copy .home-icons-medium{
	margin-left: 3px;
}

.edit-post svg, .shared svg{
	font-size: 1.5rem!important;
}

.delete-post svg{
	font-size: 1.2rem!important;
}

.report svg{
	vertical-align: top;
    margin: 8px 0;
}

/*Shared post*/

.sharedPost{
	position: relative;
	width: unset;
	top: 0!important;
	height: 100vh;
}

.sharedPost .medeata-desktop{
	margin: unset;
}

.sharedPost .nav{
	padding: 0!important;
	top: 0!important;
	box-shadow: none;
	position: relative;
	margin-bottom: 0!important;
}

.sharedPost .post-div{
	/* top: 50px; */ /*ipad portrait */
}

/*Consierge*/

.consierge{
	/* background removed since images are present */
	height: 100%;
	width: 100%;
	position: relative;
	margin: 0;
	/* overflow: hidden; */
	/* top:0!important; */
}

.consierge .profile-block{
	padding-top: 5rem;
}

/*Search UI*/

.search-container{
	width: 1400px;
	margin: auto;
	padding: 0.5rem;
}

.search-container .btn-primary[disabled]:hover {
	cursor : default;
}

.center-container-small{
	width: 650px;
	margin: auto;
}

.search-container-add{
	margin-bottom: 0;
	padding-top: 2rem; 
	padding-bottom: 0;
}

.search-results{
	text-align: center;
	flex-wrap: wrap;
    justify-content: center;
	flex-direction: row;	
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.search-card{
	width: 25vw!important;
	overflow-wrap: break-word;
	height: 85px;
	position: relative;
}

.search-card .medium{
	font-size: calc(0.875rem * var(--koeff))!important;
	color: #333;
}

.search-card .avatar-small-landscape, .search-card .avatar-small-portrait{
	transform: translate(0%,8%);
}

.search-card .info-f{
   word-break: break-word;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 57%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/*Alternate methos for search icon*/

.input-icons div {
	position: absolute;
}
  
.input-icons {
	width: 100%;
	margin-bottom: 10px;
}
  
.icon {
	min-width: 40px;
}
  
.input-field {
	width: 100%;
	padding: 10px;
	text-align: center;
}

/*Final methos for search icon*/

.new{
   background: url(images/search-sm.png) no-repeat scroll 7px 5px;
   padding-left: 2.5rem!important;
	background-color: white;
   border: 1px solid grey;
}

/*Preferences. Radio buttons*/
.radio-form{
	width: 50%;
   margin: auto;
}

.form-control-radio .image{
	height: 100%;
}

.radio-container-parent{
	display: flex;
	text-align: left;
}

.radio-title{
	display: flex;
   align-items: center;
   justify-content: center;
   text-align: left;
	padding: 1rem 0;
	margin-bottom: 0;
}

.radio-container {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.form-control-radio{
	font-family: system-ui, sans-serif;
	line-height: 2;
	display: grid;
	grid-template-columns: 1em auto;
	/* Prod 28 */
	grid-template-rows: 1em auto;
	gap: 1rem;
	font-size: calc(1rem * var(--koeff));
}

input[type="radio"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	appearance: none;
	/* For iOS < 15 to remove gradient background */
	background-color: #fff;
	/* Not removed via appearance */
	margin: 0;
}

input[type="radio"] {
	appearance: none;
	background-color: #fff;
	margin: 0;
	font: inherit;
	width: calc(1.2rem * var(--koeff));
	height: calc(1.2rem * var(--koeff));
	border: 1px solid #333;
	border-radius: 50%;
	transform: translateY(calc(0.4rem * var(--koeff)));
}  
  
input[type="radio"] {
	/* ...existing styles */
	display: grid;
	place-content: center;
} 
  
input[type="radio"]::before {
	content: "";
	border-radius: 50%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em var(--form-control-color);/*color of inner circle*/
}

/*":checked state styles"*/

input[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:checked {
	background-color: white; 
	border: 1px solid #333;
}

input[type="radio"]::before {
	content: "";
	width: calc(0.7em * var(--koeff));
	height: calc(0.7em * var(--koeff));
	border-radius: 50%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	background-color: #fff; /* Set this to the background color of your radio button */
	border: 1px solid #666;/* Set this to the border color of your radio button */
}

/*Artificial Intellegence*/

.ai-consierge{
	position: relative;
	height: 100vh;
	padding-top: 100px;
	background-image: url(images/consierge-greet2-dark-desktop.png);
	background-repeat: no-repeat;
	background-position: 1000px 350px;
	background-size: auto;
}

.ai-consierge-response{
	width: 1000px;
	margin: auto;
}

.ai-input{
	position: relative;
	height: 60px;
	width: 1000px;
	margin: auto;
}

.ai-input input{
	height: 58px;
	color: white;
}

.ai-row{
	margin: 1rem 0;
}

.user-response .ai-options{
	border: 1px solid rgb(0, 188, 212);
   height: 32px;
   padding: 10px;
   border-radius: 20px;
}

.user-response .ai-options2{
   padding: 10px;
}

.user-response{
	margin-left: 1rem;
   width: 93%;
	height: auto;
}

/*Modal for Ai*/

.modal-fullscreen{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	max-width: 100vw!important;
	width: 100%!important;
	max-height: 100vh!important;
	height: 100vh!important;
	border-radius: 0!important;
	box-shadow: none!important;
	margin: 0!important;
	padding: 1rem;
}

.modal-fullscreen .btn{
	margin-bottom: 0.5rem;
}

.body-fullscreen{
	background: url(images/consierge-greet2-dark-desktop.png);
	background-repeat: no-repeat;
	background-position: 220px 400px;
   background-size: 30%;
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
}

.body-fullscreen-3{
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0!important;
}

.body-fullscreen-3-noimage {
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0!important;
}

.body-fullscreen-2{
	background: url(images/consierge-greet2-dark-desktop.png);
	background-repeat: no-repeat;
	background-position: 520px 400px;
   background-size: 30%;
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
}

.modal-fullscreen-content{
	width: 900px;
	margin: 0rem auto 0;
}

.modal-fullscreen-switch{
	width: 700px;
	margin: 2rem auto;
}

.modal-fullscreen h4{
	font-weight: 400;
	font-family: 'Mea Culpa';
	color: #0088aa;
	font-size: calc(2rem * var(--koeff));
}

.modal-fullscreen h6{
	font-weight: 400;
	color: #0088aa;
	font-size: calc(1.0075rem * 1.00);
	margin-bottom: 0rem!important;
   margin-top: 0.5rem!important;
}

.modal-fullscreen .ai-concierge{
	position: relative;
	margin: auto;
}

.modal-fullscreen .ai-content{
	position: relative;
	padding-bottom: 0;
}

.modal-fullscreen .ai-response, .modal-fullscreen .user-response{
	width: 100%;
	margin-left:0;
	padding: 1rem 0;
}

.modal-fullscreen-content textarea[name=createpost], .modal-fullscreen[name=createcomment]{
	border: 1px solid #ced4da;
}

.modal-fullscreen-content textarea[name=createpost]{
	min-height: unset;
   height: 90px;
	padding: 0.5rem 1rem;
	font-size: calc(1rem * var(--koeff));
}

.modal-fullscreen .ai-image, .modal-fullscreen .avatar-mobile{
	width: 50px!important;
	height: 50px!important;
}

.modal-fullscreen .createtextarea {
	width: 100%!important;
	padding-left: 0!important;
}

.modal-fullscreen .modal-space .createtextarea {
	width: 100%!important;
	padding-left: 0!important;
}

.modal-fullscreen .modal-space .ai-createtextarea{
	width: 91%!important;
	background-position: center;
	background-image: url(images/send-blue.png);
	background-repeat: no-repeat;
	background-position: 99% 91%;
	background-color: transparent;
}

.modal-fullscreen .ai-space textarea[name=createpost] {
	width: 100%!important;
	padding-left: 1rem!important;
	line-height:1;
}

.modal-fullscreen .ai-adjust{
	margin-top: 0.3rem;
	padding: 0.3rem 3rem!important;
}

.modal-fullscreen .ai-adjust2{
	padding: 1rem 3rem!important;
}

.modal-space {
	border-radius: 3px;
	padding: 0rem 1rem 0.5rem!important;
}

.modal-fullscreen .ai-content{
	top: 0;
}

.modal-fullscreen .alignflex{
	padding-bottom: 0.5rem;
}

.modal-fullscreen .switch button{
	background-color: transparent!important;
	width: unset;
	border-radius: 50% 50% 50% 50% !important;
	height: 85px;
	padding: 0;
}

.modal-fullscreen .switch:hover{
	color: white;
}

.modal-fullscreen .image-parent-create-post {
	margin-bottom: 0;
}

.modal-fullscreen .ai-input {
	position: relative;
}

.modal-fullscreen .ai-input2 {
	width: 740px!important;
}

.modal-fullscreen .image-parent-create-post{
	margin-top: 1rem;
	top: 1rem;
	bottom:1rem;
	margin-bottom:1rem;
	max-width: 100%;
	margin: 0.5rem auto;
}

.modal-fullscreen .ai-concierge .image-parent-create-post{
	margin: auto;
   padding: 1rem;
}


.modal-fullscreen .ai-concierge .image-parent-create-post img,
.modal-fullscreen .image-parent-create-post img{
	max-height:59vh!important; /*O laptop with monitor*/
}

.modal-fullscreen .media1{
	margin: 0!important;
	padding-top: 0.5rem!important;
}

.modal-fullscreen .try{
	float: right;
	color: #03b39a;
}

.modal-fullscreen .media-icon{
	margin: 0!important;
	height: 35px;
}

#labelAI .media-icon{
	height: 40px;
}

#labelAI .media-icon svg{
	font-size: 29px;
	padding-left: 1rem;
}

.modal-fullscreen .switch{
	width:26%;
}

.modal-fullscreen .switch button {
	border-radius: 0;
	box-shadow: none;
}

.modal-fullscreen #label, .modal-fullscreen #labelAI{
	width:3vw;
}

.modal-fullscreen .send{
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 58px;
}

.modal-fullscreen .send img{
	height:85%;
}

.iphoneIpadInset{
	height : 100px;
	width: 100%; 
	background-color: white; 
	position: fixed; 
	z-index: 1001; 
	margin-top: -100px;
}
	
.posFixed{
	position: relative;
}

/*The below two classes define AI response 
input or text area within phone devices*/

.ai-input-butmobile{
	height: 58px;
}

.ai-textarea-mobile-n{
	width: 100%;
	padding: 0.5rem;
	font-size: calc(1rem * var(--koeff));
	font-weight: 300;
	line-height: 1.5;
	border: 1px solid #ced4da;
	resize: none;
	margin: 0;
	position: relative;
}

.fuulBgrnd{
	height: 100vh;
}

.line{
	border-right: 1px solid #9a9595;
}

.choice{
	margin-bottom:2rem;
}

.threeDotsPosition {
	float: none;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.threeDots, #threeDots{
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: left;
	padding-left: 3rem;
}

/*AI Avatar*/

.avatar-ai-sm{
	width: 35px!important;
	height: 35px;
	float: left;
	border-radius: 50% 50% 50% 50%;
	overflow: hidden;
}

.avatar-ai-landscape, .avatar-ai-portrait {
	width: 85px;
	height: 85px;
	border: 0.5px solid #ccc;
   border-radius: 50%; 
	overflow: hidden; 
}

.avatar-ai-landscape:hover, .avatar-ai-portrait:hover{
	outline: 1px solid #ccc; /* Hover effect outside the circle */
	outline-offset: -1x; /* Adjust the hover outline to sit over the border */
}

.avatar-ai-landscape img {
	height : 100%;
	width: auto;	
}
 
.avatar-ai-portrait img {
	width : 100%;
	height: auto;
}

.notfound{
	color: #cf6b00;
	font-weight: 400;
}

.bodyBackgroundColor{
	background-color: #fff !important;
}

.bodyModalHeight {
	height : 100vh;
}

/*Search with Tags*/

.my-dropdown{
	width: 650px;
	background-color: white;
}

.search-input-container {
	width: 100%;
	position: relative;
	display: inline-block;
}
  
.search-input-container input {
	padding-left: 45px;
}
  
.search-input-container .btn-magnifier-arrow {
	position: absolute;
	top: 0;
	left : 0;
	height: 100%;
	padding: 0.375rem;
}

.search-center .arrows{
	font-size: 1.5rem;
	color:white !important;
}

#inp::placeholder, .search-center .form-control::placeholder {
	color: #666;	
}

/*New AI Landing image*/

.login-form .btn-dark, .signup-form .btn-dark{
	background-color: #092854;
}

.login-form .form-control, .signup-form .form-control{
	background-color: #fff!important;
	border: 1px solid grey !important;
}

.login-form .btn-info{
	background-color: #092854;
}

.login-form h2, .login-form p{
	color: #797c7e;
}

.home-icons-font {
	font-size: 1.7rem;
}

/* Edit profile */
/* For Desktops */

.modal-dialog .form-group-local-scope-desktop{
	width: 100%!important;
}

/* Forgot password */

.forgot-password .login-form,
.enterCode .login-form,
.resetPassword .login-form {
	padding: 3rem 1rem;
   color: #fff;
   float: none;
	margin: auto;
	width: 25%;
	background-color: transparent;
	border: none;
}

.forgot-password .login-form h2, .forgot-password .login-form p,
.enterCode .login-form h2, .enterCode .login-form p,
.resetPassword .login-form h2, .resetPassword .login-form p {
	color: #fff;
}

/*Medeata Concierge Gallery*/

.consierge-desktop-gallery{
	height: 100%;
	background: none!important;
	text-align: center;
	padding-top: 1rem;
	margin: auto;
}

.consierge-desktop-gallery .after-app-wrap-images-div,
.consierge-desktop-gallery .profile-posts-and-channels {
	margin-bottom: 0;
	top: 0.5rem;
}

/*Centered version. not needed if not ised*/

.concierge-avatar-gallery{
	/* background: url(images/consierge-greet2-dark-desktop-transparent-gallery.png); */
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 25%;
	height: 150px;
	margin: 0;
	padding: 0!important;
}

.profile-block-gallery {
	padding-bottom: 2rem;
}

.profile-info-gallery, .profile-info-gallery span, .profile-info-gallery h4 {
	text-align: center;
}

.consierge .custom-file-upload {
	cursor: auto;
}

.align-left {
	text-align: left;
}

/*Video*/

/*New Concierge*/

.modal-fullscreen .form-control-radio{
	border: 1px solid #cccccc;
	width: 100%;
	height: 100%;
	transition: border 0.3s ease, box-shadow 0.3s ease;
}

.modal-fullscreen .form-control-radio:hover, .modal-fullscreen .form-control-radio:focus{
	border: 1px solid #666666;
	cursor: pointer;
}

.modal-fullscreen .form-control-radio{
	line-height: 1;
	display: flex;
	grid-template-columns: 1em auto;
	/* Prod 28 */
	grid-template-rows: 1em auto;
	gap: 1rem;
}

.modal-fullscreen .radio-container {
	display: flex;
	flex-direction: row;
	padding: 0;
	width: 100%;
}

.modal-fullscreen .radio-container-parent{
	display: flex;
	text-align: left;
	margin: auto;
	width: 60%;
}

.modal-fullscreen .form-control-radio .image{
	height: 100%;
}

.modal-fullscreen .form-control-radio p{
	/* margin-bottom: 0!important; */ /*that causes mobile uneven */
	padding: 0.5rem;
	text-align: center;
	margin-bottom: 0!important;
}

.modal-fullscreen .row{
	display: flex;
   justify-content: center;  /* Horizontally centers the h2 */
   align-items: center;      /* Vertically centers the h2 */
   /* height: 100px;   */ /*this looks ggod but does not let the input be on the bottom*/
}

.modal-fullscreen .selectOption {
	text-align: left;
	width: 60%;
}

.modal-fullscreen .selectOption p{
	padding: 1rem 0;
	text-align: left;
}

.modal-fullscreen .choice{
	padding-top: 2rem;
}

/*nEW CONCIERGE*/

.modal-fullscreen .form-control-radio {
	cursor: pointer;
}

.background-text, .background-text-studio {
	position: absolute;
	color: rgba(0, 0, 0, 0.07);
	font-weight: 500;
}

.login-background .imagine {
	color: #fff;
	font-weight: 400;
}

/* Target iOS devices */

.script {
	font-family: 'Mea Culpa', -apple-system, BlinkMacSystemFont, sans-serif!important;
}

/* Target Safari browsers */

@supports (-webkit-overflow-scrolling: touch) {
	.script {
		 font-family: 'Mea Culpa', sans-serif !important;
	}
}
/* Target Safari browsers (including iOS) */

.script:root:only-screen {
	font-family: 'Mea Culpa', sans-serif !important;
}

#aiInput{
	border-radius: 0;
}

.sharedPost {
	padding: 0!important;
}

.sharedPost .post-div{
	margin: auto;
}


/*ALL MEDIA*/

/***********************************MEDIA********************************/

/********************************Galaxy Fold Portrait.*******************/

@media (min-width: 1px) and (max-width: 280px) {

	.after-app-wrap-center-login-div-walk {
		background: url(images/vectorstock_25083512_edit_3.svg) 0px 530px;
		background-size: contain;
		background-repeat: no-repeat;
		height: 100vh;
  	}

	.login-form {
		width: 24vw;
		margin: 3rem auto;
	}

	.login-form-row-no-label {
		height: 72px;
		font-size: calc(14px * var(--koeff));
	}

	.btn{
		padding: 0.75rem 1rem;
	}

	p, input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p {
		font-size: calc(1rem * var(--koeff))!important;
	}

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(1.3rem * 1.00)!important;
	}

	/*Posts*/

	.home-icons-medium{
		font-size: calc(1.125rem * 1.00)!important;
	}

	.shared, .edit-post svg{
		font-size: calc(1.375rem * 1.00)!important;
	}
	
	/*Nav*/

	.nav .nav-item {
		padding-right: 0.5rem!important;
	}

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(1.5rem * 1.00)!important;
  }

	.nav .nav-link{
		padding: 0!important;
	}
	
	.nav .nav-item .btn{
		padding: 0!important;
		top: 0;
	}

	.nav .medeata-desktop {
		display: none;
	}

	.modal-body{
		padding: 0.2rem 0.3rem;
    	font-size: calc(12px * var(--koeff));
	}

	.profile-info h4 {
		font-size: calc(2rem * var(--koeff))!important;
	}

	.avatar-small-landscape, .avatar-small-portrait {
		width: 35px;
		height: 35px;
	}
	/*New user*/

	.after-app-wrap-walk-newuser-container{
		width: 100%;
	}

	.after-app-wrap-walk-newuser {
		height: 100px!important;
	}

	.newuser-mobile{
		width: 60vw!important;
		padding-bottom: 3rem;
	}

	.newuser-mobile h3 {
		font-size: calc(12rem * var(--koeff))!important;
	}

	/*Profile*/

	.icon-count{
		margin-top: 0!important;
	}

	.edit-post, .delete-post{
		margin-top: 1px!important;
	}

	/*Comments*/

	.comments-div {
		padding-bottom: 4rem;
	}	

	.talkbubble{
		max-width: 72vw;
	}

	/*Sharing functionality*/

	#sharedModal svg, #sharedModal .copy{
		height: 40px!important;
		width: 40px!important;
		margin-right: 0!important;
	}

	#sharedModal .copy svg {
		height: 30px!important;
		width: 40px!important;
		margin-top: 3px!important;
    	margin-left: 0px!important;
	}

	/* Concierge */
	.consierge .avatar-big, .consierge .avatar-big img {
		width: 60px;
		height: 60px;
	}
}

/********************************Old Iphone Portrai. Iphone4******************************/

@media (min-width: 281px) and (max-width: 319px) {

	.btn{
		padding: 0.75rem 1rem;
	}

	.nav .nav-item{
		padding-right: 1rem;
	}

	/*Login*/

	.login-form-row-no-label {
		height: 77px;
	}

	/*Sign up*/

	.sign-up{
		margin-top: 2rem;
	}

	.sign-up .password span{
		position: relative;
		left: 6.5rem!important;
	}

	/*Nav*/

	.nav{
		align-items: center;
	}

	.nav .nav-item{
		top: 0;
    	position: relative;
	}

	.nav .nav-user {
		padding: 0!important;
		padding-right: 1.2rem!important;
  	}

	.nav .nav-icon{
		font-size: calc(1.5rem * 1.00);	
	}

	.nav-link {
		display: block;
		padding: 0!important;
		position: relative;
  	}

	.nav .nav-item .btn{
		padding: 0;
	}

	/*Create post*/

	.modal-dialog{
		padding: 0.5rem;
	}

	.modal-body{
		padding: 0.5rem 0!important;
	}

	textarea[name=createpost], [name=createcomment] {
		min-height: 65px!important;
	}

	.createtextarea, .createcommentarea{
		min-height: 90px!important;
	}

	.counter-d {
		margin-top: 0.2rem;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		/* no koef */
		font-size: 7px;	
	}

	#counter{
		/* no koeff */
		font-size: 10px!important;
		line-height: 1.5;
	}

	.media-align{
		margin-top: 0;
	}

	.media-align .media-icon{
		width: 15px;
	}

	.media-align .btn{
		padding: 0.375rem 0.5rem;
    	font-size: calc(12px * var(--koeff));
		line-height: 0.5;
	}

	.alignflex .btn{
		float: right;
	}

	/*Posts*/

	.sams-fold{
		display: none;
	}

	.avatar {
		width: 38px!important;
		height: 38px;
	}

	/*Profile*/

	.profile-info h4 {
		font-size: calc(2.5rem * var(--koeff));
	}

	/*GetImages*/

	.image-text-div {
		height: 99px;	
	}

	.image-text-div img {
		width: 99px!important;
		height: 99px!important;
	}

	/*Comments*/

	.btn-mobile-comments {
		display: block;
		background-color: #00bcd4 !important;
		border: 1px solid #00bcd4 !important;
		color: white;
		border-radius: 50%;
  	}

	/*New user*/

	.after-app-wrap-walk-newuser {
		height: 120px!important;
	}

	.newuser-mobile{
		width: 60vw!important;
		padding-bottom: 3rem;
	}

	.newuser-mobile h3 {
		font-size: calc(2.75rem * var(--koeff))!important;
	}

	/*Shared post*/

	.sharedPost{
		top: 0!important;
	}

	.buttonsDiv{
		/* padding: 0.2rem; */
		font-size: calc(1.175rem * var(--koeff))!important;
	}

	.sharedComments{
		padding: 0.5rem;
		margin-top: 0;
	}

	/*AI*/

	.modal-fullscreen h6 {
		margin-top: 0.5rem!important;
		font-size: calc(0.75rem * 1.00)!important;
  	}

	/*AI*/

	/* #threeDots{
		height: 15px;
	} */

	/* Concierge */
	.consierge .avatar-big, .consierge .avatar-big img {
		width: 60px;
		height: 60px;
	}
}

/********************************Samsung Galaxy S8 Portrait, S9******************************/

@media (min-width: 320px) and (max-width: 359px) {
	.modal-header h4 {
		padding-top: 1rem;
	}

	.nav .nav-item {
		top: 0px;
		position: relative;
		padding-right: 1rem!important;
	}

	/*Login*/

	.login-form{
		margin: 0;
	}

	/*Create post*/

	.modal-dialog{
		padding: 0.5rem;
	}

	.modal-body{
		padding: 0.5rem 0!important;
	}

	textarea[name=createpost], [name=createcomment] {
		min-height: 65px!important;
	}

	.createtextarea, .createcommentarea{
		min-height: 90px!important;
	}

	.counter-d {
		margin-top: 0.2rem;
		border-radius: 50%;
		width: 25px;
		height: 25px;
		font-size: 7px;	
	}

	#counter{
		/* no koeff */
		font-size: 10px!important;
		line-height: 2;
	}

	.media-align{
		margin-top: 0;
	}

	.media-align .media-icon{
		width: 15px;
	}

	.media-align .btn{
		padding: 0.375rem 0.5rem;
    	font-size: calc(12px * var(--koeff));
		line-height: 0.5;
	}

	.modal-fullscreen .body-fullscreen-3{
		background: none !important;
	}

	/*GetImages*/

	.image-text-div {
		height: 31.3vw;	
	}

	.image-text-div img {
		width: 31.3vw!important;
		height: 31.3vw!important;
	}

	/*Account*/

	.edit-password .password span {
		left: 14rem!important;
  	}

	#savechanges{
		font-size: calc(12px * var(--koeff));
	}

	/*Delete account*/

	.delete-account .profile-info h3{
		margin-bottom: 1rem;
		font-size: calc(1.5rem * var(--koeff));
	}

	/*New user*/

	.after-app-wrap-walk-newuser {
		height: 120px!important;
	}

	.newuser-mobile{
		width: 60vw!important;
		padding-bottom: 3rem;
	}

	.newuser-mobile h3 {
		font-size: calc(2.75rem * var(--koeff))!important;
	}

	.btn{
		padding: 0.75rem 1rem;
	}

	/*End of Galaxy s8, s9 Portrait*/
}

/*Small ph including 0 ph. More PRECISE.*/

@media (min-width: 360px) and (max-width: 370px) {

	.poststyle{
		color: black;
	}
}

@media (min-width: 370px) and (max-width: 386px) {

	.post-username, .poststyle{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	/*General Fonts*/

	body, p, .profile-info span{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 225px!important;
	}

	.dropdown-menu, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff));
	}

	.medium{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Sign in*/

	.login-advertise h3 {
		font-size: calc(2.5rem * var(--koeff))!important;
	}

	.after-app-wrap-center-div {
		width: 100%;
		top: 168px;
	}

	.nav .nav-item{
		top: 0px;
			position: relative;
	}

	.nav .nav-link{
		padding: 0!important;	
	}

	.nav .dropdown button, .nav .dropdown .btn {
		padding: 0;
		line-height: 0.5;
	}

	.eye{
		top: -2rem; 
	}
	
	/*Create Post*/

	textarea[name=createcomment] {
		min-height: 325px!important;
	}

	/*Get Images*/

	.image-text-div {
		height: 32.8vw;	
	}

	.image-text-div img {
		width: 32.8vw!important;
		height: 32.8vw!important;
	}

	/*Sharing functionality*/

	#sharedModal .copy{
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	#sharedModal .copy svg{
		height: 35px;
		width: 40px;
		margin-top: 5px;
		margin-left: 4px;
	}

	#counter{
		/* no koeff */
		font-size: 10px;
	}

	/* Concierge */
	.consierge .avatar-big, .consierge .avatar-big img {
		width: 60px;
		height: 60px;
	}
}

/********************************Samsung Galaxy S8. Galaxy S Portrait*****************************/

@media (min-width: 320px) and (max-width: 370px) {

	input, .form-control, textarea, [name=createcomment], .dropdown-menu-report p {
		font-size: calc(1rem * var(--koeff));
	}

	.post-username, .poststyle{
		font-size: calc(1rem * var(--koeff)); 
		-webkit-text-size-adjust: none!important;
		text-size-adjust: none!important;
	}

	.small, .font-small{
		font-size: calc(0.85rem * var(--koeff))!important;
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.btn-lg{
		font-size: calc(1.125rem * var(--koeff))!important;
	}

	.nav .nav-item {
		padding-right: 1rem!important;
	}
	
	/*Login*/

	.login-form{
		margin: 1rem auto;
	}

	.login-advertise h3 {
		font-size: calc(2.5rem * var(--koeff))!important;
	}

	.eye {
		top: -2rem;
	}

	/*Sign up*/

	.sign-up {
		margin-top: 1rem !important;
	}

	/*General*/

	.modal-content .modal-close, .mobile-close {
		font-size: calc(1.5rem * var(--koeff))!important;
	}

	.modal-title{
		font-size: calc(16px * var(--koeff));
	}

	.modal-header h4 {
		font-size: calc(1.8rem * var(--koeff));
		padding-top: 1rem;
    	left: -2px;
		position: relative;
	}

	/*followers*/

	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(14px * var(--koeff));
	}

	/*Create post*/
	
	.modal-dialog{
		padding: 0.5rem;
	}

	textarea[name=createpost], [name=createcomment] {
		min-height: 140px!important;
	}

	.counter-d {
		margin-top: 0.3rem;
		width: 30px;
		height: 30px;
	}

	#counter{
		font-size: 10px!important;
		line-height: 1.5;
	}

	.media-align{
		margin-top: 0;
	}

	.media-align .media-icon{
		width: 20px;
		margin-top: 0.5rem;
	}

	.media-align .btn{
		padding: 0.5rem;
    	font-size: calc(12px * var(--koeff));
		line-height: 0.5;
	}

	/*Nav*/

	.nav .navitem-dropdown{
		padding-right: 0!important;
	}

	.nav .nav-user {
		padding-right: 1.2rem!important;
	}

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(1.7rem * 1.00); 
	}

	/*Profile*/
	
	.profile-block{
		margin-bottom: 0.5rem
	}

	.profile-block span, .profile-block p, .profile-block a{
		margin-bottom: 0!important;
	}

	.profile-block .btn{
		font-size: calc(14px * var(--koeff));
		padding: 0.175rem 0.5rem;
		margin: 0.5rem auto;
	}

	.profile-info h4 {
		font-size: calc(1.5rem * var(--koeff))!important;
    	margin-bottom: 0.5rem!important;
	}

	.icon-count{
		margin-top: 1px!important;
	}

	.shared .icon-count{
		margin-top: 3px!important;
	}

	/*Get Images*/

	.image-text-div {
		height: 31.5vw!important;	
	}

	.image-text-div img {
		width: 31.5vw!important;
		height: 31.5vw!important;
	}

	/*Delete account*/

	.delete-account .profile-info h3{
		margin-bottom: 1rem;
		font-size: calc(2rem * var(--koeff));
	}

	/*Message modal*/

	.message-modal p{
		font-size: 1rem!important;
	}

	/*About*/

	.account .small{
		font-size: calc(0.85rem * var(--koeff));
	}

	/*Comments*/

	.talkbubble {
		max-width: 62vw!important;
	}

	/*New user*/

	.after-app-wrap-walk-newuser {
		height: 130px!important;
	}

	.newuser-mobile{
		padding-bottom: 3rem;
	}

	.newuser-mobile h3 {
		font-size: calc(2.75rem * var(--koeff))!important;
	}

	.btn{
		padding: 0.3rem 1rem;
	}

	/*Shared post*/

	.sharedPost{
		top: 55px!important;
	}

	.buttonsDiv{
		/* padding: 0.2rem; */
		font-size: calc(1.175rem * var(--koeff))!important;
	}

	.sharedComments{
		padding: 0.5rem;
		margin-top: 0;
	}

	/*Sharing functionality*/

	#sharedModal .social, #sharedModal .copy{
		padding-right: 1rem;
	}

	#sharedModal .copy{
		margin-right: 0;
		padding-right: 0;
	}

	/*Artificial Intellegence Modal*/

	.modal-fullscreen{
		padding:0.4rem!important;
	}

	.modal-fullscreen .ai-content{
		overflow-y: unset;
	}

	.modal-fullscreen .choice div{
		padding: 0;
	}

	.modal-fullscreen .switch button{
		height: unset;
		width: unset;
	}

	.modal-fullscreen  .form-control-radio {
		width: auto; 
		float: left;
		/* padding-right: 1rem; */
  	}

	.modal-fullscreen textarea .avatar-ai, .avatar-ai{
		display:none;
		height: 0;
		width: 0;
	}

	.modal-fullscreen .try {
		font-size: calc(0.875rem * var(--koeff))!important;
    	line-height: 1;
  	}

	/* #threeDots {
		height: 15px!important;
	} */

	.modal-fullscreen .switch button {
		border-radius: 0;
		box-shadow: none;
	}

	.modal-fullscreen .choice {
		margin-bottom: 0;
		padding-top: 0;
  	}

	.modal-fullscreen  .mypostform .icon {
		font-size: 19px;
   }

	.avatar-ai-sm {
		width: 30px!important;
		height: 30px;
		float: left;
		border-radius: 50% 50% 50% 50%;
		overflow: hidden;
		margin: 3px 0;
  	}

	.modal-fullscreen .modal-body{
		padding: 0;
	}

	/* Concierge */
	.consierge .avatar-big, .consierge .avatar-big img {
		width: 60px!important;
		height: 60px!important;
	}

	.consierge .profile-info {
		padding: 0 0.5rem!important;
	}

	.modal-fullscreen .radio-container-parent {
		padding-top: 1rem;
  	}

	.modal-fullscreen .form-control-radio {
		height: 45px!important
	}

	.modal-fullscreen .form-control-radio p, .modal-fullscreen h6{
		font-size: calc(0.875rem * var(--koeff))!important;
	}

	/*End of O phone Portrait*/
}

/* iPhone with a width of 375px. Iphone 10S.*/

@media only screen and (min-width: 371px) and (max-width: 375px) {

	/*error goes in two lines, needs more space*/

	.login-form-row-no-label {
		height: 88px;
  	}
 }

/********************************Specific phone. Portrait*****************************/

@media (min-width: 370px) and (max-width: 389px) {
	
	.modal-header h4 {
		font-size: calc(1.8rem * var(--koeff));
		padding-top: 1rem;
    	left: -2px;
		position: relative;
	}

	/*Nav*/

	.nav .nav-item{
		padding-right: 1rem;
	}

	.nav .nav-user {
		padding-right: 1.2rem!important;
	}

	.nav .nav-icon{
		font-size: calc(1.5rem * 1.00);
	}

	/*I follow*/

	.right-div{
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
	}

	/*GetImages*/

	.image-text-div {
		height: 31.6vw;
	}

	.image-text-div img {
		width: 31.6vw!important;
		height: 31.6vw!important;
	}

	/*New user*/

	.after-app-wrap-walk-newuser {
		height: 120px!important;
	}

	.newuser-mobile{
		width: 60vw!important;
		padding-bottom: 3rem;
	}

	.newuser-mobile h3 {
		font-size: calc(2.75rem * var(--koeff))!important;
	}
}

/********************************Iphone 12 Mini Portrait*****************************/

@media (min-width: 360px) and (max-width: 389px) {

	.nav .nav-item{
		padding-right: 1rem;
	}

	/*Get Images*/

	.image-text-div {
		height: 32.7vw!important;
	}

	.image-text-div img {
		width: 32.7vw!important;
		height: 32.7vw!important;
	}

	.posFixed{
		position: fixed;
	}

	/*End of iphone 12.Portrait*/
}

/********************************Iphone 12 Portrait. Pixel 5 Portrait *****************************/

@media (min-width: 390px) and (max-width: 394px) {

	.nav .nav-item{
		padding-right: 1.5rem!important;
	}

	.nav .nav-user {
		padding-right: 1.5rem!important;
	}

	/*Create post*/

	textarea[name=createpost], [name=createcomment]{
		min-height: 210px!important;
	}

	#counter{
		margin-top: 0;
	}

	/*GetImages*/

	.image-text-div {
		height: 31.7vw;	
	}

	.image-text-div img {
		width: 31.7vw!important;
		height: 31.7vw!important;
	}

	/*New user*/

	.newuser-mobile{
		width: 60vw!important;
		padding-bottom: 3rem;
	}

	.newuser-mobile h3 {
		font-size: calc(2.75rem * var(--koeff))!important;
	}

	/*Comments*/

	.talkbubble {
		max-width: 63vw;
	}

	.posFixed{
		position: fixed;
	}
	/*End of iphone 12.Portrait*/
}


/***************ARTIFICAL INTELLEGENCE COMPONENT ON THE PHONES ONLY*******************/
/*************************************************************************************/

/*****ONLY ARTIFICAL INTELLEGENCE COMPONENT. Very small phones.*******/

@media (min-width: 281px) and (max-width: 319px) {
}

/*****ONLY ARTIFICAL INTELLEGENCE COMPONENT. Regular phones. (A32 on www included)*******/

@media (min-width: 320px) and (max-width: 359px) { 

	.modal-fullscreen .btn {
		padding: 0.2rem 1rem!important;
	}

	.avatar-ai-landscape, .avatar-ai-portrait {
		display: block;
		width: 45px!important;
		height: 45px!important;
	}

	.ai-input-border{
		border: 0!important;
	}

	.ai-textarea-mobile-n,
	.modal-fullscreen-content textarea[name=createpost] {
		width: 100%;
		margin-top: 0.5rem;
		padding: 0.5rem!important;
		font-size: calc(1rem * var(--koeff))!important;
		line-height: 1.5;
		border: 1px solid #ced4da;
		resize: none;	
		font-weight: 350!important;;
	}

	.modal-fullscreen-content textarea[name=createpost]{
		min-height: 140px!important;
		margin-top: 0.8rem!important;
	}

	.choice{
		margin-bottom: 1rem;
	}
}

/*****ONLY ARTIFICAL INTELLEGENCE COMPONENT. Small phones (0 ph.including) *****/

@media (min-width: 360px) and (max-width: 373px) {

	.modal-fullscreen .ai-content{
		overflow-y: unset;
	}

	.modal-fullscreen .choice div{
		padding: 0;
	}

	.modal-fullscreen .switch button{
		height: unset;
		width: unset;
	}

	.modal-fullscreen .switch button {
		border-radius: 0;
		box-shadow: none;
	}

	.modal-fullscreen  .mypostform .icon {
		font-size: 19px;
   }

	.modal-fullscreen .modal-body{
		padding: 0;
	}

	.avatar-ai-landscape, .avatar-ai-portrait {
		display: block;
		width: 45px!important;
		height: 45px!important;
	}

	.ai-input-border{
		border: 0!important;
	}

	.ai-textarea-mobile-n,
	.modal-fullscreen-content textarea[name=createpost] {
		width: 100%;
		margin-top: 0.5rem;
		padding: 0.5rem!important;
		font-size: calc(1rem * var(--koeff))!important;
		line-height: 1.5;
		border: 1px solid #ced4da;
		resize: none;	
		font-weight: 350!important;
	}

	.modal-fullscreen-content textarea[name=createpost]{
		/* height: 5.5rem; */
		min-height: 140px!important;
		margin-top: 0.8rem!important;
	}

	.choice{
		margin-bottom: 1rem;
	}

	.modal-fullscreen .imagine{
		height: 80px;
	}

	.modal-fullscreen .form-control-radio {
		margin-bottom: 0.5rem;
	}
}

/*****ONLY ARTIFICAL INTELLEGENCE COMPONENT. Bigger phones (F ph including).******************************/

@media (min-width: 374px) and (max-width: 390px) {

   .modal-fullscreen{
		overflow: auto!important;
	}

	.modal-fullscreen .btn{
		padding:0.3rem 0.5rem!important;
	}

	.avatar-ai-landscape, .avatar-ai-portrait {
		display: block;
		width: 55px!important;
		height: 55px!important;
	}

	.ai-textarea-mobile-n,
	.modal-fullscreen-content textarea[name=createpost] {
		margin: 0.5rem 0;
		width: 100%;
		padding: 0.5rem!important;
		font-size: 1rem;
		font-size: calc(1rem*var(--koeff))!important;
		font-weight: 350; /* According to main mobile style */
		line-height: 1.5;
		border: 1px solid #ced4da;
		resize: none;
		margin: 0;
		position: relative;
	}

	.modal-fullscreen-content textarea[name=createpost] {
		margin-top: 0.5rem;
		height: 11rem!important;
	}

	.ai-input-border{
		border: 0!important;
	}

	.choice{
		margin-bottom: 0!important;
	}
}

/****ONLY ARTIFICAL INTELLEGENCE COMPONENT.More bigger phones, no fonts******************************/

@media (min-width: 391px) and (max-width:413px) {

	.avatar-ai-landscape, .avatar-ai-portrait {
		display: block;
		width: 70px!important;
		height: 70px!important;
	} 

	.ai-input-border{
		border: 0!important;
	}

	.choice{
		margin-bottom: 1rem;
	}
}

/****ONLY ARTIFICAL INTELLEGENCE COMPONENT. All big Iphones starting (S ph including)********/

@media (min-width: 414px) and (max-width: 431px) {
	/* Prod 27 @media (min-width: 414px) and (max-width: 430px) { */
	.after-app-wrap-center-div-gotop .radio-title {  
		line-height: var(--conciergeLignHeight); /* Prod 27 */
    }

	.modal-fullscreen{
		padding: 1rem!important;
	}

	.ai-input-border{
		border: 0!important;
	}

	.ai-textarea-mobile-n{
		width: 100%;
		padding: 0.2rem 0.5rem;
		margin-top: 0.5rem;
		font-size: calc(1.25rem * var(--koeff))!important;
		line-height: 1.5;
		border: 1px solid #ced4da;
		resize: none;
	}

	.modal-fullscreen-content textarea[name=createpost] {
		width: 100%;
		padding: 0.2rem 0.5rem;
		margin-top: 0.5rem;
		font-size: calc(1.25rem * var(--koeff))!important;
		line-height: 1.5;
		border: 1px solid #ced4da;
		resize: none;
		height: 10rem;
	}

	.avatar-ai-landscape, .avatar-ai-portrait {
		display: block;
		width: 70px!important;
		height: 70px!important;
	}

	.choice{
		margin-bottom: 1rem;
	}
}

/*************END OF AI COMPONENT ONLY*************/
/**************************************************/

/***********************************************************************/

@media (min-width: 395px) and (max-width: 413px) {
	.edit-password .password span {
		left: 16rem!important;
  	}

	.modal-header h4 {
		font-size: calc(1.8rem * var(--koeff));
		padding-top: 3rem;
		left: -39px;
		position: relative;
  	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*GetImages*/

	.image-text-div {
		height: 112.5px;	
	}

	.image-text-div img {
		width: 112.5px!important;
		height: 112.5px!important;
	}

	.dialog{
		left: 0%;
		top: 20%;
		margin: auto;
		width: 100%;
		height: auto;
    	transform: translate(0%, -20%);
	}

	.dialog img{
		width: 100%;
	}

	/*New user*/

	.newuser-mobile{
		width: 60vw!important;
		padding-bottom: 3rem;
	}
}

/***************************Sams Galaxy 20 Ultra. A51/71. Iphone XR. Portrait******************/

@media (min-width: 412px) and (max-width: 415px) {

	/*General Fonts*/

	body, p, .profile-info span{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 225px!important;
	}

	.dropdown-menu, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff));
	}

	.medium{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Sign in*/

	.login-advertise h3 {
		font-size: calc(2.5rem * var(--koeff))!important;
	}

	.after-app-wrap-center-div {
		width: 100%;
		top: 168px;
	}

	.nav .nav-item{
		top: 0px;
    	position: relative;
	}

	.nav .nav-link{
		padding: 0!important;	
	}

	.nav .dropdown button, .nav .dropdown .btn {
		padding: 0;
		line-height: 0.5;
	}

	.eye{
		top: -2rem; 
	}
	
	/*Create Post*/

	textarea[name=createcomment] {
		min-height: 325px!important;
	}

	/*Get Images*/

	.image-text-div {
		height: 32.8vw;	
	}

	.image-text-div img {
		width: 32.8vw!important;
		height: 32.8vw!important;
	}

	/*Sharing functionality*/

	#sharedModal .copy{
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	#sharedModal .copy svg{
		height: 35px;
		width: 40px;
		margin-top: 5px;
		margin-left: 4px;
	}
}

/***************************Who is it?. Portrait********************************************/

@media (min-width: 416px) and (max-width: 420px) {
	.edit-password .password span {
		left: 19.5rem!important;
  	}

	.modal-header h4 {
		font-size: calc(1.8rem * var(--koeff));
		padding-top: 3rem;
		left: -39px;
		position: relative;
  	}

	/*GetImages*/

	.library-div-profile{
		padding: 1rem!important;
	}

	.image-text-div {
		height: 125px;	
	}

	.image-text-div img {
		width: 125px!important;
		height: 125px!important;
	}
}

/************* Most of the portret phones range intended only for Galaxy Fold Portrait. ******************************/

@media (min-width: 1px) and (max-width: 415px) {

	.sign-up{
		margin-top: 2rem;
	}
}

/********************************All Phones above 0 phone till 415 px. Portrait******************************/

@media (min-width: 370px) and (max-width:415px) {

	.post-username, .poststyle {
		font-size: calc(1rem * var(--koeff));
		text-size-adjust: none!important;
	}

	input, textarea, textarea[name=createcomment], .dropdown-menu-report p{
		font-size: calc(1rem * var(--koeff)); 
	}

	label{
		font-size: calc(1rem * var(--koeff));
	}

	.small, .font-small{
		font-size: calc(0.85rem * var(--koeff));
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.btn-lg{
		font-size: calc(1.125rem * var(--koeff))!important; 
	}

	label{
		font-size: calc(0.925rem * var(--koeff));
	}

	.modal-fullscreen .btn {
		margin-bottom: 0;
		padding: 0.5rem 1rem!important;
  	}

	/*Sign in*/

	.login-advertise h3 {
		font-size: calc(3rem * var(--koeff));
	}

	/*Nav*/

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(2rem * 1.00); 
	}

	.dropdown-menu, .dropdown-menu.show a {
		font-size: calc(1rem * var(--koeff));
	}

	/*Profile*/

	.profile-info h4{
		font-size: calc(3.2rem * var(--koeff));
	}

	/*Followers*/

	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff));
	}

	/*Sharing functionality*/

	.sharedPost{
		top: 0!important;
	}

	.buttonsDiv{
		/* padding: 0.2rem; */
		font-size: calc(1.175rem * var(--koeff))!important;
	}

	.sharedComments{
		padding: 0.5rem;
		margin-top: 0;
	}

	.mega-menu-share{
		width: 174px;
		padding: 5px;
		top: 37px;
	}
	
	.toast {
		flex-basis: 100vw!important;
		max-width: 100%!important;
		left: 0;
		z-index: 2000;
		bottom: 0;
	}

	/*Shared modal*/

	#sharedModal {
		position: fixed;
		top: auto;
		right: 0;
		left: 0;
		bottom: 0;
		height: 150px;
		box-shadow: 0 12px 75px 0 #e3e1e1,0 2px 4px 0  #f4f4f4,inset 0 0 0 1px  #f4f4f4;	
	}  

	#sharedModal svg, #sharedModal .copy{
		height: 50px;
		width: 50px;
		margin-right: 1rem;
	}

	#sharedModal .copy{
		margin-left: 1.5rem;
	}

	#sharedModal .copy svg{
		height: 35px;
		width: 40px;
		margin-top: 5px;
		margin-left: 4px;
	}

	#sharedModal .social {
		float: left;
	}
}

/********************************ALL PHONES. GENERAL PORTRAIT******************************/
/* Prod 27  */

@media (min-width: 1px) and (max-width:431px) {

	/*General*/

	html {
		overflow: unset; 
	}
	
	body {
		overflow: auto;
		background-image: url("images/vectorstock_25083512_edit_bkgr-original.svg");
		background-repeat: no-repeat;
		background-attachment: fixed;
		font-weight: 400!important;
		color: black!important;
		font-size: calc(1rem * var(--koeff));
	}

	.app, body, .rootdiv{
		width: 100%;
	}

	.modal {
		/* overflow: auto!important; */
		overflow: hidden!important;
	}

	.modal-dialog{
		background-color: #fff;
		width: 100%!important;
		margin-top: 0!important;
		padding: 0.5rem;
		border-radius: 0;
		/* styles below added to fix posts seen underneath while scroll */
		/* but commented and initiated in moddal-fullscreen because they cause 
		modal on the phone be stuck */
		/* position: fixed! important; */
		/* height: 100vh !important; */
	}

	.after-app-wrap-center-login-div-walk, 
	.after-app-wrap-center-login-div-walk-sign {
		position: fixed;
		height: calc(100vh - env(safe-area-inset-bottom));
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.edit-profile .modal-dialog{
		height: 100vh !important;
	}

	.center{
		display: block;
	}

	/*General Fonts*/

	p{
		-webkit-text-size-adjust: none!important;
		text-size-adjust: none!important;
	}
	
	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p, .profile-info p, .profile-info{
		font-size: calc(1rem * var(--koeff)); 
	}

	input, textarea, .form-control {
		/* it is bigger then in posts. would it cause problems? */
		font-weight: 400!important;
	}

	h5 {
		font-size: calc(1.25rem * var(--koeff))!important; 
	}

	.post-username, .poststyle{
		font-size: calc(1rem * var(--koeff)); 
		-webkit-text-size-adjust: none!important;
		text-size-adjust: none!important;
	}

	.poststyle{
		margin: 0!important;
	}

	.profile-info span{
		font-size: calc(1rem * var(--koeff)); 
	}
	
	.small, .font-small{
		font-size: calc(0.85rem * var(--koeff))!important;
		font-weight: 300!important;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 225px!important;
	}

	.dropdown-menu, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff)); 
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1.125rem * var(--koeff)); 
	}

	.btn-lg{
		font-size: calc(1.25rem * var(--koeff))!important; 
	}

	.ifollow p{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Sign in*/

	.login-advertise h3 {
		font-size: calc(3rem * var(--koeff));
	}

	.forgot-password .login-form, .enterCode .login-form, .resetPassword .login-form {
		width: 90%;
	}

	/*Nav*/

	.nav{
		margin-bottom: 0!important;
	}

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(1.6rem * 1.00); 
	}

	/*Profile*/

	.profile-info h4{
		font-size: calc(1.3rem * var(--koeff));
	}

	/*Followers*/

	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.suggested .username{
		font-size: calc(0.95rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff));
	}

	.modal-body p, .modal-body .btn-imitation{
		font-size: calc(1rem * var(--koeff))!important;
		margin-bottom: 0.4rem!important;
	}

	.modal-dialog-centered{
		top: 0!important;
	}
	
	.createtextarea, .createcommentsarea{
		width: 100%;
		padding: 0!important;
		padding-left: 0!important;
	}

	.signintooltip{
		min-width: 95vw;
	}

	/*Containers*/

	.after-app-wrap{
		width: 100%;
	}

	.after-app-wrap-center-login-div {
		background: none;
	}

	.after-app-wrap-walk-newuser{
		background: none!important;
		background-size: contain;
		background-repeat: no-repeat;
		height: 162px;
	}

	.after-app-wrap-center-div{
		width: 100%;	
		top: 168px;/*for followers on top on mobile*/
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}

	.postcard{
		margin: 0!important;
		border-radius: 0;
	}

	.after-app-wrap-center-div-gotop {
		width: 100%;
		top: 40px;
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}

	.after-app-wrap-center-div-cu{
		width: 100%;
	}

	.after-app-wrap-minus-nav{
		margin-left: 0;
	}
	
	.after-app-wrap-center-basic {
		position: relative;
		margin: auto;
		width: 100%;
	}

	.post-div-gotop{
		width: 100%;
		top: 0;
	}

	.after-app-wrap-center-div-comments{
		width: 100%;
	}

	.after-app-wrap-walk-newuser-container .newuser-mobile {
		width: 60%;
	}

	/*Profile*/

	.profile-posts-and-channels{
		top:0;
	}

	.profile-block{
		top: 0.5rem;
		position: relative;
		height: auto;
		background-color: #fff!important;
		margin-bottom: 0!important;
	}

	.profile-block .center, .profile-block .center-eup{
		padding-top:0.5rem;
	}

	.profile-block-gotop{
		top: 0px;
		position: relative;
		height: auto;
		background-color: #fff;
		padding: 1rem;
	}

	.profile-block span, .profile-block p, .profile-block a{
		margin-bottom: 0!important;
	}

	.profile-block .btn {
		font-size: calc(14px * var(--koeff));
		padding: 0.175rem 0.5rem;
		margin: 0.5rem auto;
	}

	.login-form-row-label-bio textarea{
		padding: 0.375rem 0.75rem
	}

	/*Consierge*/
	
	.consierge{
		/*background image removed*/
		background: none!important;
		padding-top: 1rem!important;
		height: 100%!important;
		top: 50px!important;
	}

	.consierge .profile-info{
		padding: 1rem !important;
		text-align: center;
	}

	/*Login and Signin*/

	.login-form-row-no-label {
		height: 80px;
	}

	.form-control, .login-form-row-no-label input, 
	.login-form-row-no-label input:focus, .btn-imitation, .login-form-row-no-label a{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Posts*/

	.post-div, .comment-div{
		width: 100%;
		margin-bottom: 2rem;
	}

	.post-avatar {
		width: 12vw;
	}

	.post-avatar .avatar{
		width: 40px!important;
		height: 40px!important;
		margin: 0.5rem!important;
	}

	.post-content {
		width: 86vw;
		padding: 0.5rem 1rem;
	} 

	.right-div-create-post{
		background: transparent;
		bottom: 1rem;
		right: 1rem;
		width: auto;
		top: unset;
		height: 80px;
	}

	.right-div-create-post button{
		border-radius: 50%;
		padding: 1.5rem;
	}

	.create-post .btn-mobile, .create-post .btn-mobile-comments{
		right: 1rem;
		position: fixed;
		font-size: calc(50px * 1.00);	
		display: block;
		background-color: #00bcd4 !important;
		border: 1px solid #00bcd4 !important;
		color: white;
		border-radius: 50%;
		z-index: 900;
		bottom: 1rem;
	}

	.icon-mobile{
		display: none!important;
	}

	.create-post .btn-desktop{
		display: none;
	}

	.image-parent{
		height: unset;
	}

	.image-parent img {
		width: 100%;
		height: unset!important;
	}

	.modal-open .modal {
		overflow-x: hidden;
		overflow-y: scroll;
  	}

	.card-wrapper{
		padding: 0;
	}

	/*I follow*/
	
	.right-div, .right-div-profile {
		width: 100%;
		height: 120px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div {
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
	}

	.ifollow p{
		margin-bottom: 0.5rem;
		font-size: calc(16px * var(--koeff));
	}

	.right-div .card, .right-div-profile .card, .scroll .card {   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 64px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}

	.search-card .tagline{
		display:block;
		color: black;
	}
	
	.username{
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.suggested p{
		font-size: calc(14px * var(--koeff));
		text-align: center;
	}

	.likes .suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: left;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested {
		padding: 0rem;
	}

	/*Icons*/

	.home-icons {
		font-size: calc(1.4rem * var(--koeff));
		color: rgb(22, 99, 214);
		padding-right: 3px;
	}

	.home-icons-medium{
		padding-right: 1px;
		font-size: calc(1.5rem * 1.00); 
	}

	.shared svg{
		font-size: 1.7rem!important;
	}

	.shared svg{
		font-size: calc(1.8rem * var(--koeff));
	}

	.mobile-col{
		padding-right: 0!important;
	}

	/*vertically centering icons*/

	.icon-div .left {
		display: flex;
		align-items: center; /* Align items vertically in the center */
  	}
  
	.icon-div .home-icons-medium {
		margin-right: 0.1rem; 
	}
  
	.icon-div .icon-count.medium {
		margin: 0; 
		line-height: 1.5;
	}

	.icon-div .icon-count{
		padding-left: 0;
		margin-top: 0;
		font-size: calc(1rem * 1.00); /*18px*/
	}

	.icon-div .menu-bar .icon-count.medium{
		line-height: 1.5;
	}

	/*Nav*/

	.nav{
		position: fixed;
		width: 100%;
		padding: 0 1rem!important;
		overflow: hidden;
	}

	.nav .nav-item{
		top: 0px;
    	position: relative;
		padding-right: 1.3rem;
	}

	.nav .navitem-dropdown{
		padding-right: 0!important;
	}
	
	.nav .dropdown button, .nav .dropdown .btn {
		padding: 0;
		line-height: 0.5;
	}

	.nav .nav-link{
		padding: 0!important;
	}

	.nav-item .create-post {
		top: unset;
		font-size: calc(3rem * var(--koeff));
	}

	.dropdown-menu, .dropdown-menu.show {
		position: fixed!important;
		width: 100vw!important;
		transform: translate3d(170px, 48px, 0px)!important;
		z-index: 3000;
		top: 7px!important;
		left: -170px!important;
	}

	.dropdown-modal{
		width: 100%!important;
		padding: 0;
		margin: 0;
		left: 0!important;
		right: 0!important;
	}

	.dropdown-menu, .dropdown-menu.show a{
		padding: 1rem;
	}

	.nav .medeata-desktop {
		display: none;
	}

	.nav .medeata-mobile, .medeata-mobile{
		display: block;
		position: fixed;
		width: 55px;
		height: 55px;
		left: 0.5rem!important;
		float: left;
		top: 0;
	}

	.nav .avatar-sm{
		width: 30px!important;
		height: 30px;
	}

	/*Login. Signup*/

	.login-nav, .login-form{
		width:100%;
	}

	.login-background .login-form{
		width: 100%;
		padding: 1rem;
		margin: 3rem auto;
		background: none;
		border: none;
	}

	.close-icon{
		display:block;
	}

	.sign-up .password span{
		left: 8rem;
	}

	.btn-login {
			margin: auto;
	}

	.check{
		margin: 0.7rem 0.5rem;
	}

	.margin-against-row {
		margin-right: 0;
		margin-left: 0;
	}

	.btn-login-signup {
			width: 90%!important;
			margin: auto;
	}

	/*big top padding needed for tooltip to show up*/
	.signup-form{
		width: 100%;
		padding: 2.5rem 0.5rem;
	}

	.signup-form .form-control{
		width: 98%;
	}

	.signup-form .input-align{
		width: 92%!important;
		padding: 0;
		margin: 0;
	}

	/*Posts*/

	.profile-info{
    	word-break:break-all;
	}

	.profile-info .row{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.notice{
		margin: 0 auto;
		float: none;
	}

	.post-div-profile{
		width: 100%;
		margin-right: 0;
	}
	
	/* fix for enlarged fonts for app. 12/19/24 */
	.post-div, .post-div-profile{
		margin-bottom: 1rem;
	}

	.post-div-profile{
		padding: 0.5rem 0;
	}

	.post-div{
		padding: 0.5rem 0;
	}

	.profile-posts-and-channels{
		width: 100%;
		margin: 0;
		padding: 0;
	}

	.edit-profile .login-form-row{
		padding-top: 2.8rem;
	}

	.login-form-row-label-bio {
		height: 80px;
		margin-bottom: 1rem;
	}

  	#bio{
		min-height: 97px;
	}

	#editWindow .modal-close{
		top: 2px;
    	position: relative;
		margin-right: 0.5rem;
	}

	#editProfile{
		margin-bottom:0.5rem;
	}

	.edit-icon{
		margin-right: 2rem;
	}

	.edit-post svg{
		font-size: calc(1.8rem * var(--koeff));
	}
	
	/*Get Images*/

	.library-div-profile {
		width: 100%;
		padding: 0;
		margin-bottom: 2rem;
	}

	.image-text-div{
		height: 31.7vw;
	}

	.image-text-div img {
		width: 31.7vw;
		height: 31.7vw;
	}

	.img-match{
		top: 6px;
	} 

	.after-app-wrap-images-div{
		width: 100%;
		padding-top: 0;
	}

	.dialog{
		left: 0%;
		top: 20%;
		margin: auto;
		width: 100%;
		height: auto;
    	transform: translate(0%, -20%);
	}

	.dialog img{
		width: 100%;
	}

	.overlay{
		display: none;
	}

	/*Account*/

	.account-form {
		padding: 1rem;
		height: 80vh;
	}

	.account-form h3{
		font-size: calc(1.375rem * var(--koeff));
	}

	h3{
		font-size: calc(1.475rem * var(--koeff));
		padding-top: 1rem;
	}

	.btn-security{
		padding: 0.5rem 0!important;
		padding-left: 0!important;
		padding-right: 0!important;
	}

	#security{
		margin: 0.5rem 0 1.2rem 0;
	}

	.modal-dialog-centered{
		height: 250px!important;
	}

	/*Create post*/

	#report{
		height: auto!important;
	}

	.avatar-mobile{
		display: none!important;
	}

	.mypostform {
		padding-top: 0;
	}

	.modal-dialog .modal-body h5{
		padding: 1rem 0!important;
	}

	.alignflex .btn{
		float: right;
	}

	textarea[name=createpost], [name=createcomment]{
		min-height: 140px!important;
		padding: 0.5rem!important;
		margin-top: 0.5rem!important;
	}
	
	.image-parent-create-post {
		margin-top: 1rem;
		height: auto; 
	}

	.counter-d {
		line-height: 1.5;
		margin-top: 0.4rem;
	}

	#counter{
		/* no koeff */
		font-size: 14px!important;
		padding-top: 0.1rem;
	}

	.mypostform .icon{
		font-size: 22px;
  	}

	/*Comments*/

	.after-app-wrap-center-div-comments{
		width: 100%;
		top: 48px;
		padding: 0.5rem;
		position: relative;
	}

	.comments-div{
		width: 100%;
	}

  	.showlibrary{
		display: none;
	}

	.comments-div .row{
		margin-right: 0;
		margin-left: 0;
	}

	.comments-div .discussion{
		margin-bottom: 1rem;
	}
  
	.talkbubble {
		max-width: 78vw;
    	min-width: 50vw;
		padding: 0.5rem 1rem 1rem;
	}

	/*Message modal*/

	.message-modal .modal-body p, .message-modal p {
		padding-left: 2rem;
	}

	.comments-div .postrow p {
		margin-bottom: 0!important;
		margin: 0;
		padding: 0;
		position: relative;
		text-align: left;
		padding-left: 0.5rem;
	}

	.createcommentarea {
		height: auto;
		min-height: 230px;
		width: 100%;
		margin: 0;
		padding-left: 0!important;
  	}

	.comments-topost{
		padding: 1rem;
	}

	/*Settings. Account*/

	.settings h3{
		font-size: calc(1.8rem * var(--koeff));
    	padding-top: 1rem;
	}

	.edit-password{
		padding: 0;
	}

	.account-form svg{
		font-size: calc(1.25rem * 1.00);
	}

	/*Home tooltip*/

	.mytooltip{
		display: none!important;
	}

	/*Signup tooltip*/

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	.contact-us{
		width: 100%;
		text-align: center;
		padding-top: 0;
		position: relative;
	}

	.contact-us-sticky{
		width: 100%;
	}

	.modal-view{
		width: 100%;
	}

	.error-message, .error-message span {
		/* padding: 0.2rem 0; */
		/* if we use koeff below then the height should be dynamic too */
		/* font-Size: calc(1rem*var(--koeff))!important; */
		font-size: 1rem;
	}

	.modal-body{
		padding: 0rem!important;
    	font-size: calc(1rem * var(--koeff));
	}

	/*Nessage modal*/

	.message-modal{
		width: 95%!important;
		height: auto;
		text-align: left;
	}

	.about{
		text-align: left;
		margin: 0;
		padding: 1rem;
	}

	.profile-info-account h3, p {
		margin-bottom: 1rem;
	}

	/*New User*/

	@font-face { 
		font-family: Mea Culpa; 
		/* src: url('https://ocafprod.s3.us-east-2.amazonaws.com/font/MeaCulpa-Regular.ttf') format('truetype');  */
		src: url('css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
	} 

	.after-app-wrap-walk-newuser-container{
		width: 100%;
		margin-top: 3rem;
	}

	.after-app-wrap-walk-newuser-container .after-app-wrap-walk-newuser{
		background-size: 100%;
	}

	.after-app-wrap-walk-newuser-container .newuser-mobile{
		width: 60%;
		margin: auto;
	}

	.newuser-mobile h3{
		font-family: 'Mea Culpa';
		color: #0088aaff;
		font-size: calc(3rem * var(--koeff));
		line-height: 1;
	}

	.newuser-mobile{
		display: block;
		width: 100vw;
	}

	.info-f{
		padding-left: 0.5rem;
	}

	/*About*/

	.about p, .about a{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.about span{
		font-size: calc(0.875rem * var(--koeff));
	}

	/*Sharing functionality*/

	.sharedPost{
		top: 55px!important;
	}

	.buttonsDiv{
		/* padding: 0.5rem; */
		font-size: calc(1.175rem * var(--koeff))!important;
		float: right;
	}

	.buttonsDiv .btn{
		float:left;
	}

	.sharedComments{
		padding: 0.5rem;
		margin-top: 55px;
	}

	/*Shared post*/

	/*Sharing menu with negative margin*/

	.sharedPost .nav{
		position: fixed;
		width: 100%;
	}

	.sharedPost .postcard{
		/* top: 55px; */
	}

	.mega-menu-share{
		width: 174px;
		padding: 5px;
		top: 37px;
	}

	.toast {
		flex-basis: 100vw!important;
		max-width: 100%!important;
		left: 0;
		z-index: 2000;
		bottom: 0;
	}

	/*Shared modal*/

	#sharedModal {
		position: fixed;
		top: auto;
		right: 0;
		left: 0;
		bottom: 0;
		height: 105px;
		box-shadow: 0 12px 75px 0 #e3e1e1,0 2px 4px 0  #f4f4f4,inset 0 0 0 1px  #f4f4f4;	
	}  

	#sharedModal .social, #sharedModal .copy{
		float: left;
	}

	#sharedModal svg, #sharedModal .copy{
		height: 50px;
		width: 50px;
	}

	#sharedModal .copy{
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	#sharedModal .copy svg{
		height: 35px;
		width: 40px;
		margin-top: 5px;
		margin-left: 4px;
	}

	.btn-mea{
		width: 100%;
		font-size: calc(2rem * var(--koeff))!important;
	}

	.search-container{
		top: 33px!important; /* on iphones bigger gap */
		background-color: #fff;
		padding-bottom: 0;
	}

	.search-container-sleepingcat{
		top: 56px!important;
		padding-bottom: 0;
	}

	.search-container-add {
		padding-bottom: 0!important;
	}

	.center-container-small {
		width: 100%;
		/* padding: 0.5rem; */
	}

	.search-input-container{
		width: 100%;
	}

	.search-center .searchbtn{
		float: right;
	}

	.search-results{
		padding-right: 1rem;
		display: initial;
		flex-shrink: 0;
	}

	.search-card {
		width: 100%!important;
		min-width: unset;
		margin: 0;
		line-height: 1.3
	}

	.search-card .avatar-small-landscape, .search-card .avatar-small-portrait{
		margin: 0;
		position: absolute;
		top: 46%;
		left: 10%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.search-card .info-f{
		word-break: break-word;
		margin: 0;
		position: absolute;
		top: 46%;
		left: 57%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	.search-card p{
		text-align: left;
		font-size: calc(1.25rem * var(--koeff));
	}

	.search-card .suggested .username{
		font-size: calc(1rem * var(--koeff)); /* cant do important since it is bigger on big iphone */
		font-weight: 500;
	}
	
	.suggested .username{
		/* Removing koeff here because when enlarged on app 
		it overflows the post container */
		/* font-size: calc(1rem * var(--koeff))!important; */
		font-size: 1rem!important;
		font-weight: 300;/*Followers in circle*/
	}

	.search-card .medium {
		font-size: calc(0.9rem * var(--koeff))!important;
	}

	.search-card .info-f {
		padding-left: 1rem;
	}

	.new{
		background: url(images/search-sm.png) no-repeat scroll 7px 7px;
		padding-left: 2.5rem!important;
		background-color: white;
    	border: 1px solid grey;
		position:fixed;
		z-index: 1000;
		top:55px;
	}

	/*Modal*/

	.alignflex .modal-myheader h6, .modal-myheader h6 {
		font-size: 1.25rem;
	}

	/*Delete your comment*/

	.alignflex .action-name {
		margin: 0.75rem 0 0.5rem 0!important;
		float: left;
	}

	/*Preferences. Radio*/

	.radio-form{
		width: 100%;
		padding: 1rem 0.5rem;
		margin: 0;
	}

	.after-app-wrap-center-div-gotop .radio-title {
		padding: 1.3rem 0;
		font-size: calc(1.25rem * var(--koeff));
	}

	.radio-container-parent, .radio-title{
		display: block;
		align-items: left;
		justify-content: left;
		text-align: left;
		padding: 0.5rem 0;
    	padding-left: 0!important;
		margin-bottom: 0.5rem;
	}

	.radio-container{
		margin: 0;
		padding: 0;
	}

	/*Artificial Intellegence*/

	.modal-fullscreen .ai-content{
		overflow-y: unset;
	}

	.ai-consierge, .ai-content, .ai-row, .ai-input, .ai-consierge-response{
		width: 100%;
	}

	.user-response, .ai-response{
		width: 80%;
		margin: 1rem;
		min-height: 50px;;
	}

	.ai-input input {
		height: 70px;
		color: white;
		padding-right: 3rem;
  	}

	/*Artificial Intellegence Modal. Portrait*/

	.modal-fullscreen {
		position: fixed!important; 
		top: unset!important;
		left: unset!important; 
		right: unset!important;
		bottom: unset!important;
		z-index: 9999;
		max-height: unset!important; 
		height: 100vh!important; 
		border-radius: 0!important;
		box-shadow: none!important;
		margin: 0!important;
		/* Prod 28 */
		/* request 
		/* overflow: hidden!important; */
  	}

	.modal-fullscreen h6{
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		color: white;
      font-size: calc(0.975rem * 1.00);
		color: #0088aa;
	}

	.modal-fullscreen-content{
		width: 100%;
	}

	.avatar-ai{
		display: block;
		width: 50px;
    	height: 50px;
	}

	.modal-fullscreen .ai-concierge{
		width: 100%;
		position: relative;
	}

	.modal-fullscreen .modal-space{
		margin: 0;
		padding: 0!important;
	}

	.modal-fullscreen .user-response{
		margin: 0;
		padding: 0;
	}

	.modal-fullscreen .switch{
		width: 100%;
	}

	.modal-fullscreen .ai-input {
		position: relative;
		left: 0;
		right: 0;
		height: 100px;
		width: 100%;
		margin: auto;
	}

	.modal-fullscreen .try{
		float: left;
		font-size: calc(0.875rem * var(--koeff));
		line-height: 1;
	}

	.modal-fullscreen .ai-concierge .image-parent-create-post{
		max-width: 100%;
		margin: auto;
	}

	.modal-fullscreen .image-parent-create-post{
		max-width: 100%;
		margin: auto;
	}

	.modal-fullscreen .ai-input img{
		height: 35px;
		position: relative;
		float: left;
		margin: 0.2rem 0.3rem;
	}

	.ai-row .requests{
		line-height: 1.8;
		text-align: left;
	}

	.ai-content .radio-container label {
		font-size: calc(0.875rem * var(--koeff));
  	}

	.posFixed{
		position: fixed;
	}

	/* #threeDots{
		height: 15px;
	} */

	.threeDotsPosition{
		float: none;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.modal-fullscreen .btn {
		margin-bottom: 0;
		padding: 0.2rem 1rem;
  	}

	.avatar-ai-sm{
		width: 35px!important;
		height: 35px;
		float: left;
		border-radius: 50% 50% 50% 50%;
		overflow: hidden;
		margin: 3px 0;
	}

	.modal-fullscreen .media-icon{
		margin: 0.25rem!important;
		height: 35px;
	}
	
	#labelAI .media-icon{
		height: 40px;
	}
	
	#labelAI .media-icon svg{
		font-size: 29px;
		padding-left: 1rem;
	}

	.modal-fullscreen .image-parent-create-post img {
		/* commented in order to take all available width */
		/* max-height: 43vh!important; */
		border: 1px solid #ced4da;
		padding: 0.5rem;
  	}

	.modal-fullscreen .alignflex {
		padding: 0rem;
	}

	.modal-fullscreen .btn-look{
		width: 100%;
		visibility : var(--aiVisibility);
	}

	.modal-fullscreen .line{
		padding: 0;
	}

	.modal-fullscreen #label, .modal-fullscreen #labelAI{
		width:12vw;
	}

	.modal-fullscreen .media1 {
		padding-top: 0rem!important;
		padding-left: 0.5rem;
  	}

	.modal-fullscreen .send{
		background-color: rgb(48, 210, 250);
		border-radius: 6px;
		width: 45px;
		height : var(--aiSendArrowHeight);
		opacity : var(--aiArrowOpacity);
		margin: 0.5rem 0rem;
	}

	.mobile-close, .modal-content .modal-close {
		font-size: 1.5rem!important;
		height: 25px;
  	}

	.body-fullscreen-3-noimage {
		width: 100%;
		height: 100vh;
		margin: 0;
	}

	#remainingAIrequests{
		font-size: calc(0.875rem * var(--koeff));
		line-height: 1.6;
	}

	.background, .bkgrnd{
		background: transparent;
		background-color: transparent!important;
	}

	/****General AI fot general phones(including Samsung A32 on VPS (mediotate.com)**********/
	/*There is also "ARTIFICIAL INTELEGENCE COMPONENT" because of special attention*/
	/*****Placing stays like this because of interference of styles*****************/

	.modal-fullscreen .ai-content{
		overflow-y: unset;
	}

	.modal-fullscreen .choice div{
		padding: 0;
	}

	.modal-fullscreen .switch button{
		height: unset;
		width: unset;
	}

	.modal-fullscreen .try {
		font-size: calc(0.875rem * var(--koeff));
    	line-height: 1;
  	}

	.modal-fullscreen .switch button {
		border-radius: 0;
		box-shadow: none;
	}

	.modal-fullscreen  .mypostform .icon {
		font-size: 19px;
   }

	.modal-fullscreen .modal-body{
		padding: 0;
	}

	.modal-fullscreen h6{
		font-size: calc(1rem * var(--koeff)) !important;
	}

	.modal-fullscreen .btn {
		padding: 0.2rem 0.5rem;
		margin: 0.5rem;
	}

	.avatar-ai-landscape, .avatar-ai-portrait {
		display: block;
		width: 55px;
		height: 55px;
	}

	.ai-input-border{
		border: 0!important;
	}

	.ai-textarea-mobile-n,
	.modal-fullscreen-content textarea[name=createpost] {
		width: 100%;
		margin-top: 0.5rem;
		padding: 0.5rem;
		font-size: calc(1rem * var(--koeff));
		line-height: 1.5;
		border: 1px solid #ced4da;
		resize: none;	
	}

	.ai-textarea-mobile-n {
		height: calc(1px * var(--aiTextareaHeight)) !important;
		opacity : var(--aiOpacity);
		width: 100%;
		padding: 0.5rem;
		font-size: calc(1rem * var(--koeff));
		font-weight: 300;
		line-height: 1.5;
		border: 1px solid #ced4da;
		resize: none;
		margin: 0;
		position: relative;
	}

	.ai-textarea-mobile-n::placeholder {
		/* New placeholder style for testing */
		/* Placed here for more specific classes */
		color:rgba(0, 0, 0, 0.5);
	}

	.modal-fullscreen-content textarea[name=createpost]{
		height: 5.5rem;
		min-height: 140px;
		margin-top: 0.8rem;
	}

	.modal-fullscreen .form-control-radio {
		line-height: 1;
	}

	.modal-fullscreen input[type=radio] {
		-webkit-transform: translateY(calc(0.1rem*var(--koeff)));
    	transform: translateY(calc(0.1rem*var(--koeff)));
	}

	.modal-fullscreen .send {
		width: 37px;
		height : var(--aiSendArrowHeight);
		opacity : var(--aiArrowOpacity);
		margin: 0.5rem 0rem;
  	}

	.modal-fullscreen  .radio-container-parent{
		/* padding: 1rem 0; */
		width: 100%;
	}

	.choice{
		margin-bottom: 1rem;
	}

	.modal-fullscreen .body-fullscreen-3{
		width: 100%;
		height: var(--conciergeImageHeight);
		margin: 0;
		padding: 0!important;
  	}

	/*End of General AI (including Samsung A32 on VPS*/

	.App-div{
		height: unset;
	} 
	
	/*Search with tags*/

	.search-center-parent{
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		background-color: white;
		height: 53px;
    	width: 100%;
	}

	.search-center {
		position: fixed;
		z-index: 1000;
		top: 0.25rem;
		width: 98%;
		left: 0.2rem;
	}
	
	.search-center input{
		width: 100%;
		font-weight: 350;
		border: 1px solid #0cf;
		color: black!important;
		height: 45px;
	}

	.search-input-container .btn-magnifier-arrow {
		position: absolute;
		top: 0;
		left : 0;
		height: 100%;
		/* padding: 0.075rem 0.75rem 0.375rem 0.75rem; */
      display: flex;  /* Use flexbox for layout */
      align-items: center;  /* Center items vertically */
      justify-content: center; /* Center items horizontally */
      width: 45px; /* to match 45px height in order to be sqaure */
      padding: 0;
	} 

	.search-input-container input {
		padding-left: 57px;
  	}

	.search-center .form-control{
		border: 2px solid #0cf!important;
		border-radius: 0.5rem!important;
  	}

	.search-center .btn{
		border-radius: 0.5rem!important;
	}

	#inp::placeholder, .search-center .form-control::placeholder {
		color: grey!important;
	}

	.login-form .form-control, .signup-form .form-control{
		background-color: #fff!important;
		border: 1px solid grey !important;
	}

	.login-form .x-input::placeholder,
	.signup-form .x-input::placeholder,
	.login-form .x-input,
	.signup-form .x-input {
		font-size: 1.1rem;
	}

	.login-form-row label, .login-form-row-label-bio label,
	.login-form-row-label-tag label {
		float:left;
		font-size: calc(0.925rem * var(--koeff));
		margin: 0.1rem 0!important;
		color: rgb(51, 51, 51, 0.7);
	}

	.btn-mea {
		border: 1px solid #fff !important;
    	background-color: transparent !important;
    	color: #fff !important;
	}

	/* Concierge */
	.consierge .avatar-big, .consierge .avatar-big img {
		width: 70px;
		height: 70px;
	}

	/*New Concoerge*/

	.modal-fullscreen .radio-container{
		display: unset;
	}

	.modal-fullscreen .radio-container-parent {
		padding-top: 1.5rem;
	}

	.modal-fullscreen .form-control-radio {
		margin-bottom: 1rem;
		border-radius: 30px;
		float: left;
	}

	.modal-fullscreen .profile-info .row{
		justify-content: left;
	}

	.modal-fullscreen .form-control-radio .image{
		display: none;
	}

	.modal-fullscreen .form-control-radio p{
		text-align: center;
		padding: 0.5rem;
		color: black;
	}

	.modal-fullscreen h2{
		font-size: 2rem;
		word-break: auto-phrase;
	}

	.color1 {
		background: rgba(0, 188, 212, 0.2); /* Light blue (30% opacity) */
  	}
  
	.color2 {
			background: rgba(128, 0, 128, 0.2); /* Purple (30% opacity) */
	}
  
	.color3 {
			background: rgba(255, 69, 0, 0.2); /* Orangered (30% opacity) */
	}
  
	.color4 {
			background: rgba(0, 0, 255, 0.2); /* Blue (assuming var(--blue) is #0000FF) */
	}

	.ai-concierge .user-response, .ai-concierge .profile-info,
	.ai-concierge .user-response .row,
	.ai-concierge .user-response .row .row{
			width: 100%!important;
	}

	.ai-concierge .col-6{
		padding: 0.3rem;
	}

	.modal-fullscreen .form-control-radio{
		height: 50px;
		display: flex;
		align-items: center; /* Center vertically */
		justify-content: center; /* Center horizontally */
		margin: 0; 
		width: 100%;
	}

	.modal-fullscreen .form-control-radio .image {
		display: none;
  	}

	  .modal-fullscreen .form-control-radio p {
		text-align: center;
		padding: 0.5rem;
		color: black;
 	}

	.background-text, .background-text-studio{
			display: none;
	}

	.modal-fullscreen .choice {
		padding-top: 0;
  	}

 	/*New login*/

  	.login-background .imagine{
		font-size: 3rem;
		text-align: left;
		font-weight: 500;
		padding: 0 2rem;
  	}

  .script{
		font-family: 'Mea Culpa'; 
		/* src: url('https://ocafprod.s3.us-east-2.amazonaws.com/font/MeaCulpa-Light.ttf') format('truetype'); */
		src: url('css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
		text-align: left; 
		font-size: 2rem;
		font-weight: 300!important;
		display: block;
  }

  .cat-container{
		top: -5%;
      transform: translate(-5%, 3%);
  }

	/*End of ALL PHONES. PORTRAIT*/
}

/****All big phones starting 414px. Portrait.*******/

@media (min-width: 414px) and (max-width: 431px){

	.profile-info span, body, p {
		font-size: calc(1.25rem * var(--koeff))!important;
  	}

	.form-control, .login-form-row-no-label input, 
	.login-form-row-no-label input:focus, 
	.btn-imitation, 
	.login-form-row-no-label a {
		font-size: calc(1.25rem * var(--koeff))!important;
  	}

	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1.25rem * var(--koeff))!important; 
	}

	h5{
		font-size: calc(1.375rem * var(--koeff))!important; 
	}

	.search-card .tagline {
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow {
		font-size: 1.025rem!important;
	}

	.icon-count {
		font-size: 1.25rem!important;
		line-height: 1.1 !important;
  	}

	.login-form{
		width: 90vw!important;
		margin-top: 4rem!important;
	}

	.eye {
		top: -2.5rem;
	}

	.consierge{
		height: 950px;
		background: url(images/consierge-greet2.png) -304px 202px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.post-username, .poststyle,
	.search-container .username,
	.search-option {
		font-size: calc(1.25rem * var(--koeff))!important; 
		text-size-adjust: none!important;
  	}

	.search-dropdown {
		top: unset!important;
	}

	.modal-fullscreen .btn {
		margin-bottom: 0.5rem;
		padding: 0.5rem 1rem!important;
  	}

	.modal-fullscreen-content textarea[name=createpost]{
		font-size: calc(1.25rem * var(--koeff))!important;
	}

	/*some landscapes in phone, to be verified*/

	.login-form{
		width: 90vw!important;
		margin-top: 4rem!important;
	}

	.consierge{
		height: 950px;
		background: url(images/consierge-greet2.png) -304px 202px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	/*search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}
}

/********************************Galaxy S9. Landscape?******************************/

@media (min-width: 421px) and (max-width: 520px){

	/*Create Post*/

	.mypostform .avatar-sm{
		display: none;
	}
	
	/*GetImages*/

	.image-text-div{
		height: 130px;
	}

	.image-text-div img {
		width: 130px!important;
		height: 130px!important;
	}

	/*Radio*/

	.radio-container-parent, .radio-title{
		display: unset;
		align-items: left;
		justify-content: left;
		text-align: left;
	}
}
	
/********************************ALL PHONES. GENERAL LANDSCAPE******************************/
/*O Tablet landscape*/

@media (min-width: 432px) and (max-width: 850px){
/* Prod 27 @media (min-width: 416px) and (max-width: 850px){ */

	/*General Fonts*/

	body{
		font-size: calc(1.0625rem * var(--koeff));
	}
	
	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1.0625rem * var(--koeff));
	}
	
	.small, .font-small{
		font-size: calc(1.0625rem * var(--koeff));
	}

	h5 {
		font-size: calc(1.25rem * var(--koeff))!important; 
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 300px!important;
	}

	.dropdown-menu, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff)); 
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.btn {
		font-size: calc(1.25rem * var(--koeff)); 
	}

	.btn-imitation{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.btn-lg{
		font-size: calc(1.5rem * var(--koeff))!important; 
	}

	.ifollow p{
		font-size: calc(1rem * var(--koeff))!important;
	}

	label{
		font-size: calc(0.925rem * var(--koeff))!important;
		color:rgb(51, 51, 51, 0.8);
	}

	/* supposed to be only for phones landscape edit profile*/

	.modal-dialog {
		padding: 0.5rem;
		border-radius: 0;
	}

	/*Login and Edit Profile*/

	.form-control, .login-form-row-no-label input, .login-form-row-no-label input:focus, textarea {
		font-size: calc(1rem * var(--koeff))!important;
	}

	.btn-mea {
		font-size: calc(2rem * var(--koeff))!important;
	}

	/*Sign in*/

	/* New styles to adress small distance after login on sign uo */

	.sign-up {
		margin-top: 0;
		margin: 1rem auto;
		width: 45vw;
  	}

	.padding {
		padding-bottom: 2rem;
	}

	.login-background .login-form{
		margin: 3rem auto;
		float: unset;
		width: 55vw;
	}

	.login-advertise h3 {
		font-size: calc(3rem * var(--koeff));
	}

	.forgot-password .login-form, .enterCode .login-form, .resetPassword .login-form {
		width: 60%;
	}

	/*Nav*/

	.nav{
		margin-bottom: 0!important;
	}
	
	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(2rem * 1.00); 
	}

	/*Profile*/

	.alignflex {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.edit-profile .form-group h5{
		margin-bottom: 1rem;
	}

	.edit-profile .login-form-row-label-tag{
		height: 75px;
	}

	/*Followers*/

	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff));
	}

	.modal-body p, .modal-body .btn-imitation{
		font-size: calc(1rem * var(--koeff))!important;
		margin-bottom: 0.5rem!important;
	}

	/*End of General Fonts*/

	/*Login and Sign up*/

	.App-div{
		height: unset;
	}

	.modal-dialog-centered {
		height: 205px;
		max-width: 600px!important;
		top: -10px!important;
	}

	.login-form .btn-mea{
		font-size: calc(2rem * var(--koeff))!important;
	}

	.signup-form {
		width: 60vw;
	}

	/*Posts*/

	.post-div{
		width: 100vw;
		margin: auto;
	}

	.image-parent img {
		width: 100%;
		height: unset!important;
	}

	.edit-post svg, .shared svg{
		font-size: 1.9rem;
  	}

	/*Profile*/
	
	.post-div-profile{
		padding: 0;
		margin-right:0;
	}

	.profile-block, .profile-block-gotop{
		margin-bottom: 0;
	}

	.profile-block a, .profile-block p, .profile-block span{
		margin-bottom: 0!important;
	}

	.profile-info h4 {
		font-weight: 400;
	}

	.icon-div{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/*Shared post*/

	.sharedPost{
		top: 0!important;
	}

	.buttonsDiv{
		/* padding: 0.2rem; */
		font-size: calc(1.175rem * var(--koeff))!important;
	}

	.sharedComments{
		padding: 0.5rem;
		margin-top: 0;
	}

	/*Sharing functionality*/

	.sharedPost{
		top: 0!important;
	}

	.buttonsDiv{
		/* padding: 0.2rem; */
		font-size: calc(1.175rem * var(--koeff))!important;
	}

	.sharedComments{
		padding: 0.5rem;
		margin-top: 0;
	}

	.mega-menu-share{
		width: 174px;
		padding: 5px;
		top: 37px;
	}
	
	.toast {
		flex-basis: 100vw!important;
		max-width: 100%!important;
		left: 0;
		z-index: 2000;
		bottom: 0;
	}

	/*Shared modal*/

	#sharedModal {
		width: 100%;
		position: fixed;
		top: auto;
		right: 0;
		left: 0;
		bottom: 0;
		height: 150px;
		box-shadow: 0 12px 75px 0 #e3e1e1,0 2px 4px 0  #f4f4f4,inset 0 0 0 1px  #f4f4f4;	
	}  

	#sharedModal svg, #sharedModal .copy{
		height: 50px;
		width: 50px;
		margin-right: 1rem;
	}

	#sharedModal .copy{
		margin-left: 1.5rem;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	#sharedModal .copy svg{
		height: 35px;
		width: 35px;
		margin-top: 5px;
		margin-left: 4px;
	}

	#sharedModal .social {
		float: left;
	}

	.consierge{
		background: none;
	}

	/*Search UI*/

	.search-container{
		background-color: #fff;
	}

	.search-container-add {
		padding-bottom: 0!important;
	}

	.center-container-small {
		width: 100%;
	}

	.search-center .searchbtn{
		float: right;
	}

	.search-center .searchedUser{
		width: 100%;
	}

	.search-card .info-f .username, .search-card .info-f .suggested .username {
		font-weight: 600;
  	}

	.search-card .info-f .tagline {
		display: block!important;
  	}

	/*search UI*/

	.search-results{
		text-align: left;
		top: 1.5rem;
	}

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 2rem;
		word-break: break-word;
		width: 80%;
	}

	/*Prefernces*/

	.after-app-wrap-center-div-gotop {
		width: 100%;
		top: 40px;
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
  }

	/*Artificial Intellegence Modal*/

	.modal-fullscreen h6{
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		color: white;
      font-size: calc(1.115rem * 1.00);
		color: #0088aa;
	}

	.modal-fullscreen, .modal-fullscreen-content,
	.modal-fullscreen .ai-concierge,
	.modal-fullscreen .ai-content, 
	.modal-fullscreen .ai-input{
		width: 100%;
	}

	.avatar-ai{
		width: 100px!important;
		height: 100px;
	}

	.modal-fullscreen .ai-concierge{
		width: 100%;
		position: unset;
	}

	.modal-fullscreen .modal-space{
		margin: 0;
		padding: 0!important;
	}

	.modal-fullscreen .user-response {
		/* padding: 1rem!important; */
	}

	.modal-fullscreen .ai-input {
		position: relative;
		left: 0;
		right: 0;
		height: 100px;
		width: 100%;
		margin: auto;
	}

	.modal-fullscreen .ai-input input {
		padding: 0.5rem;
		width: 100%;
  	}

	.modal-fullscreen .ai-concierge .image-parent-create-post{
		max-width: 100%;
		margin: auto;
	}

	.modal-fullscreen .image-parent-create-post{
		max-width: 100%;
		margin: auto;
	}

	.modal-fullscreen .ai-input img{
		height: 100%;
		margin-top: 0.5rem;
	}

	.modal-fullscreen .ai-row .requests{
		line-height: 1.8;
		text-align: left;
	}

	.modal-fullscreen .ai-content .radio-container label {
		font-size: 1rem!important;
  	}

	.modal-fullscreen .ai-consierge-response {
		width: 100%;
	} 

	.modal-fullscreen .alignflex{
		padding:0.5rem;
		line-height: 1!important;
	}

	.edit-profile .alignflex{
		padding: 0;
	}

	.modal-fullscreen .alignflex p{
		font-size: calc(0.875rem * var(--koeff))!important;
		line-height: 1;
	}

	.posFixed{
		position: fixed;
	}

	.modal-fullscreen .switch{
		width: 100%;
	}

	.modal-fullscreen input[type=radio] {
		transform: translateY(calc(0.2rem * var(--koeff)));
	}

	/* Concierge gallery */

	.consierge-desktop-gallery, .consierge-desktop-gallery .after-app-wrap-images-div {
		width: 100%;
	}

	.consierge .avatar-big, .consierge .avatar-big img{
		width: 70px;
		height: 70px;
	}

	/*New login*/

	.login-background .imagine{
		display: none;
	}

	.Imaginewith2{
		display: none;
	}
	
	/*End of ALL PHONES. LANDSCAPE*/
}

/* NEW ORIENTATION********************************Ipad Pro Portrait.******************************/

/* it is not piked up */
@media (min-width: 432px) and (max-width: 850px) and (orientation: portrait) {
	.signup-form {
		width: 45vw;
	}
}

/********************************540. My tablet Portrait only images.******************************/
/*HUGE SAMSUNG PHONE*/

@media (min-width: 416px) and (max-width: 540px){

	.login-background .login-form {
		margin: 3rem auto;
      width: 70vw;
  	}

	.image-text-div {
		height: 32.8vw!important;
	}

	.image-text-div img {
		width: 32.8vw!important;
		height: 32.8vw!important;
	}

	.login-background .imagine{
		font-size: 3rem;
		text-align: left;
		font-weight: 500;
		padding: 0 1rem;
  	}

	.login-background .script{
			font-family: 'Mea Culpa'; 
			/* src: url('https://ocafprod.s3.us-east-2.amazonaws.com/font/MeaCulpa-Light.ttf') format('truetype'); */
			src: url('css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
			text-align: left; 
			font-size: 2.5rem;
			font-weight: 300!important;
			display: block;
	}

	/*change from 2 after commits*/
	
	.login-background .Imaginewith2{
		width: 83%;
    	margin: auto;
	}

	.login-background .imagine {
		/* width: 60%; */
		margin: auto;
	}
}

/********************************TABLETS PORTRAIT******************/ 

/*Small tablets. SKY. New login form and AI */

@media (min-width: 432px) and (max-width: 599px) {
	
}

/***********Huge Samsung Phones. Sky tablet Portrait except images. IPhone SE Landscape. O tablet portrait*************/

@media (min-width: 432px) and (max-width: 719px) {
/* Prod 27 @media (min-width: 416px) and (max-width: 719px){ */

	/*General Fonts*/

	.post-username, .poststyle{
		font-size: calc(1rem * var(--koeff)); 
		-webkit-text-size-adjust: none!important;
		text-size-adjust: none!important;
	}
	
	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.form-control, .login-form-row-no-label input, .login-form-row-no-label input:focus,
	.login-form-row-no-label a, .btn-imitation,
	.login-form .btn-imitation, .signup-form .btn-imitation,
	.error-message span {
		font-size: calc(1rem * var(--koeff))!important;
	}
	
	.small, .font-small{
		font-size: calc(0.85rem * var(--koeff))!important;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 195px!important;
		transform: translate(37px,35px)!important;
	}

	.dropdown-menu a, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff)); 
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.btn-imitation{
		font-size: calc(1.125rem * var(--koeff))!important;
	}

	.btn-lg{
		font-size: calc(1.25rem * var(--koeff))!important; 
	}

	.btn-mea{
		font-size: calc(2.5rem * var(--koeff))!important;
	}

	.ifollow p{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Nav*/

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(2rem * 1.00); 
	}

	/*Profile*/

	/*Followers*/

	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff));
	}

	.modal-body p, .modal-body .btn-imitation{
		font-size: calc(1rem * var(--koeff))!important;
		margin-bottom: 0.5rem!important;
	}

	.counter-d{
		line-height: 1.5;
	}
	
	#counter{
		/* no koeff */
		font-size: 1rem !important;
	}

	.profile-block a, .profile-block p, .profile-block span {
		font-size: calc(1rem * var(--koeff))!important; 
		margin-bottom: 0!important;
	}

	/*New concierge*/

	.modal-fullscreen .form-control-radio p{
		font-size: calc(0.75rem * var(--koeff))!important; 
		padding: 0.5rem;
		line-height: 1.3;
	}

	/*End of General Fonts*/
	
	/*Containers and General*/

	.after-app-wrap, .after-app-wrap-center-div {
		width: 100%; 
	}

   .after-app-wrap-center-div{
    	top: 170px;
		padding: 0;
   }

   .after-app-wrap-center-div-gotop{
		top: 55px;
		padding: 1rem;
	}

	.after-app-wrap-center-div-comments{
		width: 100%;
	}

	.after-app-wrap-walk-newuser-container{
		width: 100%;
	}

	.after-app-wrap-walk-newuser{
		background-size: 100%;
		background: url(images/vectorstock_25083512_edit_newuser2.svg);
		background-repeat: no-repeat;
		position: relative;
		margin: auto;
		height: 420px;
		overflow: hidden;
	}

	.avatar-md{
		width: 35px!important;
    	height: 35px;
	}

	.btn-mobile-comments{
		display: none;
	}

	/*Login*/

	.btn-login {
			margin-left: 0;
	}

	.sign-up{
		margin-top: 3rem;
	}

	/*Signup*/

	.signup-form {
		width: 68vw;
		margin-top: 2.5rem !important;
	}

	.modal-company-individual {
		width: 100%!important;
		max-width: 510px!important;
  	}

	.after-app-wrap-center-basic {
		width: 100%;
	}

	/*Nav*/

	.nav .nav-item{
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 1.5rem;
	}
		
	.btn-mobile{
		display: none;;
	}

	.btn-desktop {
		display: block;
	}

	/*Posts*/

	.post-div{
		top: 0;
	}

	.post-div, .comment-div{
		width: 100%;
	}
  
	.post-avatar {
		width: 12vw;
	}
  
	.post-avatar .avatar{
		width: 40px!important;
		height: 40px!important;
		margin: 0.5rem!important;
	}
  
	.post-content {
		width: 86vw;
		padding: 0.5rem 1rem;
	} 
  
	.right-div-create-post{
		background: transparent;
		bottom: 1rem;
		right: 1rem;
		width: auto;
		top: unset;
		height: 80px;
	}
  
	.right-div-create-post button{
		 border-radius: 50%;
		 padding: 1.5rem;
	}
  
	.create-post{
		z-index:1000;
		font-size: calc(2.5rem * var(--koeff))!important;
	}
  
	.btn-mobile{
		display: none;;
	}

	.btn-desktop {
		display: block;
	}

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(1.7rem * 1.00);
		color: rgb(22, 99, 214)!important;
	}
  
	.modal-dialog{
		border-radius: 0!important;
		width: 100vw!important;
		max-width: 100vw!important;
		transform: translate(0%, 0%)!important;
	}

	.image-parent{
		height: unset!important;
	}

	.image-parent img {
		width: 100%;
		height: unset!important;
	}

	textarea[name=createpost], [name=createcomment]{
		min-height: 180px;
    	height: auto;
	}

	.createtextarea, .createcommentarea{
		width: 100%;
	}

	/*I follow*/
	
	.right-div, .right-div-profile{
		width: 100%;
		height: 122px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 50px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index: 10;
	}

	.right-div{
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
	}

	.scroll{
		width: 100%;
		height: 115px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff))!important;
		margin-bottom: 0!important;
	}

	.right-div .card, .right-div-profile .card, .scroll .card{   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.info-f .tagline{
		display: none!important;
	}

	.consierge .right-div-profile{
		display: none;
	}

	.poststyle{
		margin: 0.5rem 0;
	}
	
	.username{
		/* Koeff seems not needed */
		/* font-size: calc(1rem * var(--koeff))!important; */
		font-size: 1rem;
		text-overflow: ellipsis;
		font-weight: 300!important;
		white-space: nowrap;
	}
	
	.search-card .username{
		font-weight: 600!important;
	}

	.search-card p{
		font-weight: 400;
	}
	
	.suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: center;
	}

	.search-card p{
		font-weight: 400;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
		outline: 1px solid #ccc;
	}

	.suggested .username{
		overflow: hidden;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested{
		padding: 0;
	}

	.info-f{
		padding-left: 0;
	}
  
	 /*Icons*/

	.home-icons {
		font-size: calc(1.4rem * var(--koeff));
		color: rgb(22, 99, 214);
		padding-right: 3px;
	}
  
	.home-icons-medium{
		padding-right: 1px;
	}
  
	.icon-div .btn{
		margin-top: -0.5rem;
	}
  
	.icon-count{
		padding-left: 0;
	}
  
	/*Nav*/

	.nav{
		width: 100%;
	}

	.nav .medeata-desktop{
		display: none;
	} 
	  
	.nav .medeata-mobile{
		display: block;
		position: fixed;
		width: 3rem;
		height: 3.5rem;
		left: 0.5rem!important;
		float: left;
		top: 0;
	}

	.nav .nav-item{
		position: relative;
	}

	.btn-mobile{
		display: none;;
	}

	.btn-desktop {
		display: block;
	}

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(1.7rem * 1.00)!important;
		color: rgb(22, 99, 214)!important;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 208px!important;
    	transform: translate(53px,38px)!important;
	}

	.dropdown-menu, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff));
	}

	.icon-mobile svg{
		font-size: calc(47px * var(--koeff));
	}
  
	/*Profile*/

	.profile-block{
		height: auto;
	}

	.profile-info{
		padding: 2rem!important;
	}

	.profile-info .row {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.post-div-profile{
		width: 100%;
		height: auto;
		margin-bottom: 7rem;
	}

	#editProfile, #followUnfollowID {
		margin-top: 0.5rem;
	}

	.edit-post svg{
		font-size: calc(1.8rem * var(--koeff));
	}

	.edit-post, .delete-post{
		margin-top: -1px;
	}

	/*not picked up for some reason*/
	.consierge{
		background: none;
	}

	/*Get Images*/

	.profile-posts-and-channels {
		top: 0
	}
	
	.after-app-wrap-images-div{
		width: 100%;
	}

  	.profile-info{
		padding: 0!important;
	}

	.image-text-div {
		float: left;
	}

	.overlay{
		display: none;
	}

	.image-text-div{
		height: 31.8vw!important;
	}

	.image-text-div img {
		width: 31.8vw;
    	height: 31.8vw!important;
	}

	.library-div-profile {
		width: 100%;
		margin-bottom: 2rem;
	}

	.after-app-wrap-images-div{
		width: 100%;
		padding-top: 0;
	}

	.dialog{
		left: 0%;
		top: 20%;
		margin: auto;
		width: 100%;
		height: auto;
    	transform: translate(0%, -20%);
	}

	.dialog img{
		width: 100%;
	}

	/*Comments*/

	.after-app-wrap-center-div-comments{
		width: 100%;
		top: 48px;
		padding: 0.5rem;
		position: relative;
	}

	.comments-div{
		width: 100%;
	}

	.comments-topost {
		padding: 1rem;
	}

	.comments-topost button{
		max-width: 300px;
	}

	/*Create your post*/

	.avatar-mobile{
		display: none;
	}

  	.showlibrary{
		display: none;
	}

	.image-parent-create-post {
		padding-bottom: 1rem;
	}

	/*Comments*/

	.talkbubble {
		max-width: 85vw;
	}

	/*Signup tooltip*/

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	.contact-us{
		width: 100%;
	}

	/*Account*/

	.account-form {
		padding: 1rem;
	}

	.radio-container {
		padding: 1rem 0;
  	}

	.btn-security {
		padding: 0.5rem 0!important;
		padding-left: 0!important;
		padding-right: 0!important;
	}

	.btn-security button{
		margin-bottom: 1.2rem;
	}

	/*New user*/

	.after-app-wrap-walk-newuser-container {
		width: 100%;
	}

	.after-app-wrap-walk-newuser{
		background-size: 100%;
	}

	/*Search UI*/

	.search-container{
		top: 75px;
	}

	.search-card {
		width: auto;
		min-width: 45vw!important;
		float: left;
	}

	.search-card p{
		font-size: calc(1.125rem * var(--koeff));
		text-align: left;
	}

	.search-card ,.tagline{
		display: block;
		font-size: calc(1rem * var(--koeff));
	}

	/*Modals*/

	.modal-company-individual{
		height: 100%!important;
		padding: 0.5rem;
	}

	.about{
		margin-left: 0;
		margin: auto
	}

	/*AI*/

	.modal-fullscreen .avatar-ai-sm {
		margin-left: 2rem;
	}

	.modal-fullscreen .icon{
		font-size: 25px;
	}

	.modal-fullscreen textarea[name=createpost] {
		height: 10rem;
	}

	/* Concierge gallery */

	.consierge .profile-info {
		padding: 1rem!important;
		word-break: break-all;
		text-align: center;
	}

	/*New concierge*//*Clean*/

	.modal-fullscreen .ai-response, .modal-fullscreen .user-response{
		padding: 2.5rem 0 1rem;
		margin-left: unset;
		margin: auto;
	}

	.modal-fullscreen .choice {
		padding-top: 0;
		margin-bottom: 1rem;
  	}

	.modal-fullscreen .radio-container-parent {
		width: 100%!important; /*for huge phone*/
	}

	.modal-fullscreen .form-control-radio{
		height: 50px;
		display: flex;
		align-items: center; 
		justify-content: center;
		margin: 0; 
		width: 100%;
		margin-bottom: 1rem;
		border-radius: 30px;
		float: left;
	}

	.modal-fullscreen .form-control-radio .image {
		display: none;
  	}

	.modal-fullscreen .form-control-radio p {
		font-size: calc(0.95rem*var(--koeff))!important;
		text-align: center;
		padding: 0.5rem;
		color: black;
 	}
		
	.modal-fullscreen .form-control-radio {
		margin-bottom: 1rem;
	}

	.modal-fullscreen .fixedHeight{
		height: 40px;
		display: flex;
		align-items: center; /* Center vertically */
		justify-content: center; /* Center horizontally */
		margin: 0; 
		width: 100%;
	}

	.modal-fullscreen .radio-container{
		display: unset;
	}

	.modal-fullscreen .profile-info .row{
		justify-content: left;
	}

	.color1 {
		background: rgba(0, 188, 212, 0.2); /* Light blue (30% opacity) */
  	}
  
	.color2 {
			background: rgba(128, 0, 128, 0.2); /* Purple (30% opacity) */
	}
  
	.color3 {
			background: rgba(255, 69, 0, 0.2); /* Orangered (30% opacity) */
	}
  
	.color4 {
			background: rgba(0, 0, 255, 0.2); /* Blue (assuming var(--blue) is #0000FF) */
	}

	.body-fullscreen-3 .background-text,
	.body-fullscreen-3 .background-text-studio {
		display: none;
	}

  	.login-background h1 {
	 	display: none;
  	}

  	.script{
		font-family: 'Mea Culpa'; 
		/* src: url('https://ocafprod.s3.us-east-2.amazonaws.com/font/MeaCulpa-Regular.ttf') format('truetype'); */
		src: url('css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
		text-align: left; 
		font-weight: 400!important;
	}

	/*End of general TABLETS PORTRAIT*/
}

/********************************O tablet Portrait*****************************/

@media (min-width: 540px) and (max-width: 650px){

	.login-form {
		margin: 3rem auto;
      width: 55vw;
  	}

	.login-background .imagine{
		font-size: 3rem;
		text-align: left;
		font-weight: 500;
		width: 75vw;
		margin: auto;
  	}

	.delete-account input{
		width: 80%;
	}

	/*Signup tooltip*/

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	.after-app-wrap-center-div{
		width: 100%;	
		top: 165px;
		/* causing gap on tablet */
		/* padding: 0.5rem; */
		min-height: unset;
	}

	.after-app-wrap-center-div-gotop{
		width: 100%;
		top: 65px;
		padding: 0.5rem;
		min-height: unset;
	}

	.nav .nav-item{
		padding-right: 2rem!important;
	}

	.alignflex .btn{
		padding: 0.375rem 0.75rem!important;
	}

	.medeata-desktop{
		display: none;
	}

	.medeata-mobile{
		display: block;
	}

	/*Posts*/

	.image-parent, .image-parent img{
		height: unset!important;
	}

	/*Get Images*/

	.libarary-div-profile{
		padding: 1rem;
	}

	.image-text-div{
		height: 32.9vw!important;
	}

	.image-text-div img {
		width: 32.9vw!important;
		height: 32.9vw!important;
	}

	/*Comments*/
	.comments-div .postrow p {
		padding-left: 0.5rem;
	}

	/*Modals*/
	
	.modal-company-individual{
		height: 100%!important;
	}
}

/******************************** O phone. Landscape. 640*****************************/

@media (min-width: 639px) and (max-width: 640px){

	.post-username, .poststyle{
		font-size: calc(1rem * var(--koeff)); 
		-webkit-text-size-adjust: none!important;
		text-size-adjust: none!important;
	}

	input, .form-control, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p {
		font-size: calc(1.125rem * var(--koeff))!important;
	}

	.small, .font-small{
		font-size: calc(0.85rem * var(--koeff))!important;
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.btn-lg{
		font-size: calc(1.125rem * var(--koeff))!important; 
	}

	.dropdown-menu, .dropdown-menu.show a {
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Get Images*/

	.image-parent img {
		width: unset!important;
	}

	.image-text-div{
		height: 150px!important;
	}

	.image-text-div img {
		width: 150px!important;
		height: 150px!important;
	}

	.dialog {
		left: unset;
		margin: auto;
		width: 57%;
		left: 50%;
		top: 0;
		transform: translate(-50%, 0%)!important;
	}

	.dialog img{
		width: 100%;
	}

	/*Comments*/

	.avatar-md {
		width: 30px!important;
		height: 30px;
	}

	/*I follow*//*10.24*/

	.after-app-wrap-center-div {
		width: 100%;
		top: 170px;
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}
	
	.right-div, .right-div-profile {
		width: 100%;
		height: 110px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div {
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
		font-size: calc(1rem * var(--koeff));
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card {   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}
	
	.username{
		text-overflow: ellipsis;
		font-weight: 300!important;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: center;
	}

	.likes .suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: left;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested {
		padding: 0rem;
	}

	/*End of I follow*/

	.right-div, .right-div-profile {
		width: 100%;
		height: 120px;
	}
	
	/*Shared functionality*/

	#sharedModal {
		height: 105px;
	}

	#sharedModal .modal-dialog{
		width: 100%!important;
		max-width: 100%!important;
    	bottom: 0;
		height: 100%;
	}

	/*Search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: 47vw;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 71%;
	}
}

/********************************Galaxy Fold. Iphone SE. Landscape*****************************/

@media (min-width: 650px) and (max-width: 719px){
	.after-app-wrap-center-div {
		width: 100%;
		top: 163px;
		min-height: unset;
		height: auto;
		margin-bottom: 3rem;
  	}

	.after-app-wrap-center-div-gotop{
		width: 100%;
	}

	.btn-mobile-comments{
		display: none;
	}

	/*Nav*/

	.nav{
		padding: 0!important;
	}
	
	/*Posts*/

	.suggested a p {
		font-size: calc(1rem * var(--koeff));
	}

	/*I follow*/
	
	.right-div, .right-div-profile {
		width: 100%;
		height: 120px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div {
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card {   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}
	
	.username{
		text-overflow: ellipsis;
		font-weight: 300!important;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: center;
	}

	.likes .suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: left;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested {
		padding: 0rem;
	}
	/*Get Images*/

	.libarary-div-profile{
		padding: 1rem;
	}

	.image-text-div{
		height: 205px!important;
	}

	.image-text-div img {
		width: 205px!important;
		height: 205px!important;
	}

	.dialog{
		left: 0%;
		top: 35%;
		margin: auto;
		width: 85%;
		height: auto;
		transform: translate(0%, -12%);
	}

	.dialog img{
		width: 100%;
	}

	.overlay{
		display: none;
	}

	/*Shared functionality*/

	#sharedModal .modal-dialog{
		width: 100%!important;
		max-width: 100%!important;
    	bottom: 0;
		height: 100%;
	}

	/*Search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: 47vw;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 71%;
	}
}

/********************************667. Only Iphone SE landscape. Galaxy Tab S4******************************/

@media (min-width: 666px) and (max-width: 719px){
	.image-text-div{
		height: 33vw!important;
	}

	.image-text-div img {
		width: 33vw!important;
		height: 33vw!important;
	}

	/*Comments*/
	.comments-div .postrow p {
		padding-left: 0.5rem;
	}
}

/**************************Iphone 12 Mini. LANDSCAPE*********************************/
@media (min-width: 720px) and (max-width: 718px){
	.image-text-div {
		height: 32.9vw!important;
	}

	.image-text-div img {
		width: 32.9vw!important;
		height: 32.9vw!important;
	}
}

/* All Ipad and Tablet.General Portrait. */

@media (min-width: 720px) and (max-width: 1024px){
	.modal-fullscreen{
		position: fixed!important;
	}
}

/********************************ALL IPADS AND TABLETS PORTRAIT. 
Iphone 12 Pro. Galaxy Tab S4. O tablet landscape******************************/

@media (min-width: 720px) and (max-width: 969px){

	/*General*/

	/*Fonts*/

	body{
		font-size: calc(1rem * var(--koeff))!important;
		color: black;
		font-weight: 400;
	}

	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p {
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.login-form .btn-imitation, .signup-form .btn-imitation, 
	.login-form-row-no-label a {
		font-size: calc(1rem * var(--koeff))!important; 
		font-weight: 400;
	}
 
	.small, .font-small,
	.radio-container label {
		font-size: calc(1rem * var(--koeff))!important;
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.search-card .medium{
		font-size: calc(0.875rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1.125rem * var(--koeff))!important; 
	}

	.btn-lg{
		font-size: calc(1.25rem * var(--koeff))!important; 
	}

	.ifollow p{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Nav*/

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(2rem * 1.00); 
	}

	/*Profile*/
	.profile-info h4{
		font-size: 2rem;
	}

	.consierge .profile-info h4{
		font-size: calc(2rem * var(--koeff));
	}

	/*Followers*/
	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff));
	}

	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1rem * var(--koeff))!important;  
	}

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop{
		width: 100%;
		padding: 0;
	}

	.suggeted .col-9{
		padding-right: 0;
	}

	.suggeted .tagline{
		word-break: break-word;
	}

	.post-div{
		width: 100vw;
		margin: auto;
	}

	.image-parent img{
		height: unset!important;
	}

	.suggested {
		padding: 0.5rem;
	}

	.avatar-small-landscape, .avatar-small-portrait{
		width: 40px;
		height: 40px;
	}

	/*I follow*/

	.after-app-wrap-center-div {
		width: 100%;
		top: 166px;
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}
	
	.right-div, .right-div-profile {
		width: 100%;
		height: 115px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div {
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
		font-size: calc(1rem * var(--koeff));
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card {   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}

	.search-card .tagline{
		display: block;
	}
	
	.username{
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.suggested p, .suggested .username{
		font-weight: 300;
		font-size: calc(0.875rem* var(--koeff));
		text-align: center;
	}

	.search-container .suggested p{
		text-align: left;
	}

	.search-card .username{
		font-weight: 600;
	}

	.search-card p{
		font-weight: 400;
	}

	.likes .suggested p{
		font-weight: 300;
		font-size: calc(0.875rem * var(--koeff));
		text-align: left;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested {
		padding: 0rem;
	}

	/*Profile*/

	.profile-block, .profile-block-gotop {
		height: auto;
	}

	.profile-block .profile-info .row, .profile-block-gotop .profile-info .row{
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.post-div-profile{
		width: 100vw;
		margin: auto;
	}

	.img-parent img{
		height: auto!important;
	}

	.profile-block span, .profile-block p, .profile-block a{
		margin-bottom: 0!important;
	}

	.profile-block .btn {
		padding: 0.175rem 0.5rem;
		margin: 0.5rem auto;
	}

	.profile-block .change-pic {
		margin-bottom: 0!important;
	}
		
	/*Comments*/

	.after-app-wrap-center-div-comments{
		width: 100%;
		padding: 0.5rem;
	}

	.comments-div{
		width: 100%;
		padding: 0;
	}

	.talkbubble{
		max-width: 87.3vw;
	}

	.comments-div .postrow p {
		padding-left: 0.5rem;
	}

	/*Account*/

	.account-form {
		padding: 1rem;
		margin: 0 5rem;
		position: relative;
		/* Good for phones lanscapes*/
		/* top: 2rem; */
	}

	/*Get Images*/

	.library-div-profile{
		width: 100vw;
	}
	
	.image-text-div {
		height: 32.1vw;
	}

	.image-text-div img {
		width: 32.1vw!important;
		height: 32.1vw;
	}

	.overlay{
		display: none;
	}

	/*New User*/

	.after-app-wrap-walk-newuser-container{
		width: 100%;
	}

	.after-app-wrap-walk-newuser{
		background-size: 100%;
	}

	/*Report*/

	.dropdown-menu-report, .dropdown-menu-report.show {
		width: 70px!important;
		min-width: 70px!important;
		transform: translate(104px,12px)!important;
		border: 1px solid rgba(0, 0, 0, 0.04);
    	box-shadow: 0 6px 12px 2px rgb(0 0 0 / 8%);
		background: #fff!important;
		padding: 0;
		padding: 0;
		border-radius: 0;
	}

	.dropdown-menu-report p{
		float: left;
		width: 100%;
		padding: 0.4rem;
		color: #1663D6;
		font-size: calc(14px * var(--koeff));
	}

	.dropdown-report .btn{
		padding: 0.375rem 0!important;
	}

	/*Sharing functionality*/

	.sharedPost{
		top: 0!important;
	}

	.buttonsDiv{
		/* padding: 0.2rem; */
		font-size: calc(1.175rem * var(--koeff))!important;
	}

	.sharedComments{
		padding: 0.5rem;
		margin-top: 0;
	}

	.mega-menu-share{
		width: 174px;
		padding: 5px;
		top: 37px;
	}
	
	.toast {
		flex-basis: 100vw!important;
		max-width: 100%!important;
		left: 0;
		z-index: 2000;
		bottom: 0;
	}

	/*Shared modal*/

	#sharedModal {
		width: 100%!important;
		position: fixed;
		top: auto;
		right: 0;
		left: 0;
		bottom: 0;
		height: 130px;
		box-shadow: 0 12px 75px 0 #e3e1e1,0 2px 4px 0  #f4f4f4,inset 0 0 0 1px  #f4f4f4;	
	}  

	#sharedModal .modal-dialog{
		width: 100%!important;
		max-width: 100%!important;
    	bottom: 0;
		height: 100%;
	}

	#sharedModal svg, #sharedModal .copy{
		height: 50px;
		width: 50px;
		margin-right: 1rem;
	}

	#sharedModal .copy{
		margin-left: 1.5rem;
	}

	#sharedModal .copy svg{
		height: 35px;
		width: 35px;
		margin-left: 7px;
	}

	#sharedModal .social {
		float: left;
	}

	.consierge {
		background: none!important;
		height: 100%!important;
		top: 70px!important;
	}

	.consierge .profile-block {
		padding-top: 4rem;
		padding: 1rem;
  	}

	.consierge .profile-info {
		padding: 2rem!important;
		text-align: center;
  	}

	/*Search UI with min-width*/

	.center-container-small, .search-input-container{
		width: 100%;
	}

	.search-container-add{
		padding: 0!important;
	}

	.search-center .btn {
		margin: 0;
	}

	.search-center {
		width: 100%;
	}

	.search-center .searchbtn{
		float: right;
	}

	.search-card{
		width: auto;
		min-width: 26vw!important;
		float: left;
	}
	
	.search-card .avatar-small-landscape, .search-card .avatar-small-portrait{
		margin: 0;
		position: absolute;
		top: 46%;
		left: 10%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.search-card .info-f{
		word-break: break-word;
		margin: 0;
		position: absolute;
		top: 46%;
		left: 57%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	.search-card p{
		text-align: left;
		font-size: calc(1.125rem * var(--koeff));
	}

	.search-card .username{
		font-weight: 600;
	}

	.search-card .medium {
		font-size: calc(0.875rem * var(--koeff))!important;
	}

	.search-card .info-f {
		padding-left: 1rem;
	}

	.new{
		background: url(images/search-sm.png) no-repeat scroll 7px 9px;
		padding-left: 2.5rem!important;
		background-color: white;
    	border: 1px solid grey;
	}

	/*Modals*/

	.modal-company-individual {
		height: 100%!important;
	}

	.modal-dialog{
		width: 100%!important;
		max-width: 100%!important;
	}

	/*Artificial Intellegence*/
		
	.modal-fullscreen, .modal-fullscreen-content,
	.modal-fullscreen .ai-concierge,
	.modal-fullscreen .ai-content, 
	.modal-fullscreen .ai-input{
		width: 100%;
	}

	.body-fullscreen-3 {
		width: 100%;
		height: 100vh;
		margin: 0;
		padding: 0!important;
  	}

	.modal-fullscreen {
		margin: 0;
		padding: 1rem 1rem 0!important;
	}

	.modal-fullscreen-content{
		margin: 0;
		padding: 0;
	}

	.modal-fullscreen-content button{
		padding: 0.5rem 1rem!important;
	}

	.modal-fullscreen .profile-info .ai-row{
		text-align: left;
		justify-content: left;
	}

	/* #threeDots {
		height: 20px!important;
		justify-content: left;
	} */

	.modal-fullscreen .switch {
		width: 50%;
  	}

	.modal-fullscreen .ai-content {
		overflow-y: unset; 
	}

	.modal-fullscreen #label, .modal-fullscreen #labelAI {
		width: 7vw;
  	}

	.avatar-ai-landscape, .avatar-ai-portrait {
		width: 65px;
		height: 65px;
	}

	.avatar-ai-sm {
		width: 40px!important;
		height: 40px;
		float: left;
		border-radius: 50% 50% 50% 50%;
		overflow: hidden;
	}

	.modal-fullscreen .alignflex p {
		line-height: 1;
	}

	.radio-form{
		width:65%;
	}

	.modal-fullscreen .user-response{
		padding: 0.5rem 0;
	}

	.choice {
		margin-bottom: 0.5rem;
	}

	/* Create post */

	.modal-fullscreen-content textarea[name=createpost] {
		min-height: 115px!important;
		/* seems no need for height */
	}

	.counter-d{
		line-height: 1.5;
		font-size: 1rem;
	}

	.login-form .btn-mea{
		font-size: calc(2rem * var(--koeff))!important;
	}

	.login-form .form-control, .signup-form .form-control{
		background-color: #fff!important;
		border: 1px solid grey !important;
	}

	.forgot-password .login-form, .enterCode .login-form, .resetPassword .login-form {
		width: 50%;
		/* padding: 6rem 0; */
	}

	/*New Concierge*/

	.modal-fullscreen .radio-container-parent{
		width: 90%;
		margin: 1rem auto;
	}

	.background-text {
      transform: rotate(0deg);
		bottom: 5%; /* Adjust for tablet bottom-right corner */
		right: 15%;
	}

	.background-text, .background-text-studio{
		font-size: 9rem;
	}

	.background-text-studio {
		position: absolute;
		transform: rotate(-90deg); 
		transform-origin: bottom left;
		bottom: 2.5%;
		left: calc(100% - 4%); 
	}

	.modal-fullscree .user-response{
		padding: 0;
	}

	.modal-fullscreen .radio-container-parent  .fixedHeight{
		/* height: 40px; */
		padding: 0.5rem;
		vertical-align: middle;
		display: grid;
		place-items: center;
	}

	.modal-fullscreen .radio-container-parent p{
		font-size: calc(0.75rem* var(--koeff))!important;
      line-height: 1;
		text-align: center;
		padding: unset;
	}

	.modal-fullscreen .radio-container-parent .col-md-3, .modal-fullscreen .radio-container-parent .col-md-12{
		/* padding: 10px!important; */
	}

	.modal-fullscreen .radio-container-parent .col-md-12{
		padding-right: 0!important;
	}
	/*column should be 10px */

	/*New AI*/
	/*O tablet landscape. Cannot be put in app advanced because of click event contradiction on UI*/

	.login-background .login-form{
		width: 45vw;
		float: none;
		margin: 3rem auto 0;
	}

	.login-background .imagine{
		display: none;
  	}

	.script{
		font-family: 'Mea Culpa'; 
		/* src: url('https://ocafprod.s3.us-east-2.amazonaws.com/font/MeaCulpa-Regular.ttf') format('truetype'); */
		src: url('css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
		text-align: left; 
		font-weight: 300!important;
	}

	.info-f {
		padding-left: unset;
	}

	/*End of ALL IPADS PORTRAIT*/
}

/* Small ipads, IPAD MINI PORTRAIT*/

@media (min-width: 720px) and (max-width: 768px){

}


/*The below is cover Large Samsung Phone or similar on Chrome created teretically on april 8 2024*/

@media (min-width: 1020px) and (max-width: 1022px){

	.avatar-ai-landscape, .avatar-ai-portrait {
		width: 65px;
		height: 65px;
  }

	.modal-fullscreen .switch {
		width: 30%;
	}

	.body-fullscreen-3{
		background: unset!important;
	}
}


/********************************My tablet. Landscape. Phone Galaxy s8 Landscape. F phone******************************/
@media (min-width: 720px) and (max-width: 751px){

	/*Login. Sign up*/

	.login-form {
		width: 49vw;
	}
	
	.password span {
		left: 9.5rem;
	}

	.btn-login, .btn-login-signup {
		margin-left: 0;
		font-size: calc(16px * var(--koeff))!important;
	}

	.login-form-row-no-label {
		height: 64px;
  	}

	.after-app-wrap-center-basic {
		width: 100%;
	}

	/*Sign up*/

	.signup-form .password span {
		left: 8rem;
  	}

	.modal-company-individual {
		width: 100%!important;
		max-width: 675px!important;
	}

	/*Posts*/

	.post-div-profile{
		width: 100vw;
		margin: auto;
	}

	.suggested p {
		font-size: calc(12px * var(--koeff));
	}

	.avatar-mobile{
		display: block;
	}

	.info-f {
		width: 75%;
	}

	.scroll{
		right: 0.5rem;
		width: 245px;
	}

	.avatar {
		width: 39px!important;
		height: 39px
	}

	.image-parent img {
		width: 100%;
		height: unset!important;
	}
	
	/*I follow*/
	
	.right-div, .right-div-profile{
		width: 100%;
		height: 115px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 50px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
	}

	.right-div{
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
	}

	.scroll{
		width: 100%;
		height: 115px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff))!important;
		margin-bottom: 0!important;
	}

	.right-div .card, .right-div-profile .card, .scroll .card{   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
		margin: 0;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}

	p{
		overflow: hidden;
	}
	
	.username{
		font-size: calc(0.9rem * var(--koeff))!important;
		text-overflow: ellipsis;
		font-weight: 300!important;
		white-space: nowrap;
	}
	
	.suggested p{
		font-weight: 300;
		font-size: calc(0.875rem * var(--koeff));
		text-align: center;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
		outline: 1px solid #ccc;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested{
		padding: 0.5rem;
	}
  
	/*Profile*/

	.profile-block{
		height: auto;
	}

	.profile-info, .profile-info .row{
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.profile-block .btn{
		margin-bottom: 0.5rem;
	}

	.post-div-profile img{
		width: 100%;
	}

	/*Comments8*/

	.after-app-wrap-center-div {
		width: 100%;
	}

	.talkbubble {
		max-width: 91%;
	}

	.btn-mobile-comments{
		display: none;
	}

	/*Account*/

	.profile-info {
		text-align: left;
		word-break: break-all;
		padding: 0!important;
	}

	.delete-account input{
		width: 70%!important;
    	margin: auto;
	}

	/*GetImages*/

	.after-app-wrap-images-div{
		width: 100%;
	}

	.library-div-profile{
		width: 100%;
		padding: 1rem;
	}

	.image-text-div{
		height: auto;
	}

	.image-text-div{
		height: 227px;
	}

	.image-text-div img {
		width: 227px!important;
		height: 227px;
  	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*Search*/

	.search-container{
		width: 100%;
	}
	
	.search-results{
		text-align: center;
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: row;	
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		position: relative;
	}
	
	.search-card{
		width: 25vw!important;
		overflow-wrap: break-word;
		height: 85px;
		position: relative;
	}
	
	.search-card .medium{
		font-size: calc(0.75rem * var(--koeff))!important;
	}
	
	.search-card .avatar-small-landscape, .search-card .avatar-small-portrait{
		transform: translate(0%,8%);
	}
	
	.search-card .info-f{
		word-break: break-word;
		margin: 0;
		position: absolute;
		top: 50%;
		left: 57%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	/*Modals*/

	.modal-company-individual{
		height: 100%!important;
	}
}


/********************************Only 740. Samsung Galaxy S8. Landscape******************************/
@media (min-width: 740px) and (max-width: 751px){

	/*Get Images*/

	.library-div-profile{
		width: 100%;
		padding: 1rem;
	}

	.image-text-div {
		height: 234px;
	}

	.image-text-div img {
		width: 234px!important;
		height: 234px;
  	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	} 
	
	.after-app-wrap-center-div-comments{
		width: 100%;
		top: 48px;
		padding: 0.5rem;
		position: relative;
	}

	.comments-topost{
		text-align: left;
	}

	/*search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}
}

/********************************Only Ipad portrait******************************/

@media (min-width: 895px) and (max-width: 913px) {
	.right-div .scroll{
    	right: 1vw;
	}
}

/********************************Ipad portrait. Which ipad? MAYVE My tablet. Samsung A32 Phone Landscape******************************/
@media (min-width: 845px) and (max-width: 913px) {

	html, body{
		overflow-y: unset;
	}

	.after-app-wrap-center-div,
	.after-app-wrap-center-login-div,
	.after-app-wrap-center-login-div-walk, .after-app-wrap-center-login-div-walk-sign {
		width: 100%;
		height: auto;
	} 

	/*Nav*/

	.nav .nav-user {
		padding: 0.5rem 2rem 0.5rem 0;
	}
		
	.right-div-create-post{
		width: 36%;
		right: -12px;
	}
 
	.create-post{
		z-index:1000;
	}
 
	.btn-mobile{
		display: none;
	}
 
	.btn-desktop{
		 display: block;
	 }

	 .btn-mobile-comments{
		display: none;
	 }

	.create-post .btn-desktop{
		position: relative;
	}
 
	.modal-dialog{
	  padding: 1rem 0.5rem;
	  border-radius: 0;
	}

	.right-div-profile {
		width: 255px;
	}

	.avatar{
		width: 40px!important;
		height: 40px;
	}
 
	/*Icons*/

	.home-icons {
	  font-size: calc(1.4rem * var(--koeff));
	  color: rgb(22, 99, 214);
	  padding-right: 3px;
	}
 
	.home-icons-medium{
	  padding-right: 1px;
	}
 
	.icon-div .btn{
	  margin-top: -0.5rem;
	}
 
	.icon-count{
	  padding-left: 0;
	  margin-top: 0px;
	}
 
	/*Nav*/
	.nav{
	  width: 100%;
	}
 
	/*Login*/

	.login-form{
		padding: 1rem;
		margin: 2rem auto;
		width: 51%;
	}

	/*Sign up*/

	.password span{
		left: 9rem;
	}

  /*Profile*/

	.profile-settings {
		width: 100%;
		margin: auto;
		padding: 1rem;
		top: 72px;
		position: relative;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 187px!important;
		-webkit-transform: translate(51px,41px)!important;
		transform: translate(26px,59px)!important;
	}

	.profile-info {
		padding: 2rem;
	}

	.profile-info .row {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	/*Create Post*/

	textarea[name=createpost], [name=createcomment] {
		min-height: 26vh;
	}

	/*Get Images*/

	.after-app-wrap-images-div{
		width: 100%;
	}

	.labrary-image-profile{
		width: 100%;
		padding: 0;
	}

	.image-text-div {
		height: 255px!;	
	}

	.image-text-div img {
		width: 255px!important;
		height: 255px!;
	}

	.overlay{
		display: none;
	}

	/*Create Post*/

	.image-parent-create-post {
		padding-bottom: 1rem;
	}

	/*Account*/

	.edit-password .password span {
		position: relative;
    	margin-left: 1rem!important;
  	}

	.edit-post svg{
		font-size: calc(1.8 * var(--koeff))rem;
	}

	/*search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}

	/*Modals*/
	
	.modal-company-individual{
		height: 100%!important;
	}
	
}

/********************************Ipad Mini Portrait / My tablet Landscape.******************************/

@media (min-width: 766px) and (max-width: 819px){

	/*General*/
	
	/*Fonts*/

	body{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.small, .font-small{
		font-size: calc(0.975rem * var(--koeff))!important;
	}

	.medium{
		font-size: calc(1rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1.125rem * var(--koeff))!important; 
	}

	.btn-lg{
		font-size: calc(1.25rem * var(--koeff))!important; 
	}

	.ifollow p{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Nav*/

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(2rem * 1.00); 
	}

	/*Profile*/
	.profile-info h4{
		font-size: calc(2rem * var(--koeff));
	}

	.consierge .profile-info h4{
		font-size: calc(2rem * var(--koeff));
	}

	/*Followers*/

	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff));
	}

	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 182px;
		transform: translate(24px,38px);
  	}

	.after-app-wrap-center-login-div-walk, .after-app-wrap-center-login-div-walk-sign,
 	.after-app-wrap-center-basic{
		width: 100%;
	}
	
	.after-app-wrap-center-div{
		padding: 0.5rem;
	}

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop {
		width: 100%;
	}

	.after-app-wrap-center-div-comments{
		width: 100%!important;
	}

	/*Login, sign-up*/

	.login-form, .signup-form{
		width: 45vw;
	}

	.signup-form{
		padding-top: 1rem;
	}

	.eye {
		top: -2.1rem;
	}

	/*Posts*/

	.after-app-wrap-center-div{
		width: 100vw;
	}
	
	.post-div .image-parent img{
		height: unset!important;
	}

	.suggested p{
		font-size: calc(14px * var(--koeff));
	}

	/*GetImages*/

	.after-app-wrap-images-div{
		width: 100vw;
	}
	
	.library-div-profile{
		width: 100vw;
		padding: 1rem;
	}
	
	.image-text-div {
		height: 31.5vw;
	}

	.image-text-div img {
		width: 31.5vw!important;
		height: 31.5vw;
	}

	.overlay{
		display: none;
	}
	
	.overlay{
		display: none;
	}

	/*I follow*/

	.after-app-wrap-center-div {
		width: 100%;
		top: 170px;
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}
	
	.right-div, .right-div-profile {
		width: 100%;
		height: 115px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div {
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card {   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}

	.search-card .tagline{
		display: block;
	}
	
	.username{
		text-overflow: ellipsis;
		font-weight: 300!important;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	.search-card .username{
		font-weight: 600!important;
	}

	.suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: left;
	}

	.search-card p{
		font-weight: 400;
	}

	.likes .suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: left;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested {
		padding: 0rem;
	}

	/*Profile*/

	#followUnfollowID, #editProfile{
		margin-top: 1rem;
	}

	.profile-block{
		height: auto;
		padding: 1rem;
	}

	.profile-info .row {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
  	}

	/*Comments*/

	.btn-mobile-comments{
		display: none;
	}

	/*Signup tooltip*/

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	.contact-us{
		width: 100%;
	}

	/*Report*/
	.dropdown-menu-report, .dropdown-menu-report.show {
		width: 70px!important;
		min-width: 70px!important;
		transform: translate(104px,12px)!important;
		border: 1px solid rgba(0, 0, 0, 0.04);
    	box-shadow: 0 6px 12px 2px rgb(0 0 0 / 8%);
		background: #fff!important;
		padding: 0;
		padding: 0;
		border-radius: 0;
	}

	.dropdown-menu-report p{
		float: left;
		width: 100%;
		padding: 0.4rem;
		color: #1663D6 !important;
		font-size: calc(14px * var(--koeff));
	}

	.dropdown-report .btn{
		padding: 0.375rem 0!important;
	}

	/*search UI*/

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
		line-height: 1.3;
	}
	
	.search-card .info-f{
		padding-left: 1rem;
		word-break: break-word;
	}

	.search-card .username {
		font-size: calc(1.125rem * var(--koeff))!important;
    	font-weight: 600;
	}

	/*Modals*/

	.modal-company-individual{
		height: 100%!important;
	}

	.script{
		font-size: 2.7rem;
	}

	/*End of IPAD MINI PORTRAIT*/
}

/********************************Ipad Air Portrait. My Ph A32 landscape. latest******************************/
@media (min-width: 820px) and (max-width: 842px){

	/*Fonts*/

	body{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1rem * var(--koeff))!important; 
	}

	.small, .font-small{
		font-size: calc(0.875rem * var(--koeff))!important;
	}

	.medium{
		font-size: calc(0.975rem * var(--koeff)); 
	}

	.btn{
		font-size: calc(1.125rem * var(--koeff))!important; 
	}

	.btn-lg{
		font-size: calc(1.375rem * var(--koeff))!important;
	}

	.ifollow p{
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Nav*/

	.nav .nav-icon, .nav-links .nav-icon {
		font-size: calc(2rem * 1.00); 
	}

	/*Login*/

	.btn-mea {
		font-size: calc(2rem * var(--koeff))!important;
	}

	/*Profile*/

	.profile-info h4{
		font-size: calc(2rem * var(--koeff));
	}

	/*Followers*/

	.username{
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		font-size: calc(1rem * var(--koeff));
	}

	input, textarea, textarea[name=createpost], [name=createcomment], .dropdown-menu-report p{
		font-size: calc(1rem * var(--koeff))!important;  
	}

	.after-app-wrap-center-login-div-walk, .after-app-wrap-center-login-div-walk-sign,
	.after-app-wrap-center-div, .after-app-wrap-center-basic{
		width: 100%;
	}

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop {
		width: 100%;
	}

	.after-app-wrap-center-div{
		top: 160px; 
	}

	/*Signup*/

	.signup-form{
		width: 45vw;
		padding-top: 1rem;
	}

	/*Crearte post*/

	.modal-dialog{
		min-height: 40vh;
	}

	textarea[name=createpost], [name=createcomment]{
		min-height: 22vh!important;
	}

	.createtextarea{
		min-height: 25vh;
	}

	/*Posts*/

	.post-div{
		width: 100vw;
		margin: auto;
	}

	.post-div .image-parent img{
		height: unset!important;
	}

	/*Profile*/

	.post-div-profile{
		width: 100vw;
		margin: auto;
	}

	.right-div-profile{
		width: 100vw;
		margin: auto;
	}

	.profile-info .row {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
  	}

	.profile-block{
		height: auto;
		padding: 1rem;
	}

	#followUnfollowID, #editProfile{
		margin-top: 1rem;
	}

	.consierge {
		background: url(images/consierge-greet2.png) 0px 0px;
		background-repeat: no-repeat;
		background-position: center;
		height: 100vh;
	}

	/*GetImages*/

	.after-app-wrap-images-div{
		width: 100%;
	}
	
	.library-div-profile{
		width: 100vw;
	}

	.image-text-div {
		height: 33vw;
	}

	.image-text-div img {
		width: 33vw!important;
		height: 33vw;
	}

	/*Comments*/

	.comments-div{
		width: 100%;
		padding: 1rem;
	}

	.btn-mobile-comments{
		display: none;
	}

	.after-app-wrap-center-basic {
		width: 100%;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*Report*/

	.dropdown-menu-report, .dropdown-menu-report.show {
		width: 70px!important;
		min-width: 70px!important;
		transform: translate(104px,12px)!important;
		border: 1px solid rgba(0, 0, 0, 0.04);
    	box-shadow: 0 6px 12px 2px rgb(0 0 0 / 8%);
		background: #fff!important;
		padding: 0;
		padding: 0;
		border-radius: 0;
	}

	.dropdown-menu-report p{
		float: left;
		width: 100%;
		padding: 0.4rem;
		color: #1663D6 !important;
		font-size: calc(14px * var(--koeff));
	}

	.dropdown-report .btn{
		padding: 0.375rem 0!important;
	}

	.modal-dialog{
		max-width: 90vw!important;;
		max-height: 100vh!important;;
	}

	.about{
		margin-left: 0;
		font-size: calc(1rem * var(--koeff))!important; 
	}

	/*Sharing functionality*/

	#sharedModal .copy{
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	#sharedModal .copy svg{
		height: 35px;
		width: 40px;
		margin-top: 5px;
		margin-left: 4px;
	}

	/*search UI*/

	.search-container{
		width: 100%;
	}

	.search-results{
		text-align: center;
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: row;	
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		position: relative;
	}

	.search-card{
		width: 30vw!important;
		overflow-wrap: break-word;
		height: 85px;
		position: relative;
	}

	.search-card .medium{
		font-size: calc(0.75rem * var(--koeff))!important;
	}

	.search-card .avatar-small-landscape, .search-card .avatar-small-portrait{
		margin: 0;
		position: absolute;
		top: 46%;
		left: 12%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.search-card .info-f{
		word-break: break-word;
		margin: 0;
		position: absolute;
		top: 46%;
		left: 57%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	.search-card p{
		text-align: left;
		font-size: calc(1rem * var(--koeff));
	}

	.search-card .medium {
		font-size: calc(1rem * var(--koeff))!important;
	}

	.search-card .info-f {
		padding-left: 1rem;
	}

	.new{
		background: url(images/search-sm.png) no-repeat scroll 7px 9px;
		padding-left: 2.5rem!important;
		background-color: white;
    	border: 1px solid grey;
	}

	.search-card .info-f{
		word-break: break-word;
		margin: 0;
		position: absolute;
		top: 50%;
		left: 57%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	/*Modals*/
	
	.modal-company-individual{
		height: 100%!important;
	}

	.modal-dialog{
		margin: 0rem auto!important;
	}

	.alignflex .action-name {
		margin: 0.5rem!important;
		float: left;
	}

	/*AI*/
	.modal-fullscreen{
		max-width: 100vw!important;
	}
	
	.modal-fullscreen .createtextarea {
		min-height: unset;
  	}

	/*End of my p landscape. a32*/
}

/********************************Iphone 12 Landscape / Surface Pro 7 ******************************/

@media (min-width: 843px) and (max-width: 895px) {
	.after-app-wrap-center-login-div-walk, .after-app-wrap-center-login-div-walk-sign,
	.after-app-wrap-center-div, .after-app-wrap-center-basic {
		width: 100%;
	}

	.after-app-wrap-center-div{
		padding: 0.5rem;
	}
	
	.dropdown-menu, .dropdown-menu.show {
		width: 187px!important;
		/* good for my tablet nadscape */
		transform: translate(40px,40px)!important;
	}

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop {
		width: 100%;
	}

	/*Login*/

	.login-form {
		width: 43vw;
	}

	/*Sign up*/

	.signup-form {
		width: 43vw;
		margin: 2rem auto;
	}

	/*Nav*/

	.btn-mobile-comments{
		display: none;
	}

	/*Posts*/

	.post-div{
		width: 100vw;
		margin: auto;
	}

	/*I follow*//*10.24*/

	.after-app-wrap-center-div {
		width: 100%;
		top: 185px;
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}
	
	.right-div, .right-div-profile {
		width: 100%;
		height: 130px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div {
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card {   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}
	
	.username{
		text-overflow: ellipsis;
		font-weight: 300!important;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: center;
	}

	.likes .suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: left;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested {
		padding: 0rem;
	}

	/*Profile*/

	.right-div-profile {
		margin-left: 0;
	}

	.profile-block{
		height: auto;
		padding: 1rem;
	}

	.post-div-profile{
		width: 100vw;
		margin: auto;
	}

	.profile-info .row {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
  	}

	/*GetImages*/

	.after-app-wrap-images-div{
		width: 100%;
	}

	.library-div-profile{
		width: 100%;
		padding: 1rem;
	}

	.image-text-div {
		height: 268px;
	}

	.image-text-div img {
		width: 268px!important;
		height: 268px;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 400px;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	.consierge{
		height: 950px!important;
		background: url(images/consierge-greet2.png) -304px 202px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	/*search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}

	/*New concierge*/

	.modal-fullscreen .radio-container-parent {
		width: 70%;
	}

	.modal-fullscreen .radio-container-parent .col-md-12 {
		padding: 5px !important;
  	}

}

/********************************Pixel 5. Landscape ******************************/
@media (min-width: 850px) and (max-width: 895px) {
	/*Get Images*/

	.image-text-div{
		height: 271px;
  	}

  	.image-text-div img {
		width: 271px!important;
		height: 271px;
	}
}

/********************************Iphone XR Landscape ******************************/
@media (min-width: 896px) and (max-width: 911px) {

	/*Profile*/

	.profile-block{
		height: auto;
	}

	.right-div-profile {
		width: 285px;
  	}

	.library-div-profile {
		width: 100%;
		padding: 1rem;
  	}

	.image-text-div {
		height: 286px; 
	}
	.image-text-div img {
		width: 286px!important;
		height: 286px;
  }

  .signup-form {
		width: 43vw;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}
}

/********************************Samsung Galaxy A32/A51/A71/S20 Ultra Phone Landscape******************************/

@media (min-width: 912px) and (max-width: 950px) {
	/*Containers*/

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop {
		width: 100%;
	}

	/*Login*/

	.after-app-wrap-center-login-div-walk, .after-app-wrap-center-login-div-walk-sign{
		width: 100%
	}

	.after-app-wrap-center-div-comments{
		width: 100%;
		padding: 0.5rem;
	}

	.login-form {
		width: 44vw;
		margin: 5rem auto;
	}

	/*Sign up*/

	.signintooltip{
		width: 40%;
	}

	.signup-background{
		margin-top: -1rem;
	}

	.signup-form {
		width: 43vw;
		margin: 5rem auto;
	}

	/*Nav*/

	.nav .nav-icon {
		padding: 0;
	}

	.nav .nav-item{
		position: relative;
		padding-right: 1.5rem;
	}
	
	.nav .nav-user{
		padding: 0.5rem 1.5rem 0.5rem 0;
	}
	
	.nav .nav-item .btn{
		padding: 0;
		position: relative;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 180px!important;
	}

	.btn-mobile-comments{
		display: none;
	}

	/*Posts*/

	.post-div, .post-div-profile{
		width: 63vw;
		margin: auto;
	}

	.right-div, .right-div-create-post {
		right: 0.5rem;
	}

	.scroll{
		right: 1vw;
	}

	.avatar{
		width: 40px!important;
		height: 40px;
	}

	.img-parent img{
		height: auto;
	}

	/*Profile*/

	.profile-info {
		padding: 2rem;
  	}

	.profile-info .row {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
  	}

	.profile-block{
		height: auto;
	}

	.profile-block .btn{
		margin: 0.5rem;
	}

	.right-div-profile {
		width: 35vw;
	}

  	/*Get Images*/	

	.library-div-profile{
		width: 100%;
		padding: 1rem;
	}

	.image-text-div {
		height: 32vw;
	}

	.image-text-div img {
		width: 32vw!important;
		height: 32vw;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*I follow*//*10.24*/

	.after-app-wrap-center-div {
		width: 100%;
		top: 195px;
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}
	
	.right-div, .right-div-profile {
		width: 100%;
		height: 130px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div {
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
	}

	.ifollow{
		margin-bottom: 0.5rem;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.right-div .card, .right-div-profile .card, .scroll .card {   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.tagline{
		display: none;
	}
	
	.username{
		text-overflow: ellipsis;
		font-weight: 300!important;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: center;
	}

	.likes .suggested p{
		font-weight: 300;
		font-size: calc(14px * var(--koeff));
		text-align: left;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested {
		padding: 0rem;
	}

	/*search UI*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		text-align: left;
	}

	.search-card .suggested p{
		text-align: left;
	}

	.modal-company-individual{
		height: 100%!important;
		transform: none;
		margin: 0!important;
	}
}

/********************************Samsung Tablet Landscape. O tablet *Most used?) landscape.******************************/

@media (min-width: 951px) and (max-width:969px){

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop {
		width: 100%;
	}

	.after-app-wrap-center-login-div, .after-app-wrap-center-login-div-walk,
	.after-app-wrap-center-login-div-walk-sign {
		width: 100%;
		height: 768px;
	}

	/*Login*/

	.after-app-wrap-center-login-div-walk, .after-app-wrap-center-login-div-walk-sign{
		width: 100%;
	}

	.form-control, .login-form-row-no-label input, .login-form-row-no-label input:focus,
	.btn-imitation, .login-form-row-no-label a {
		font-size: calc(1rem * var(--koeff))!important;
	}

	.dropdown-menu a{
		font-size: calc(1rem * var(--koeff));
	}

	.login-nav{
		width:100%;
	}

	.password span {
		left: 9.5rem;
	}

	.btn-mea{
		font-size: calc(2.5rem * var(--koeff))!important;
	}

	/*Signin*/

	.signintooltip{
		width: 40%;
	}

	.signup-form {
		width: 42vw;
		margin: 2rem auto;
	}
	
	/*Nav*/

	.btn-mobile-comments{
		display: none;
	}

  	/*Get Images*/

	.dialog{
		left: 46%;
		height: unset;
		padding: 0;
	}

	.dialog img{
		border: 3px solid white;
	}

  /*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*search UI*/

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}

	.search-card p{
		font-size: calc(1rem * var(--koeff));
	}

	.search-card .info-f {
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}

	.background-text, .background-text-studio{
		display: none;
	}
}

/********************************Huge samsung phone*****************************/

@media (min-width: 970px) and (max-width: 1200px) {

	.after-app-wrap, .after-app-wrap-center-div {
		width: 100%;
	}

	/*Login*/

	.after-app-wrap-center-login-div-walk, .after-app-wrap-center-login-div-walk-sign{
		width: 100%;
	}

	/*Nav*/

	.nav{
		min-height: 6vh;
	}

	.btn-mobile-comments{
		display: none;
	}

	

	.login-background .imagine {
		font-size: 2.5rem;
    	top: calc(58% - 70px);
    	position: absolute;
	}

	.login-background .imagine .script{
		display: unset;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 187px!important;
	}

	/*Signin*/

	.signintooltip{
		width: 40%;
	}

	/*Posts and Profile*/

	/* Followers */

	.after-app-wrap-center-div {
		width: 100%;
		top: 180px; /*ipad is affected */
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
  }

	.right-div, .right-div-profile {
		width: 100%;
		height: 115px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div, .right-div-home{
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
		font-size: calc(1rem * var(--koeff));
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card, .right-div-home .card{   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.right-div .tagline {
		display: none;
	}

	.search-card .tagline{
		display: block;
	}
	
	.username{
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.search-card .username{
		font-weight: 600;
	}

	.search-card p{
		font-weight: 400;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested, .right-div-home .suggested{
		padding: 0rem;
	}

	.post-div, .post-div-profile {
		width: 80vw;
		margin: 0 auto;
  	}

	.post-div{
		margin: auto;
	}

	.about {
		margin: 3rem;
	}

	.icon-count {
		margin-top: 1px;
	}

	.search-card .info-f {
		left: 62%;	
	}

	/*Get Images*/	

	.dialog{
		left: unset;
		margin: auto;
		height: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%)!important;
	}

	.dialog img{
		width: 100%;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*Artificial Intellegence*/

	.modal-fullscreen .ai-concierge .image-parent-create-post img,
	.modal-fullscreen .image-parent-create-post img{
		max-height:53vh!important;
	}

	.modal-dialog .form-group{
		width: 60%;
		margin: auto;
	}
}


/********************************IPAD MINI LANDSCAPE / O laptop My Profile and Profile of Any User
post-div, post-profile-div, image-text-div 
Samsung Business Laptop******************************/

@media (min-width: 1023px) and (max-width: 1099px) {

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop {
		/* width: 1000px; */
		width: 80%;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 187px!important;
	}
	 
	/*Signin*/

	.signintooltip{
		width: 40%;
	}

	.image-parent img{
		height: unset!important;
	}

	/*GetImages*/

	.library-div-profile{
		padding: 1rem;
	}

	.after-app-wrap-images-div{
		width: 100%;
	}

	.dialog{
		left: unset;
		margin: auto;
		height: 90%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%)!important;
	}

	.dialog img{
		width: 100%;
	}

	/*Comments*/

	.btn-mobile-comments{
		display: none;
	}

	.talkbubble{
		max-width: 88.1vw;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*Search with tags*/

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}

	.search-card .username{
		font-weight: 600;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}

	.search-center-parent{
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		background-color: white;
		height: 50px;
			width: 100%;
	}

	.search-center {
		position: fixed;
		z-index: 1000;
		top: 0.5rem;
		width: 98%;
		left: 0.25rem;
	}
	
	.search-center input{
		width: 100%;
		font-weight: 350;
		border: 1px solid #0cf;
		color: black!important;
		height: 45px;
	}

	.search-center .form-control{
		border: 2px solid #0cf!important;
		border-radius: 0.5rem!important;
		}

	.search-center .btn{
		border-radius: 0.5rem!important;
	}

	#inp::placeholder, .search-center .form-control::placeholder {
		color: grey!important;
	}

	.search-center-parent .btn-arrow{
		background: transparent!important;
		border: none !important;
	}

	/***search options*/
	
	.search-options-container{
		max-height: none;
	}

	.search-dropdown{
		width: 100%;
	}

	/*AI*/

	.body-fullscreen-3 {
		height: 100vh;
		margin: 0;
		padding: 0!important;
  	}	

	.modal-dialog .form-group{
		width: 60%;
		margin: auto;
	}

	/*New login*/

	.login-background .login-form{
		float: right;
		width: 30vw;
		margin: 30% 0;
	}

	.login-background .imagine {
		position: absolute;
		left: 7%;
		margin: 0;
		text-align: left;
		font-size: 3.5rem;
		font-weight: 500;
		width: 100%;
  	}

	/* New ai*/

	.modal-fullscreen .body-fullscreen-3 .background-text, 
	.modal-fullscreen .body-fullscreen-3 .background-text-studio{
		font-size: 9rem!important;
	}

	.modal-fullscreen .body-fullscreen-3 .background-text {
		/* right: 15%; */
		display: none;
	}

	.modal-fullscreen .body-fullscreen-3 .background-text-studio{
		display: none;
	}

	/*End of IPAD MINI LANDSCAPE*/
}

/* Various tablet devices including Surface Duo */

@media (min-width: 1100px) and (max-width: 1175px) {
	.modal-fullscreen .body-fullscreen-3 .background-text,
	.modal-fullscreen .body-fullscreen-3 .background-text-studio {
		display: none;
	}
}

/*All big ipads Landscape for followers on the top April 16*/ 

@media (min-width: 1023px) and (max-width: 1367px) {

	.after-app-wrap-center-div {
		width: 100%;
		top: 180px;/*ipad and o desktop is affected? */
		padding: 0;
		min-height: unset;
		padding-bottom: 3rem;
	}

	.suggested p{
		font-weight: 300!important;
		font-size: calc(0.875rem* var(--koeff));
		text-align: center;
	}

	.search-container .suggested p {
		text-align: left;
	}

	.tagline{
		display: none;
	}

	.search-container .tagline{
		display: block;
	}

	.modal-dialog .form-group{
		width: 60%;
		margin: auto;
	}

	.info-f {
		padding-left: 0;
	}
}

/* LG Pad 5 and bigger*/

@media (min-width: 1195px) and (max-width: 1367px) {
	.modal-fullscreen .body-fullscreen-3 .background-text-studio{
		bottom: calc(50% + 82px) !important;
	}
}

/*HUGE IPADS. PORTRAIT*/

@media (min-width: 970px) and (max-width: 1024px) {
	.modal-fullscreen .radio-container-parent {
		width: 75%;
	}

	.script{
		display: block;
	}
}

/********************************O Mac. Ipad Air Landscape. Ipad Pro Landscape. 1180 Only?******************************/

@media (min-width: 1100px) and (max-width: 1366px) {

	.after-app-wrap-center-div, .after-app-wrap-center-div-gotop {
		/* width: 1050px;  */
		width: 80%;
	}

	.sharedPost .after-app-wrap-center-div{
		width: 80%;
		margin: auto;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 187px!important;
	}

	.overlay{
		display: none;
	}

	/*Login*/

	/*Signin*/

	.signintooltip{
		width: 40%;
	}

	/*Posts*/

	.post-div{
		width: 52vw;
	}

	/*GetImages*/

	.library-div-profile{
		width: 920px;
		margin: auto;
	}

	.after-app-wrap-images-div{
		width: 100%;
	}

	.image-text-div{
		height: 227px;
	}

	.image-text-div img {
		width: 227px!important;
		height: 227px;
	}

	.dialog{
		left: unset;
		margin: auto;
		height: 90%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%)!important;
	}

	.dialog img{
		width: 100%;
	}

	/*Comments*/

	.btn-mobile-comments{
		display: none;
	 }

	.nav .clicked-user img {
		border-radius: 50%;
	}

	.talkbubble{
		max-width: 70vw;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*Search with tags*/

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}

	/*AI*/

	.body-fullscreen-3 {
		height: 100vh;
		margin: 0;
		padding: 0!important;
  	}	

	.avatar-ai-sm{
		margin-left: 1rem;
	}

	.consierge {
		top: 65px;
	}

	/*End of Ipad Air Landscape */
}

/*IPAD PRO 11 LANDSCAPE and higher*/ 

@media (min-width: 1194px) and (max-width: 1279px) {
	.modal-fullscreen .body-fullscreen-3 .background-text-studio {
		bottom: calc(50% + 65px);
	}
}

/********************************ALL IPADS LANDSCAPE. 
Ipad Pro Portrait. Ipad Air Landscape. AM Ipad Landscape. O laptop followers scroll. *****************************/

@media (min-width: 1023px) and (max-width: 1279px) {
	html, body{
		overflow-y: unset;
		font-size: 14px;
	}

	.nav{
		min-height: 4vh!important;
		top: 0;
	}

	.after-app-wrap-center-login-div {
		width: 100%;
	}

	.login-background .imagine{
		position: absolute; /* Position relative to the container */
		left: 7%; 
		margin: 0; /* Remove default margin */
		text-align: left;
		font-size: 3.5rem;
		font-weight: 500;
		width: 100%;
	}

	.forgot-password .login-form, .enterCode .login-form, .resetPassword .login-form {
		width: 45%;
	}

	/*Signin*/

	.signup-form{
		width: 40vw;
		margin: 3rem auto;
	}
	
	.signintooltip{
		width: 40%;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 161px!;
		-webkit-transform: translate(51px,41px);
		transform: translate(42px,49px);
	}

	.right-div .scroll{
		right: 0;
		width: 100%;
		position: relative;
		height: 90vh;
		overflow-y: scroll;
	}

	/*Comments*/

	.btn-mobile-comments{
		display: none;
	}

	/*GetImages*/

	.after-app-wrap-images-div {
		width: 1000px;
		margin: auto;
	}

	/*Signup tooltip*/

	.info-tooltip{
		width: 80%;
		margin: auto;
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-mobile{
		display: block;
	}
	
	.info-desktop{
		display: none;
	}

	/*Report*/
	.dropdown-menu-report, .dropdown-menu-report.show {
		width: 70px!important;
		min-width: 70px!important;
		transform: translate(104px,12px)!important;
		border: 1px solid rgba(0, 0, 0, 0.04);
    	box-shadow: 0 6px 12px 2px rgb(0 0 0 / 8%);
		background: #fff!important;
		padding: 0;
		padding: 0;
		border-radius: 0;
	}

	.dropdown-menu-report p{
		float: left;
		width: 100%;
		padding: 0.4rem;
		color: #1663D6 !important;
		font-size: calc(14px * var(--koeff));
	}

	.dropdown-report .btn{
		padding: 0.375rem 0!important;
	}

	/*Sharing functionality*/

	#sharedModal svg, #sharedModal .copy{
		height: 40px!important;
		width: 40px!important;
		margin-right: 1rem;
	}

	#sharedModal .copy svg{
		height: 30px!important;
		margin-top: 3px!important;
    	margin-left: 0!important;
	}

	.consierge {
		padding: 0;
    	width: 100%;
	}

	.consierge .profile-block {
		padding-top: 2rem;
  	}

	/*Search UI with min-width*/

	.search-container{
		top: 80px;
	}

	.search-card{
		width: auto;
		min-width: 29.5vw!important;
		float: left;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}

	/*Search with tags*/

	.search-card{
		width: auto;
		min-width: 31vw!important;
		float: left;
	}

	.search-card .username{
		font-weight: 600;
	}
	
	.search-card .info-f{
		padding-left: 0.5rem;
		word-break: break-word;
		width: 80%;
	}

	.search-center-parent{
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		background-color: white;
		height: 50px;
		width: 100%;
	}

	.search-center {
		position: fixed;
		z-index: 1000;
		top: 0.5rem;
		width: 99.5%;
		left: 0.25rem;
	}
	
	.search-center input{
		width: 100%;
		font-weight: 350;
		border: 1px solid #0cf;
		color: black!important;
		height: 45px;
	}

	.search-input-container .btn-magnifier-arrow {
		position: absolute;
		top: 0;
		left : 0;
		height: 100%;
      display: flex;  /* Use flexbox for layout */
      align-items: center;  /* Center items vertically */
      justify-content: center; /* Center items horizontally */
      height: 100%;
      width: 45px; /* to match 45px height in order to be sqaure */
      padding: 0;
	} 

	.search-center .form-control{
		border: 2px solid #0cf!important;
		border-radius: 0.5rem!important;
		}

	.search-center .btn{
		border-radius: 0.5rem!important;
	}

	#inp::placeholder, .search-center .form-control::placeholder {
		color: grey!important;
	}

	.search-center-parent .btn-arrow{
		background: transparent!important;
		border: none !important;
	}

	.search-input-container .btn-arrow,
	.search-input-container .btn-arrow:hover,
	.search-input-container .btn-arrow:focus,
	.search-input-container .btn-arrow:active,
	.search-input-container .btn-arrow:visited{
		position: absolute;
		top: 1px;
		left: 1px;
		height: 96%;
		padding: 0.45rem 0.75rem 0.375rem 0.75rem!important;
		border: none;
	}

	.search-input-container button,
	.search-input-container button:hover,
	.search-input-container button:focus,
	.search-input-container button:active,
	.search-input-container button:visited {
		position: absolute;
		height: 100%;
		padding: 0.45rem 0.75rem 0.375rem 0.75rem!important;
		height: 97%;
	}

	/***search options*/
	
	.search-options-container{
		max-height: none;
	}

	.search-dropdown{
		width: 100%;
	}

	.avatar-ai-sm{
		margin-left: 1rem;
	}

	/*Consierge gallery*/
	
	.consierge {
		height: 100%!important;
	}

	.consierge-desktop-gallery {
		width: 100%;
	}

	/*AI Modal*/

	.modal-fullscreen .switch {
		width: 30%;
	}

	/*New Consierge*/

	.body-fullscreen-3 {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	.modal-fullscreen .body-fullscreen-3 .background-text,
	.modal-fullscreen .body-fullscreen-3 .background-text-studio {
		font-size: 10rem;
	}

	.modal-fullscreen .body-fullscreen-3 .background-text {
      transform: rotate(0deg);
		bottom: 7%;/* Adjust for tablet bottom-right corner */
      right: 11%;
	}

	.modal-fullscreen .body-fullscreen-3 .background-text-studio {
		position: absolute;
		transform: rotate(-90deg); 
		transform-origin: bottom right;
		bottom: 59%;
		/* bottom: calc(50% + 60px); */
		right: 3%;
	}

	/*New login*/

	.login-background .imagine{
		font-size: 3rem!important;
		top: calc(58% - 70px);
	}

	.login-background .imagine .script{
		display: unset;
	}

	.sharedPost {
		margin: auto;
		width: 100%;
	}

	.buttonsDiv {
		font-size: calc(1rem * var(--koeff))!important;
	}

	.info-f {
		padding-left: unset;
	}
}

/*End of ALL IPADS LANDSCAPE*/

/********************************Mac, Nest Hub Max. Very small laptops. Halaxy Tab S7 Landscape******************************/
@media (min-width: 1280px) and (max-width: 1366px) {
	/*login*/

	.signup-form {
		width: 30vw;
	}

  	/*Posts*/

	.post-div{
		top: 7px;
		width: 49vw;
	}
	
	.scroll{
		right: 9rem;
	}

	.image-test-div{
		width: 316px;
	}

	/*Profile*/

	.post-div-profile {
		width: 50vw!important;
	}

	.image-text-div img {
		width: 25vw!important;
		height: 25vw!important;
	}

	/*GetImages*/

	.after-app-wrap-images-div{
		width: 1000px;
		margin: auto;
	}
	
	.image-text-div {
		height: 25vw;
	}

	/*Settings*/

	.account .profile-info-account{
		max-width: 55%!important;
	}

	/*Comments*/

	.btn-mobile-comments{
		display: none;
	 }

	/*Sharing functionality*/

	#sharedModal svg, #sharedModal .copy{
		height: 50px!important;
		width: 50px!important;
		margin-right: 1rem;
	}

	#sharedModal .copy svg{
		width: 40px!important;
	}	

	.buttonsDiv {
		font-size: calc(1rem * var(--koeff))!important;
	}

	/*Concierge gallery */

	.consierge-desktop-gallery, .consierge-desktop-gallery .library-div-profile {
		width: 100%;
	}
	
}

/********************************Ipad Pro Landscape******************************/

@media (min-width: 1367px) and (max-width: 1400px) {

	.after-app-wrap-center-login-div {
		width: 100%;
	}

	.dropdown-menu, .dropdown-menu.show {
		width: 187px!important;
		-webkit-transform: translate(51px,41px)!important;
		transform: translate(3px,41px)!important;
	}

	.login-form {
		width: 34%;
		margin: 7rem auto;
		position: relative;
	}

	/*Posts*/

	.post-div{
		width: 46vw;
	}

	.right-div, .right-div-create-post {
		width: 35vw;
	}

	/*Profile*/

	.library-div-profile{
		width: 100%;
		padding: 1rem;
	}

  .image-text-div{
	  height: 331px;
  }

  .image-text-div img {
		width: 331px!important;
		height: 331px;
  }
  /*Comments*/

  .btn-mobile-comments{
		display: none;
	}

	/*GetImages*/

	.after-app-wrap-images-div{
		width: 100%;
	}	
}

/******************************Possible Big Mobile devices. V computer******************************/

@media (min-width: 1401px) and (max-width: 1600px) {

	/*General*/

	.after-app-wrap-center-div{
		width: 70%; /* Default width */
    	margin: 0 auto; /* Center the container */
	}

	.sharedPost{
		top: 0!important;
		height: 100vh;
	}

	.sharedPost .nav{
		position: relative;
		width: unset;
		margin-bottom: 0 !important;
	}

	.sharedPost .card-wrapper{
		padding-bottom: 0.5rem;
	}

	.post-div{
		width: 43.3vw;
	}

	.scroll{
		width: 21vw;
		height: 90vh;
	}

	.btn-mobile-comments {
		display: none;
	}

	.suggested{
		font-size: calc(14px * var(--koeff))!important;
	}

	.search-card{
		min-width: 20vw;
		width:auto;
		float:left;
	}

	.modal-fullscreen .image-parent-create-post img {
		max-height: 59vh!important;
	}

	.modal-fullscreen .radio-container-parent {
		width: 64%;
	}

	.modal-fullscreen .form-control-radio p {
		margin-bottom: 0.2rem!important;
	}

	.background-text, .background-text-studio{
		font-size: 10.5rem;
	}
}

/********************************Wolodin comp?******************************/

@media (min-width: 1601px) and (max-width: 10000px) {

	/*General*/

	.after-app-wrap-center-div{
		width: 1050px;
	}

	.post-div{
		width: 42vw;
	}

	.post-div-profile{
		width: 42vw!important; 
	}

	.scroll{
		width: 20vw;
		right: 14vw;
	}

	.btn-mobile-comments {
		display: none;
	}

	.search-card{
		min-width: 20vw;
		width:auto;
		float:left;
	}

	.modal-fullscreen .image-parent-create-post img {
		max-height: 59vh!important;
	}

	.modal-fullscreen .switch {
		width: 30%;
  	}
}

/* Huge computers */

@media (min-width: 3001px) and (max-width: 10000px) {

	.login-form, .signup-form, .forgot-password .login-form, .enterCode .login-form, .resetPassword .login-form {
		width: 10vw;
	}

	.search-card{
		width: 10vw !important;
		min-width: 10vw !important;
	}
}

/*Huge ipad. portrait*/ /*TEST IF THIS DOESNT COMPROMISE SMALL LAPTOP OR LANDSCAPES*/

@media (min-width: 1024px) and (max-width: 1100px) and (orientation: portrait) {
	.background-text {
		transform: rotate(0deg);
		bottom: 0%; /* Adjust for tablet bottom-right corner */
		right: calc(100% - 86%); 
	}

	.background-text, .background-text-studio{
		font-size: 10rem;
	}

	.background-text-studio {
		position: absolute;
		transform: rotate(-90deg); 
		transform-origin: bottom left;
		bottom: 3%;
		left: calc(100% + 3%); 
	}
}

/* LG Pad*/

@media (min-width: 1200px) and (max-width: 1921px){

	.modal-fullscreen .body-fullscreen-3 .background-text-studio{
		bottom: 64%!important;
	}
}


.hiddenElement {
	visibility: hidden;
	pointer-events: none;
}

/******************************** Spinner ******************************/

#spinner{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px; 
	margin: auto;
	text-align: center;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #00adef;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

/*possible centering threeDots*/

  