[data-bs-theme="dark"] .search-dropdown{
	background: #1b1b1b;
	background-color: #1b1b1b!important;
	color: white;
	border: 1px solid grey;;
}

[data-bs-theme="dark"] .search-option:hover, 
[data-bs-theme="dark"] .search-option.selected,
[data-bs-theme="dark"] .search-option.active {
  background-color: #333!important;
  color: #fff!important;
}

[data-bs-theme="dark"] .search-options-container::-webkit-scrollbar-thumb {
  background-color: #333;
}

@media (min-width: 1px) and (max-width:431px) {

   [data-bs-theme="dark"] .search-dropdown{
		background: #1d2733;
		background-color: #1d2733!important;
		color: white;
		border: none;
  	}

	[data-bs-theme="dark"] .search-option:hover, 
	[data-bs-theme="dark"] .search-option.selected,
	[data-bs-theme="dark"] .search-option.active {
			background-color: #283f5b!important;
			color: #fff;
	}
}