.radio-hover {
  transition: transform 0.2s ease-in-out;
}

.radio-hover:hover {
  transform: scale(1.05);
}

.radio-hover:hover .image {
  opacity: 0.8;
}

.radio-click {
  transform: scale(1.05);
  border: 1px solid #666666 !important;
}

.radioDisable {
  opacity: 0.5;
}