.custom-dropdown {
	position: relative;
	width: 500px; 
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 5px;
}
  
.options-container {
	/* Set a fixed height or max-height to trigger the vertical scrollbar */
	max-height: 200px; 
	overflow-x: hidden; 
	overflow-y: auto; 
}
  
.option-wrapper {
	width: 100%;
	display: flex;
}
  
.option {
	padding: 5px;
	flex: 1;
	cursor: pointer;
}
  
.option:hover {
	background-color: #f0f0f0;
}
  
/* Apply styles to the selected and active option */
.option.selected,
.option.active {
	background-color: #007bff;
	color: #fff;
}