.search-dropdown {
	position: relative;
	width: 650px;
	margin: auto;
	background-color: #fff;
	/* border: 1px solid #ccc; */
	border-radius: none;
	padding: 0;
}

.search-options-container {
	overflow: auto; /* Make sure the container has a scrollbar */
	word-break:break-word;
}
 
.search-options-container::-webkit-scrollbar {
	width: 8px; /* Adjust the width as needed */
}
 
.search-options-container::-webkit-scrollbar-thumb {
	background-color: #f0f0f0; /* Scrollbar thumb color */
	border-radius: 4px; /* Rounded corners for the thumb */
}
 
/* For Firefox */
.search-options-container {
	scrollbar-width: thin; /* This property enables custom scrollbar support in Firefox */
}
 
.search-options-container::-moz-scrollbar-thumb {
	background-color: #f0f0f0; /* Scrollbar thumb color */
	border-radius: 4px; /* Rounded corners for the thumb */
}

.search-options-container {
	text-align: left;
	overflow-x: hidden; 
	overflow-y: auto; 
}

.search-options-container-max-height {
	max-height: 350px;
}

.search-option:hover,   
.search-option.selected,
.search-option.active {
	background-color: #f0f0f0!important;
	color: #333!important;
}

.search-option-wrapper {
	width: 100%;
	display: flex;
}

.search-option {
	padding: 0.3rem 1rem;
	flex: 1;
	cursor: pointer;
	font-weight: 400;
}

@media (min-width: 1px) and (max-width: 431px) {
	.search-dropdown {
		/* top: 1rem; */
		top: 0;
		width: 100%;
		padding: 0;
		overflow: auto;
		height : 100%;
	}

	.search-option {
		font-size: calc(1rem * var(--koeff)); 
	}
}

@media (min-width: 432px) and (max-width:1024px) {
	.search-dropdown {
		top: 0;
		width: 100%;
		padding: 0 0.5rem;
		overflow: auto;
		height : 100%;
	}
}
	