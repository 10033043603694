/* Mobil devices signup */

.tippy-box[data-theme~='medeata-signup']  {
	background: #222;
	color: #fff;
	border-radius: 4px;
	border: 1px solid transparent;
	padding: 8px;
	font-size: 18px;
}

.tippy-box[data-theme~='medeata-signup'] > .tippy-svg-arrow {
	fill: red;
}

/* Desktop devices */

 /* signup */
.tippy-box[data-theme~='medeata-signup-desktop']  {
	background: #222;
	color: #fff;
	border-radius: 4px;
	border: 1px solid transparent;
	padding: 8px;
	font-size: 15px;
}

.tippy-box[data-theme~='medeata-signup-desktop'] > .tippy-svg-arrow {
	fill: red;
}

 /* icons */
.tippy-box[data-theme~='medeata-icon-desktop']  {
	/* background: rgb(83, 83, 83); */
	background: #222;
	color: #fff;
	border-radius: 8px;
	border: 1px solid transparent;
	padding: 7px;
	font-size: 13px;
}

.tippy-box[data-theme~='medeata-icon-desktop'] > .tippy-svg-arrow {
	fill: red;
}