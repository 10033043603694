/*All tablets*/

/*Search with tags*/

/**Tablet Genetal Landscape and Portrait included, landscape phones**/

@media (min-width: 430px) and (max-width: 1366px) {

   .search-container{
      top: 2.5rem!important;
   }

   .search-center-parent{
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      background-color: white;
      height: 60px;
      width: 100%;
   }

   .search-center {
      position: fixed;
      z-index: 1000;
      top: 0.5rem;
      width: 99.5%!important;
      left: 0.25rem;
   }
   
   .search-center input{
      width: 100%;
      font-weight: 350;
      border: 1px solid #0cf;
      color: black!important;
      height: 55px;
   }

   .search-input-container .btn-magnifier-arrow {
		position: absolute;
		top: 0;
		left : 0;
		height: 100%;
      display: flex;        /* Use flexbox for layout */
      align-items: center;  /* Center items vertically */
      justify-content: center; /* Center items horizontally */
      height: 100%;
      width: 55px;
      padding: 0;
	} 

   .search-center .form-control{
      border: 2px solid #0cf!important;
      border-radius: 0.5rem!important;
   }

   .search-center .btn{
      border-radius: 0.5rem!important;
   }

   #inp::placeholder, .search-center .form-control::placeholder {
      color: grey!important;
   }

   .search-center-parent .arrows{
      color: #0cf!important;
   }

   .search-input-container input {
      padding-left: 65px !important;
   }

   /***search options*/
   
   .search-options-container{
      max-height: none;
   }

   .search-dropdown{
      width: 100%;
   }

   .search-container .form-control,
   .search-center .form-control {
      border: 2px solid #0cf!important;
      border-radius: 0.5rem!important;
   }
}

/**Tablet Partrait**/

@media (min-width: 720px) and (max-width: 969px){

   .search-center {
      top: 0.5rem !important;
      width: 99% !important;
      left: 0.25rem !important;
   }
}

/**My Tablet Partrait**/

@media (min-width: 432px) and (max-width: 719px) {
   .search-center input{
      width: 99.5%;
   }
}