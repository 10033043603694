.EmojiPickerReact .epr-skin-tones {
	display: none !important;
}

.EmojiPickerReact .Flex.FlexRow {
	display: none !important;
}

.emojiImgDiv {
	display:flex;
	align-items: center;
	height: 38px;
}

.marginLeft {
	margin-left: -20px;
}