.search-input-container input {
	padding-right: 35px;
}

.search-input-container .eraser-X {
	position: absolute;
	top: 50% !important;
	transform: translateY(-50%) !important;
	right: 1.5rem !important; 
	cursor: pointer;
}

.eraser-img-X {
	top: 50% !important;
	transform: translateY(-50%) !important;
	position: absolute;
}