[data-bs-theme="dark"] :root{
    background: #1b1b1b;
    background-color: #1b1b1b!important;
    color: white;
}

[data-bs-theme="dark"] body{
    font-weight: 300!important;
    background: #1b1b1b;
    background-color: #1b1b1b!important;
    color: white;
    background-image: none;
}

[data-bs-theme="dark"] .rootdiv{
    width: 100%;
    margin: 0;
    padding: 0;
    background: #1b1b1b;
    color: white;
}

/*Get Comments*/

[data-bs-theme="dark"] .parent-div{
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #1b1b1b;
    color: white;
}

[data-bs-theme="dark"] .background{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

[data-bs-theme="dark"] .test-div, 
[data-bs-theme="dark"] .nav, 
[data-bs-theme="dark"] .App-div, 
[data-bs-theme="dark"] .right-div, 
[data-bs-theme="dark"] .right-div-home, 
[data-bs-theme="dark"] .right-div-profile, 
[data-bs-theme="dark"] .profile-block,
[data-bs-theme="dark"] .modal-dialog, 
[data-bs-theme="dark"] .modal-content, 
[data-bs-theme="dark"] .account-form {
    background: #1b1b1b;
    background-color: #1b1b1b!important;
    color: white;
}

[data-bs-theme="dark"] .bold {
    font-weight: 400!important;
}

[data-bs-theme="dark"] .card-wrapper{
    background-color: #1b1b1b;
}

[data-bs-theme="dark"] .card{
    background-color: #1b1b1b!important;
}

[data-bs-theme="dark"] .postcard{
    border:1px solid grey!important;
}

[data-bs-theme="dark"] .post-row{
    border-bottom: 0;
}

[data-bs-theme="dark"] .btn-dark, 
[data-bs-theme="dark"] .dropdown button, 
[data-bs-theme="dark"] .profile-block .btn {
    color: white;
}

[data-bs-theme="dark"] .btn-outline-primary, 
[data-bs-theme="dark"] .btn-outline-primary:hover {
    border: 1px solid #0cf!important;
    background-color: #1b1b1b!important;
    color: #0cf!important;
}


[data-bs-theme="dark"] .nav-icon, 
[data-bs-theme="dark"] .nav .nav-icon, 
[data-bs-theme="dark"] .home-icons-medium, 
[data-bs-theme="dark"] .home-icons-small, 
[data-bs-theme="dark"] .icon-div, 
[data-bs-theme="dark"] .icon-div a, 
[data-bs-theme="dark"] .icon-div p, 
[data-bs-theme="dark"] .profile-info h4,
[data-bs-theme="dark"] .modal-content .modal-close, 
[data-bs-theme="dark"] .media-icon, 
[data-bs-theme="dark"] .search-card,
[data-bs-theme="dark"] .search-card .username,
[data-bs-theme="dark"] .suggested .username,
[data-bs-theme="dark"] .username, 
[data-bs-theme="dark"] #webSiteId,
[data-bs-theme="dark"] .suggested-desktop .username-desktop{
    color: #00ccff!important;
}

[data-bs-theme="dark"] .btn-primary .home-icons-medium,
[data-bs-theme="dark"] .suggested-desktop .tagline{
    color:#fff!important;
    font-weight: 300;
}

[data-bs-theme="dark"] .nav-settings:hover, 
[data-bs-theme="dark"] .dropdown-toggle:hover, 
[data-bs-theme="dark"] .btn:hover,
[data-bs-theme="dark"] [data-bs-theme="dark"] .btn-success:hover{
    background-color: #333!important;
}

[data-bs-theme="dark"] .suggested a, 
[data-bs-theme="dark"] .suggested p, 
[data-bs-theme="dark"] .search-card a,   
[data-bs-theme="dark"] [data-bs-theme="dark"] .modal-myheader h6{
    color: #e6e2e2!important;
}

[data-bs-theme="dark"] .postcard .small, 
[data-bs-theme="dark"] [data-bs-theme="dark"] .small{
    color: #88959e!important;
}

[data-bs-theme="dark"] .dropdown button{
    background-color: transparent!important;
}

[data-bs-theme="dark"] label{
    color: #dedede;
}

[data-bs-theme="dark"] .suggested-desktop .username-desktop {
    font-weight: 400;
}

[data-bs-theme="dark"] .search-option {
    font-weight: 300;
}

[data-bs-theme="dark"] .form-control,  
[data-bs-theme="dark"] .edit-profile textarea, 
[data-bs-theme="dark"] .mega-menu-share,
[data-bs-theme="dark"] .form-group textarea{
    border: 1px solid grey!important;
    background-color: #1b1b1b;
}

[data-bs-theme="dark"] .form-control:focus,
[data-bs-theme="dark"] .edit-profile textarea:focus{
    background-color: #333;
}

[data-bs-theme="dark"] .form-control:focus, 
[data-bs-theme="dark"] [data-bs-theme="dark"] .login-form-row-no-label input:focus{
    color: white;
    border-color: 1px solid white;
}

[data-bs-theme="dark"] .form-control:disabled, 
[data-bs-theme="dark"] input[type=text]:disabled {
    /* color: #2e3639; */
    background-color: #303030 !important;
}

[data-bs-theme="dark"] textarea[name=createcomment], 
[data-bs-theme="dark"] textarea[name=createpost], 
[data-bs-theme="dark"] textarea[name=createcomment]:visited, 
[data-bs-theme="dark"] textarea[name=createpost]:visited, 
[data-bs-theme="dark"] .form-control:visited, 
[data-bs-theme="dark"] .login-form-row-no-label input:visited
[data-bs-theme="dark"] .login-form-row-label-bio textarea:visited,
[data-bs-theme="dark"] .form-control,  
[data-bs-theme="dark"] .mega-menu-share {
    background-color: #1b1b1b;
    font-weight: 300;
}

[data-bs-theme="dark"] textarea[name=createcomment]:visited, 
[data-bs-theme="dark"] textarea[name=createpost]:visited, 
[data-bs-theme="dark"] .form-control:visited, 
[data-bs-theme="dark"] .login-form-row-no-label input:visited
[data-bs-theme="dark"] .login-form-row-label-bio textarea:visited,
[data-bs-theme="dark"] .form-control,  
[data-bs-theme="dark"] .mega-menu-share {
    color: white!important;
    background-color: #1b1b1b;
    font-weight: 300;
}

[data-bs-theme="dark"] .x-input::placeholder {
    color: rgb(255, 255, 255, 0.5) !important;
    font-weight: 300;
}

[data-bs-theme="dark"] .login-form .x-input::placeholder,
[data-bs-theme="dark"] .signup-form .x-input::placeholder {
    color: rgb(0, 0, 0, 0.5) !important;
    font-weight: 400;
}

[data-bs-theme="dark"] .x-input {
    color: rgb(255, 255, 255) !important;
    font-weight: 300;
}

[data-bs-theme="dark"] .login-form .x-input,
[data-bs-theme="dark"] .signup-form .x-input {
    color: #222 !important;
    font-weight: 400;
}

[data-bs-theme="dark"] input:-webkit-autofill {
    -webkit-text-fill-color:#222; 
	font-size: calc(1rem * var(--koeff))!important;
}

[data-bs-theme="dark"] .eye{
    color: grey;
}

/*Posts*/

[data-bs-theme="dark"] .image-parent{
    background-color: #272727;;
}

/*Comments*/

[data-bs-theme="dark"] .talkbubble, 
[data-bs-theme="dark"] .comments-topost{
    background: #333;
    border: 1px solid #7e8487;
}

[data-bs-theme="dark"] .triangle-left{
    border-right: 15px solid #7e8487;
}

[data-bs-theme="dark"] .inner-triangle {
    position: relative;
    top: -20px;
    left: 0px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 23px solid #333;
    z-index: 900;
}

/*Consierge*/

[data-bs-theme="dark"] .consierge{
    /* height: 100vh;
    background: url(../images/consierge-greet2-dark-desktop.png) center bottom;
    background-size: 50%;
    background-repeat: no-repeat; */
    width: 100%;
    padding: 0;
    margin: 0;
}

/*Modals*/

[data-bs-theme="dark"] .modal-dialog{
    border-radius: 25px;
    background: #333;
    color: white;
    padding: 1rem;
    margin: 0rem auto!important;
    box-shadow: 0 12px 28px 0 #e3e1e1, 0 2px 4px 0 #f4f4f4, inset 0 0 0 1px #f4f4f4;
    width: 600px!important;
    max-height: 100vh;
}

[data-bs-theme="dark"] .modal-body p, 
[data-bs-theme="dark"] .modal-dialog .modal-body p, 
[data-bs-theme="dark"] .modal-dialog .modal-body h5, 
[data-bs-theme="dark"] .modal-body {
    color:#dedede;
}

[data-bs-theme="dark"] .modal-company-individual {
    width: 860px!important;
    max-width: 860px!important;
    text-align: left;
    height: 100%!important;
}

[data-bs-theme="dark"] .modal-bode h5{
    margin-bottom: 1rem;
    font-weight: 400;
}

/*Search*/

[data-bs-theme="dark"] .search-card{
    border: 1px solid #5c5a5a!important;
}

.search-input-container .btn-primary[disabled]:hover{
	background-color: #00bcd4 !important;
    border: 1px solid #00bcd4 !important;
    box-shadow: none!important;
}

.search-input-container .btn-primary:hover{
	background-color: #00bcd4 !important;
    border: 1px solid #00bcd4 !important;
}

/*Artificial Intellegence*/

[data-bs-theme="dark"] .body-fullscreen-3{
    /* background: url(../images/consierge-greet2-dark-desktop.png); */
    background: none;
	background-repeat: no-repeat;
	background-position: center bottom;
    background-size: 44%;
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0!important;
}

[data-bs-theme="dark"] .ai-response{
    border: 1px solid #7e8487;
    color: white;
}

[data-bs-theme="dark"] .ai-content::-webkit-scrollbar {
    width: 8px;
}

[data-bs-theme="dark"] .ai-content::-webkit-scrollbar-track {
    background-color: #333;
}

[data-bs-theme="dark"] .ai-content::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 10px;
}

[data-bs-theme="dark"] .ai-content::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

/*Artificial Intelegence Modal*/

[data-bs-theme="dark"] .modal-fullscreen{
    background-color: #1b1b1b;
    border: 1px solid grey;
	 width: 100%!important;
}

[data-bs-theme="dark"] .modal-fullscreen h6{
    color: #30d2fa;
    font-weight: 300;
}

[data-bs-theme="dark"] .modal-fullscreen .avatar-small-landscape, 
[data-bs-theme="dark"] .modal-fullscreen .avatar-small-portrait{
    margin: 0.2rem;
}

[data-bs-theme="dark"] .modal-fullscreen textarea[name=createpost],
[data-bs-theme="dark"] .modal-fullscreen .ai-input .ai-input-border{
    border: 1px solid #4a4a4a; 
}

[data-bs-theme="dark"] .modal-fullscreen .image-parent-create-post {
    background: #1b1b1b;
}
    
[data-bs-theme="dark"] .modal-space{
    border-radius: 3px;
    padding: 1rem;
}

@media (prefers-color-scheme: dark) {
    .iphoneIpadInset{
        background-color: black; 
    } 
}

.bkgrnd {
	width: 100vw;
	height: 100vh;
	margin: 0!important;
	padding: 0!important;
	background-color: #1b1b1b!important;
	background-image: none!important;
    background: #1b1b1b;
}

/*AI everything but mobile controlled by javascript*/

[data-bs-theme="dark"] .ai-input-butmobile{
	background-color: #1b1b1b!important;
    border: 1px solid #888686!important;
}

/*AI mobile textarea controlled by javascript*/

[data-bs-theme="dark"] .ai-textarea-mobile,
[data-bs-theme="dark"] .ai-textarea-mobile-n{
    background: #1d2733;
	background-color: #1d2733;
	color: #fff;
    border: 1px solid #788ead!important;  
}

[data-bs-theme="dark"] .modal-fullscreen .try{
    color: #05f2c8!important;
}

[data-bs-theme="dark"] .bodyBackgroundColor{
	background-color: #1b1b1b!important; 
}

[data-bs-theme="dark"] .postcard a {
    color: #0cf!important;
}

[data-bs-theme="dark"] .my-dropdown{
    background-color: #333;
}

[data-bs-theme="dark"] .search-input-container .btn-arrow,
[data-bs-theme="dark"] .search-input-container .btn-arrow:hover,
[data-bs-theme="dark"] .search-input-container .btn-arrow:active,
[data-bs-theme="dark"] .search-input-container .btn-arrow:focus,
[data-bs-theme="dark"] .btn-arrow:focus,
[data-bs-theme="dark"] .search-input-container .btn-arrow:visited{
	background-color: #333 !important;
    border: 1px solid grey;
}

[data-bs-theme="dark"] .search-center-parent{
    background-color: #1b1b1b;
}

/*New concierge*/

[data-bs-theme="dark"] .modal-fullscreen .form-control-radio {
    border: 1px solid #4a4a4a;
}

[data-bs-theme="dark"] .modal-fullscreen .form-control-radio:hover {
    border: 1px solid #fff;
}

[data-bs-theme="dark"] .radio-click {
    border: 1px solid #fff !important;
}

[data-bs-theme="dark"] .background-text, [data-bs-theme="dark"] .background-text-studio {
    color: rgba(255, 255, 255, 0.07) !important;
}

/*ALL MEDIA*/

/*All phones.Portrait*/

@media (min-width: 1px) and (max-width:431px) {   
    
    [data-bs-theme="dark"] :root{
        background: #1d2733;
        background-color: #1d2733!important;
        color: white;
    }
    
    [data-bs-theme="dark"] body{
        font-weight: 350!important;
        background: #1d2733;
        background-color: #1d2733!important;
        color: white;
    }

    [data-bs-theme="dark"] .background,
    [data-bs-theme="dark"] .rootdiv,
    [data-bs-theme="dark"] .parent-div{
        background: #1d2733;
        background-color: #1d2733!important;
        color: white;
    }

    /*Is it cat loading*/
    [data-bs-theme="dark"] .bkgrnd{
        background: #1d2733;
        background-color: #1d2733!important;
        color: white;
    }

    [data-bs-theme="dark"] .test-div,
    [data-bs-theme="dark"] .rootdiv,
    [data-bs-theme="dark"] .nav,
    [data-bs-theme="dark"] .App-div,
    [data-bs-theme="dark"] .right-div,
    [data-bs-theme="dark"] .right-div-home,
    [data-bs-theme="dark"] .right-div-profile,
    [data-bs-theme="dark"] .profile-block,
    [data-bs-theme="dark"] .modal-dialog,
    [data-bs-theme="dark"] .modal-content,
    [data-bs-theme="dark"] .account-form,
    [data-bs-theme="dark"] .search-center-parent{
        background: #1d2733!important;
        background-color: #1d2733!important;
        color: white;
        font-weight: 300;
    }

    [data-bs-theme="dark"] .card{
        background-color: #1d2733!important;
    }

    [data-bs-theme="dark"] .form-control {
        background-color: #1d2733;
        border: 1px solid grey!important;
        font-weight: 300;
    }

    [data-bs-theme="dark"] .form-control:focus, 
    [data-bs-theme="dark"] .login-form-row-no-label input:focus, 
    [data-bs-theme="dark"] .form-control:visited, 
    [data-bs-theme="dark"] .login-form-row-no-label input:visited {
        color: white;
        background-color: #1d2733;
        border: 1px solid grey;
    }

    [data-bs-theme="dark"] textarea[name=createcomment], 
    [data-bs-theme="dark"] textarea[name=createpost], 
    [data-bs-theme="dark"] .login-form-row-label-bio textarea {
        background-color: #1d2733!important;
        border-color: 1px solid white;
        font-weight: 300;
    }

    [data-bs-theme="dark"] .suggested .username, 
    [data-bs-theme="dark"] .form-control{
        font-weight: 300;
    }

    [data-bs-theme="dark"] h5{
        font-weight: 400; 
    }

    [data-bs-theme="dark"] .btn-outline-primary, 
    [data-bs-theme="dark"] .btn-outline-primary:hover {
        border: 1px solid #0cf!important;
        background-color: #1d2733!important;
        color: #0cf;
    }

    /*Comments*/

    [data-bs-theme="dark"] .talkbubble, 
    [data-bs-theme="dark"] .comments-topost{
        background: #333;
        border: 1px solid #7e8487;
    }

    [data-bs-theme="dark"] .triangle-left{
        border-right: 15px solid #7e8487;
    }

    [data-bs-theme="dark"] .inner-triangle{
        border-right: 23px solid #333;
    }

    /*Search*/

    [data-bs-theme="dark"] .search-container,
    [data-bs-theme="dark"] .center-container-small {
        background-color: #1d2733;
    }
   
    [data-bs-theme="dark"] .search-card .info-f{
        line-height: 1.3;
    }

    [data-bs-theme="dark"] .search-card .suggested .username{
        color: #0cf!important;
        font-weight: 400!important;
    }

    [data-bs-theme="dark"] .search-card .medium, 
    [data-bs-theme="dark"] .search-card .tagline{
        color: white!important;
    }

    [data-bs-theme="dark"] .search-container .form-control{
        border: none!important;
    }

    /*Search*/

    [data-bs-theme="dark"] .username {
        font-weight: 300;
    }

    [data-bs-theme="dark"] .suggested p{
        font-weight: 300;
    }

    /*Modals*/

    [data-bs-theme="dark"] .modal-dialog{
        border-radius: 0px;
        width: 100%!important;
        box-shadow: none!important;
        margin: 0 auto!important;
    }

    /*Artificial Intellegence*/

    [data-bs-theme="dark"] .modal-fulscreen {
        border:none!important;
    }

	[data-bs-theme="dark"]	.ai-input input, .ai-input .form-control{
		background-color: #1d2733!important;
        border: 1px solid #4a4a4a!important;
	}

    /*Artifical Intellegence modal*/

    [data-bs-theme="dark"] .modal-fullscreen .body-fullscreen-3{
        background: none;
        width: 100%;
        height: var(--conciergeImageHeight) !important;
        margin: 0;
        padding: 0!important;
    }

    [data-bs-theme="dark"] .modal-fullscreen .ai-concierge{
        background: #1d2733;
    	background-color: #1d2733;
    } 

    [data-bs-theme="dark"] .modal-fullscreen .image-parent-create-post {
        background: #1d2733;
    }

    [data-bs-theme="dark"] .modal-fullscreen .image-parent-create-post img {
        border: none;
    }

    [data-bs-theme="dark"] .modal-fullscreen textarea[name=createpost]{
        border: 1px solid #4a4a4a!important; 
    }

    [data-bs-theme="dark"] .bodyBackgroundColor{
        background-color: #1d2733!important; 
    }

    /*Search with tags*/

    [data-bs-theme="dark"] .search-input-container .btn-arrow,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:hover,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:active,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:focus,
    [data-bs-theme="dark"] .btn-arrow:focus,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:visited{
        background-color: #1d2733; 
    }

    [data-bs-theme="dark"]  .search-center .form-control{
        border: 1px solid #0cf!important;
        border-radius: 0.5rem!important;
    }

    [data-bs-theme="dark"]  .search-center input{
        border: 1px solid #0cf;
    }

    /*End of All phones.Portrait*/  
}

/********************************All Big Phones. Portrait******************************/

@media (min-width: 412px) and (max-width: 500px){
    [data-bs-theme="dark"] .modal-fullscreen .body-fullscreen-3 {
        background: none;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 118%;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0!important;
    }
}

/********************************All Phones. Landscape******************************/

@media (min-width: 416px) and (max-width: 850px){
    /* [data-bs-theme="dark"] .modal-dialog{
        width: 100%!important;
    } */

    [data-bs-theme="dark"] .mega-menu-share, 
    [data-bs-theme="dark"] .form-group textarea {
        background-color: #1b1b1b!important;
        border-color: 1px solid #1b1b1b!important;
    }

    [data-bs-theme="dark"] .mega-menu-share {
        color: #dedede!important;
    }

    /*form control is separate because of login dark theme*/
    
    [data-bs-theme="dark"] .form-control{
        background-color: #1b1b1b;
        border-color: 1px solid #1b1b1b!important;
    }

    [data-bs-theme="dark"] .modal-fullscreen .ai-input textarea,
    [data-bs-theme="dark"] .modal-fullscreen .ai-input input {
        background-color: #1b1b1b;
    }

    [data-bs-theme="dark"] .ai-input{
        box-shadow: 0 -12px 8px rgba(0, 0, 0, 0.2);
        /* background: #1b1b1b;
    	background-color: #1b1b1b; */
    }

    [data-bs-theme="dark"] .search-container {
        background-color: #1b1b1b;
    }

    [data-bs-theme="dark"] .center-container-small {
        background-color: #1b1b1b;
    }

    [data-bs-theme="dark"] .search-container .suggested .username {
        color: #0cf!important;
        font-weight: 400!important;
    }

    [data-bs-theme="dark"] .search-center .form-control{
        border: 2px solid #0cf!important;
        border-radius: 0.5rem!important;
    }
}

/*End of all phones.Landscape*/

/*A32 landscape*/

@media (min-width: 768px) and (max-width: 819px){

    [data-bs-theme="dark"] .postcard{
        border: 1px solid grey!important;
    }

    [data-bs-theme="dark"] .search-card .suggested .username{
        color: #0cf!important;
        font-size: calc(1rem * var(--koeff))!important;
    }

    [data-bs-theme="dark"] .search-card .tagline{
        color: white!important;
    }

    /*Modals*/

    [data-bs-theme="dark"] .alignflex .action-name {
        margin: 0.5rem!important;
        float: left;
    }
}

/*O ph. Portrait*/

@media (min-width: 360px) and (max-width: 370px) {
    [data-bs-theme="dark"] .post-username, 
    [data-bs-theme="dark"] .poststyle{
        -webkit-text-size-adjust: none!important;
        text-size-adjust: none!important;
    }

    [data-bs-theme="dark"] .poststyle{
        color: #fff!important;
    }

    [data-bs-theme="dark"] .modal-fullscreen .body-fullscreen-3{
        /* background: url(../images/consierge-greet2-dark.png); */
        background: none;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 118%;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0!important;
    }

    [data-bs-theme="dark"] .ai-textarea-mobile-n,
	[data-bs-theme="dark"] .modal-fullscreen-content textarea[name=createpost] {
        font-weight: 300!important;
    }
}

/*O ph. Landscape. That might not be needed*/

@media (min-width: 639px) and (max-width: 640px){
    [data-bs-theme="dark"] .post-username, 
    [data-bs-theme="dark"] .poststyle{
        font-size: calc(1.125rem * var(--koeff)); 
        -webkit-text-size-adjust: none!important;
        text-size-adjust: none!important;
    }
}

/*My tablet portrait. That might not be needed*/

@media (min-width: 416px) and (max-width: 540px){

    [data-bs-theme="dark"] .search-container{
        top: 1rem!important;
    }

    [data-bs-theme="dark"] .search-card .info-f {
        word-break: break-word;
        margin: 0;
        top: 51%;
    }

    /*Modals*/

    [data-bs-theme="dark"] .modal-dialog{
        border-radius: 0;
        background: #1d2733;
        color: white;
        padding: 1rem;
        margin: 0!important;
        box-shadow: none;
        width: 100%!important;
        max-height: 100vh;
        box-shadow: None!important;
    }

    [data-bs-theme="dark"] .modal-company-individual {
        width: 100%!important;
        max-width: 100%!important;
        text-align: left;
    }
}

@media (min-width: 416px) and (max-width: 900px){

    [data-bs-theme="dark"] .modal-company-individual {
        width: 100%!important;
        max-width: 100%!important;
        text-align: left;
        border-radius: 0;
        border: 1px solid #333;
    }
}

/*All ipads*/

@media (min-width: 720px) and (max-width: 969px) and (orientation: portrait) {
    [data-bs-theme="dark"] .modal-dialog{
        border-radius: 0px;
        box-shadow: none!important;
        margin: 0 auto!important;
        box-shadow: None!important;
    }

    [data-bs-theme="dark"] .modal-company-individual {
        width: 100%!important;
        max-width: 100%!important;
        text-align: left;
        margin: 0!important;
    }

    [data-bs-theme="dark"] .body-fullscreen-3{
        /* background: url(../images/consierge-greet2-dark-desktop.png); */
        background: none;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 40%;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0 !important;
    }

    [data-bs-theme="dark"] .btn-arrow:focus, .search-input-container .btn-arrow, 
    [data-bs-theme="dark"] .search-input-container .btn-arrow:active, 
    [data-bs-theme="dark"] .search-input-container .btn-arrow:focus, 
    [data-bs-theme="dark"] .search-input-container .btn-arrow:hover, 
    [data-bs-theme="dark"] .search-input-container .btn-arrow:visited{
        background-color: #1b1b1b;
        color: white;
    }
 
    /*End of all ipads portrait*/
}

@media (min-width: 951px) and (max-width: 1350px){

    [data-bs-theme="dark"] .search-container .form-control,
    [data-bs-theme="dark"] .search-center .form-control {
        border: 2px solid #0cf!important;
        background-color: none!important;
    }

    [data-bs-theme="dark"] .search-input-container .btn-arrow,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:hover,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:active,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:focus,
    [data-bs-theme="dark"] .btn-arrow:focus,
    [data-bs-theme="dark"] .search-input-container .btn-arrow:visited{
        background-color: transparent!important;
        border: none!important;
    }
}

/*The below is cover Large Samsung Phone or similar on Chrome created teretically on april 8 2024*/

@media (min-width: 1020px) and (max-width: 1022px){

    [data-bs-theme="dark"] .body-fullscreen-3{
		background: unset!important;
	}
}

/* NEW ORIENTATIONS DIAPOSONS NEEDED HERE BECAUSE IMAGES FOR AI AND EXPLORE ARE DIFFERENT */

/* All tablets in portrait */
/* Here interference with big landscape phones */

@media (min-width: 501px) and (max-width: 768px) and (orientation: portrait) {
    [data-bs-theme="dark"] .body-fullscreen-3 {
        height: 100vh!important;
        /* background: url(../images/consierge-greet2-dark-desktop.png) center bottom!important; */
        background: none;
        background-size: 50%!important;
        background-repeat: no-repeat!important;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    /* Contact us */

    /* for Sky tablet */
	[data-bs-theme="dark"] .about p  {
		display: inline; 
		font-size: calc(1rem * var(--koeff)); 
		font-weight: 350!important;
	}

    [data-bs-theme="dark"] .about p a {
		display: inline; 
		font-size: calc(1.1rem * var(--koeff)); 
		font-weight: 350!important;
	}

    [data-bs-theme="dark"] .search-container {
        background-color: #1b1b1b;
    }

    [data-bs-theme="dark"] .background-text, [data-bs-theme="dark"] .background-text-studio {
        color: rgba(0, 0, 0, 0.05);
    }
}

/* All Mini Ipads in portrait */

@media (min-height: 910px) and (max-height: 1025px) and (orientation: portrait) {
    [data-bs-theme="dark"] .body-fullscreen-3 {
        height: 100vh!important;
        /* background: url(../images/consierge-greet2-dark-desktop.png) center bottom; */
        background: none;
        background-size: 80%;
        background-repeat: no-repeat;
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

/* All Big ipads in portrait */

@media (min-height: 1026px) and (max-height: 1367px) and (orientation: portrait) {
    [data-bs-theme="dark"] .body-fullscreen-3 {
        height: 100vh;
        /* background: url(../images/consierge-greet2-dark-desktop.png) center bottom; */
        background: none;
        background-size: 70%;
        background-repeat: no-repeat;
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

/* All tablets in Landscape */

@media (min-height: 400px) and (max-height: 760px) and (orientation: landscape) {
    [data-bs-theme="dark"] .body-fullscreen-3 {
        height: 100vh;
        /* background: url(../images/consierge-greet2-dark-desktop.png) center bottom; */
        background: none;
        background-size: 45%;
        background-repeat: no-repeat;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100vh!important;
    }
}

/* PROGRAMMED DESKTOPS */

[data-bs-theme="dark"] .suggested-desktop .tagline-desktop, .suggested-desktop p {
    color: #fff;
}

/* Small laptops no monitor */

@media (min-width: 1px) and (max-width: 1200px) {
    [data-bs-theme="dark"] .consierge-desktop{
        background: url(../images/consierge-greet2-dark-desktop.png) center bottom;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 50%;
		height: 90vh;
		margin: 0;
		padding: 0!important;
    }

    .suggested-desktop p {
        display: block;
    }
}

/* Mac */

@media (min-width: 1201px) and (max-width: 1400px) {

    [data-bs-theme="dark"] .body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

    [data-bs-theme="dark"] .consierge-desktop{
        background: url(../images/consierge-greet2-dark-desktop.png) center bottom;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 50%;
		height: 92vh;
		margin: 0;
		padding: 0!important;
    }

    .suggested-desktop p {
        display: block;
    }
}

/* Volodin comp */

@media (min-width: 1401px) and (max-width: 1500px) {
    [data-bs-theme="dark"] .body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

    [data-bs-theme="dark"] .consierge-desktop{
        background: url(../images/consierge-greet2-dark-desktop.png) center bottom;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 50%;
		height: 92vh;
		margin: 0;
		padding: 0!important;
    }

    .suggested-desktop p {
        display: block;
    }
}

/* My comp */

@media (min-width: 1501px) and (max-width: 1600px) {
    [data-bs-theme="dark"] .body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

    [data-bs-theme="dark"] .consierge-desktop{
        background: url(../images/consierge-greet2-dark-desktop.png) center bottom;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 50%;
		height: 92vh;
		margin: 0;
		padding: 0!important;
    }

    .suggested-desktop p {
        display: block;
    }
}

/* Macs with big resolution */

@media (min-width: 1601px) and (max-width: 3000px) {
    [data-bs-theme="dark"] .body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

    [data-bs-theme="dark"] .consierge-desktop{
		height: 97vh;
		margin: 0;
		padding: 0!important;
    }

    .suggested-desktop p {
        display: block;
    }
}

/* Big computers */

@media (min-width: 3001px) and (max-width: 10000px) {
    [data-bs-theme="dark"] .body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

    [data-bs-theme="dark"] .consierge-desktop{
        background: url(../images/consierge-greet2-dark-desktop.png) center bottom;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 35%;
		height: 92vh;
		margin: 0;
		padding: 0!important;
    }

    .suggested-desktop p {
        display: block;
    }
}