
 @keyframes open {
  0%  {background-position: 166% -25%, 220% -69%;}
  100%{background-position: 120% -5%, 200% -40%;}
}

.margin-auto{
  margin: auto;
}

.field{
  margin: 5px;
  display: flex;
  flex-direction: column;
  }
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 1px;
  cursor: pointer;
  /* margin-bottom: 25px; */
  float: none;
}

.img-wrap{
  /* position: relative;
  width: 101px;
  height: 101px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ccc; */
  /*my lines below*/
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-upload:before{
  /* content: "\f093"; *//*What is it Barrimore?*/
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  text-align: center;
  /* color: #63d3a6; */
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  /* background-color: #fff; */
}
.img-upload:hover:before{
 opacity: 1;
}
img {
  /* width: auto; *//*that is off for images to take 100% in top posts*/
  /* height: 100%; *//*this is off for images have their real hight*/
}
 
input{
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}
input:focus{
  outline: none;
  border: 1px solid #64d488;
}
input::placeholder{
  color: #bebebe;
}
.name{ 
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  max-width: 220px;
  overflow-wrap: break-word;
}
.status{
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}
button{
  position: relative;
  color: #fff;
  letter-spacing: 1px;
  margin: auto;
  padding: .375rem .75rem;
  text-align: center;
  transition: 0.5s;
  border-radius: .25rem;
  cursor: pointer;
  border: none;
  background-color: #17a2b8;
}
/* .save{
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px  #63d3a6;
} */
.edit, .active{
  color: #fff;
  width: 180px;
}
button:hover{
  left: 0;
  color: #fff;
  /* width: 180px; */
  box-shadow: 0px 0px 20px 0px  #ccc;
}
button:focus{
  outline: none;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}


.fa-instagram{
  position: absolute;
  color: #79f1a4;
  top: 3%;
  right: 2%;
  font-size: 38px;
}
.fa-instagram:hover{
  font-size: 42px;
  color: #caff81;
  transition: all .1s linear;
  cursor: pointer;
}