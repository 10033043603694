.ai-input input {
	padding-left: 35px;
}

.ai-input .postReturner {
	position: absolute;
	top: 46% !important; 
	transform: translateY(-50%) !important;
	left: 0.5rem !important; 
	cursor: pointer;
}

.postReturner-img {
	vertical-align: middle !important; 
	transform: translateY(-50%) !important;
	position: absolute;
	color: rgb(26, 90, 185);
	font-size: 1.4rem
}