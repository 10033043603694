/* PLEASE READ BEFORE DITING */
/*NEW STYLES WITH ORIENTATION FOR CRITICAL CASES EXCEPT LOGIN BACKGRROUND IMAGE STYLES*/
/*Diapozons structured and separated from ipads and devices*/
/*All latest Desktops are here now controlled PROGRAMMATICALLY*/
/*Historically may overlap with previous diaposons*/

/*NO "LOGIN-FORM" styles should be here since "orientation landscape" break screen on devices*/
/*Login background image styles are in separate file*/

:root {
	--koeff : 1;
}

/* small phones in landscape */

@media (min-height: 1px) and (max-height: 361px) and (orientation: landscape) {
	
	.consierge{
		background: none!important;
		height: auto!important;
	}

	/* Login */

	.modal.show .modal-dialog-centered {
		top: 0!important;
	}

	/* Profile */

	.modal.show .modal-dialog {
		overflow: auto;
		width: 100% !important;
	}

	/* Profile */

	.profile-info h4{
		font-size: calc(1.5rem * var(--koeff));
	}

	/* Contact us */

	.about {
		padding: 2rem 3rem;
      margin: 0;
	}

	/* Navigation */

	.dropdown-menu, .dropdown-menu.show {
		width: 210px !important;
	}

	.dropdown-menu a, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff)); 
	}

	.info-f {
		padding-left: unset;
	}

	.username{
		/* Removing koeff here because when enlarged on app 
		it overflows the post container */
		font-size: 1rem!important;
	}
}

/* Bigger phones in landscape */

@media (min-height: 362px) and (max-height: 431px) and (orientation: landscape) {
	
	.consierge{
		background: none!important;
	}

	.consierge{
		height: auto!important;
	}

	/* Profile */

	.modal.show .modal-dialog {
		overflow: auto;
		width: 100%;
	}

	.profile-info h4{
		font-size: calc(1.5rem * var(--koeff));
	}

	/* Followers */

	.suggested .username {
		font-weight: 350;
	}

	/* Contact us */

	.about {
		padding: 2rem 3rem;
      margin: 0 6rem;
	}

	/* Navigation */

	.dropdown-menu a, .dropdown-menu.show a{
		font-size: calc(1rem * var(--koeff)); 
	}

	/* Login */

	.modal-dialog-centered {
		width: 60%!important;
		top: 2rem !important;
	}

	.info-f {
		padding-left: unset;
	}
	
	.username{
		/* Removing koeff here because when enlarged on app 
		it overflows the post container */
		font-size: 1rem!important;
	}
}

/* Huge phones in landscape */

@media (min-height: 432px) and (max-height: 460px) and (orientation: landscape) {
	
	.login-background .login-form{
		width: 29vw;
    	margin: 8% 0;
	}

	.info-f {
		padding-left: unset;
	}

	.username{
		/* Removing koeff here because when enlarged on app 
		it overflows the post container */
		font-size: 1rem!important;
	}
}
	
/* Small tablets in portrait. Sky */

@media (min-width: 432px) and (max-width: 599px) and (orientation: portrait) {

	.icon-count {
		/* For shared post it should be off */
		/* margin-top: 5px; */
	}

	/*New Concierge*/

	.modal-fullscreen .radio-container-parent{
		width: 75%;
	}
}

/* All tablets in portrait */

@media (min-width: 432px) and (max-width: 766px) and (orientation: portrait) {

	.consierge .right-div-profile{
		display: none;
	}

	.body-fullscreen-3 {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}
}

/* All ipads in portrait */

@media (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {

	/*AI*/
	
	.body-fullscreen-3 {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	/* Followers */

	.right-div, .right-div-profile {
		width: 100%;
		height: 115px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div, .right-div-home{
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
		font-size: calc(1rem * var(--koeff));
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card, .right-div-home .card{   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.right-div .tagline {
		display: none;
	}

	.search-card .tagline{
		display: block;
	}
	
	.username{
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.search-card .username{
		font-weight: 600;
	}

	.search-card p{
		font-weight: 400;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested, .right-div-home .suggested{
		padding: 0rem;
	}

	.post-div, .post-div-profile {
		width: 100vw;
		margin: 0 auto;
  	}

	.post-div{
		margin: auto;
	}

	.about {
		margin: 3rem;
	}

	.icon-count {
		margin-top: 1px;
	}
}


/* All tablets in Landscape */

/* ipad mini landscape requires 385 in emulator. wont contradict the phones because we dont have create post there */
/* Huge phone landscape*/

/*Ipad Mini landscape?*/

@media (min-height: 385px) and (max-height: 599px) and (orientation: landscape) {

	.body-fullscreen-3 {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	.consierge .profile-block {
		padding-top: 5rem;
  	}

	/* Contact us */

	.about {
		margin: 0;
	}

	/* Create post */

	.modal-fullscreen-content textarea[name=createpost] {
		min-height: 115px!important;
		/* seems no need for height */
	}

	.counter-d {
		line-height: 1.5;
		font-size: 1rem;
  	}

	.modal-dialog-centered {
		width: 60%!important;
		top: 6rem !important;
	}

	/*New concierge*/

	.modal-fullscreen .choice {
		padding-top: 0;
		margin-bottom: 0;
  	}

	.modal-fullscreen .radio-container-parent {
		width: 75%;
		margin: 1rem auto;
  	}

	.modal-fullscreen .radio-container-parent p {
		font-size: calc(0.75rem* var(--koeff)) !important;
	}

	.login-background .login-form{
		width: 40vw;
      margin: 3rem auto auto;
	}

	.profile-block, .profile-block-gotop {
		margin-bottom: 0;
	}
}

/* All ipads in landscape */

@media (min-height: 600px) and (max-height: 1025px) and (orientation: landscape) {

	/*AI*/
	
	.body-fullscreen-3 {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	.modal-fullscreen{
		position: fixed!important;
	}

	/* Followers */

  	.right-div, .right-div-profile {
		width: 100%;
		height: 115px;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		right: 0;
		top: 55px;
		background-color: #fff;
		position: fixed;
		padding: 0.5rem 0;
		z-index : 10;
		border-radius: 0;
	}

	.right-div, .right-div-home{
		padding: 0.5rem 0;
		margin-bottom: auto;
		margin-left: 0.75rem;
		border-radius: 0;
	}

	.scroll{
		width: 100%;
		height: 120px;
		right: 0;
	}

	div.scroll::-webkit-scrollbar {
		width: 2px;
		padding:0;
	}

	.right-div .ifollow, .right-div-profile .ifollow, .ifollow{
		margin: 0!important;
		padding: 0;
		font-size: calc(1rem * var(--koeff));
	}

	.ifollow p{
		margin-bottom: 0.5rem;
	}

	.right-div .card, .right-div-profile .card, .scroll .card, .right-div-home .card{   
		display: inline-block;
		zoom: 1;
		border: 0;
		max-width: 75px;
	}

	.right-div .card .avatar, .right-div-profile .card .avatar, .scroll .card .avatar{
		width: 60px!important;
		height: 60px;
	}

	.right-div .tagline {
		display: none;
	}

	.search-card .tagline{
		display: block;
	}
	
	.username{
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		overflow: hidden;
	}
	
	.search-card .username{
		font-weight: 600;
	}

	.search-card p{
		font-weight: 400;
	}

  	.suggested img {
		position: relative;
		margin-top: 0;
		align-items: center;
		margin: auto;
		display: flex;
	}

	.right-div .suggested, .right-div-profile .suggested, .scroll .suggested, .right-div-home .suggested{
		padding: 0rem;
	}

	.post-div, .post-div-profile {
		width: 100vw;
		margin: 0 auto;
  	}

	.post-div{
		margin: auto;
	}

	/* Contact us */

	.about {
		margin: 0;
	}

	/* Create post */
	/* not picked in emulator*/
	.modal-fullscreen-content textarea[name=createpost] {
		min-height: 115px!important;
		/* seems no need for height */
	}

	.modal-fullscreen .radio-container-parent{
		width: 75%;
		margin: 1rem auto;
	}

	.body-fullscreen-3 .radio-container-parent  .fixedHeight{
		/* height: 45px; */
		padding: 0.5rem;
		vertical-align: middle;
		display: grid;
		place-items: center;
	}

	.modal-fullscreen .radio-container-parent p{
		font-size: calc(0.85rem* var(--koeff))!important;
      line-height: 1;
		text-align: center;
		padding: unset;
		margin-bottom: 0.1rem!important;
	}

	.sharedPost .post-div {
		width: 100%;
	}

	.login-background  .login-form {
		margin: 25% 0;
	}

	.login-background .imagine {
		font-size: 3rem !important;
      top: calc(58% - 70px);
		position: absolute;
		left: 7%;
		margin: 0;
		text-align: left;
		font-size: 3.5rem;
		font-weight: 500;
		width: 100%;
	}

	/* End of All ipads in landscape */
}

/* All media devices */

@media (min-width: 1px) and (max-width: 1367px) {

	.btn-mea {
		border: 1px solid #fff !important;
	 	background-color: transparent !important;
	}
}



/* ALL DESKTOPS COMPUTERS FOR FOLLOWERS AND AI BACKGROUND IMAGE ON CREATE POST MODAL */
/* CONTROLLED PROGRAMATICALLY */
/* ALL CLASS NAMES MIST HAVE "-desktop" */

.login-background-desktop .login-form {
	width: 25vw;
	margin: 20% 0;
	float: right;
	/* display: flex; */
	/* height: 100vh; */
	position: relative;
	border: 1px solid #d9d9d9;
	padding: 1.5rem;
	background-color: rgb(0 88 129 / 26%);
}

.login-background-desktop h1 {
	color: #fff;
   font-size: 4rem;
	text-align: left;
	display: flex;
	align-items: center; /* Center vertically */
	justify-content: center; /* Center horizontally */
	height: 100vh; /* Full viewport height */
	margin: 0; /* Remove default body margin */
}

.login-background-desktop  .imagine{
	position: absolute; /* Position relative to the container */
	top: calc(58% - 100px);
	left: 7%; 
	text-align: left;
	font-size: 4rem;
	font-weight: 500;
	width: 100%;
}

.login-background-desktop .script, .login-background-desktop .script2{
	display: inline;
}

.after-app-wrap-center-div-desktop, .after-app-wrap-center-div-gotop-desktop {
	position: relative;
	margin: 0 auto;
	width: 70%;
	padding: 1rem;
	text-align: left;
	top: 55px;
	margin-bottom: 2rem;
} 

div.scroll-desktop::-webkit-scrollbar {
	width: 6px;
	padding:0;
}

.post-div-desktop {
	overflow: hidden;/*if I comment it helps Copy URL be on top*/
	position: relative;
	text-align: left;
	width: 63%;
	border-radius: 10px;
	padding-bottom: 0.175rem;
}

.right-div-desktop {
	text-align: left;
	border-radius: 10px;
	position: fixed;
	right: 15%;
	width: 25.9%;
}

.scroll-desktop {
	overflow: auto;
	height: 85vh;
	position: relative;
}

.post-div-profile-desktop {
	overflow: hidden;
	position: relative;
	text-align: left;
	width : 61.7%;
	margin-right: 0.5%;
}

.right-div-profile-desktop {
	position: relative;
	overflow: hidden;
	text-align: left;
	width : 37.3%;
	border-radius: 10px;
	height: auto;
	right: 0;
	top: 1.1rem;
	margin-bottom: auto;
}

.suggested-desktop{
	padding: 0.8rem;
}

.suggested-desktop button{
	appearance: none;
	background: 0;
}

.suggested-desktop img{
	position: relative;
}

.suggested-desktop p{
	margin: auto;	
}

.suggested-desktop a p{
	margin:auto;
	font-size: 15px;
}

.suggested-desktop .username-desktop {
 	font-weight: 500;
	color: #1663d6;
}

.suggested-desktop .tagline-desktop, .postcard .small{
 	color: #333;
}

.suggested-desktop .username-desktop, .suggested-desktop .tagline-desktop {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.tagline-desktop{
	display: block;
}

.body-fullscreen-3-desktop {
	height: 100vh;
	margin: 0;
	padding: 0!important;
}

.ifollow-desktop{
	font-family: 'Mea Culpa'; 
	src: url('../css/fonts/MeaCulpa-Regular.ttf') format('truetype'); 
	font-size: 2rem !important;
	font-weight: 400!important;
	color: #05b7e4;
}

/*New Concierge*/

.modal-fullscreen  .body-fullscreen-3-desktop .radio-container-parent{
 	width: 60%;
}

.modal-fullscreen .body-fullscreen-3-desktop .radio-container-parent  .fixedHeight{
	padding: 0.5rem;
	vertical-align: middle;
	display: grid;
	place-items: center;
}

.modal-fullscreen .body-fullscreen-3-desktop .form-control-radio p {
	font-size: calc(1rem* var(--koeff)) !important;
	text-align: center;
	padding: unset;
}

/*AI Background text*/

.modal-fullscreen .body-fullscreen-3-desktop .background-text{
	position: absolute;
   transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	transform-origin: bottom left;
	-webkit-transform-origin: bottom left;
	-moz-transform-origin: bottom left;
	left: calc(5% + 40px); 
	bottom: calc(50% - 270px);
	z-index: 0;  
	pointer-events: none;  
	display: block;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
}


.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
	position: absolute;
   transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
   transform-origin: bottom right;
	-webkit-transform-origin: bottom right;
	-moz-transform-origin: bottom right;
	right: calc(5% - 0px);
	bottom: calc(50% + 235px);
	z-index: 0;  
	pointer-events: none;  
	display: block;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
}

.modal-fullscreen .body-fullscreen-3-desktop .background-text,
.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
	font-size: 12rem;
}

.modal-fullscreen .body-fullscreen-3-desktop .studio {
	font-size: 10rem;
}

.modal-fullscreen .body-fullscreen-3-desktop .script{
	display: inline;
}

.sharedPost .post-div-desktop{
	margin: auto;
}

/* Small laptops no monitor */

@media (min-width: 1px) and (max-width: 1200px) {

	.after-app-wrap-center-div-desktop, .after-app-wrap-center-div-gotop-desktop {
		width: 80%; 
	}

	.post-div-desktop {
		width: 64.8%;
	}

	.right-div-desktop {
		width: 28.16%;
		right: 10%;
	}

	.post-div-profile-desktop {
		width: 64.8%;
	}

	.right-div-profile-desktop {
		width: 34.2%;
	}

	.suggested-desktop p, .suggested-desktop .username-desktop {
		text-align: left;
	}

	.suggested-desktop .tagline-desktop {
		display: block;
	}

	.body-fullscreen-3-desktop {
		background: none!important;
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	.nav-display-desktop{
		display: none;
	}

	.login-background-desktop .imagine{
		top: calc(58% - 80px);
		font-size: 3.5rem;
		left: 1%;
	}

	.login-background-desktop .login-form {
		width: 25vw; 
		margin: 14% 0;
		float: right;
		position: relative;
		border: 0.5px solid #d9d9d9;
		padding: 1.5rem;
	} 

	.modal-fullscreen .body-fullscreen-3-desktop .switch button {
		height: unset;
	}

	/*AI Background text*/

	.modal-fullscreen .body-fullscreen-3-desktop .background-text {
		left: calc(3% + 30px);
   	bottom: calc(50% - 230px);
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
		bottom: calc(50% + 180px);
		right: 3%;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text,
	.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
		font-size: 9rem;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .ai-consierge-response,
	.modal-fullscreen .body-fullscreen-3-desktop .ai-input {
		width: 70%;
		margin: auto;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .ai-input input {
		height: 50px;
	}
}

/* Mac */

@media (min-width: 1201px) and (max-width: 1400px) {
	
	.after-app-wrap-center-div-desktop, .after-app-wrap-center-div-gotop-desktop {
		width: 80%; 
	}
	.post-div-desktop {
		width: 64.8%;
	}

	.right-div-desktop {
		width: 28.16%;
		right: 10%;
	}

	.post-div-profile-desktop {
		width: 64.8%;
	}

	.right-div-profile-desktop {
		width: 34.2%;
	}

	.suggested-desktop p, .suggested-desktop .username-desktop,
	.search-card .suggested-desktop p, .search-card .suggested-desktop .username-desktop {
		text-align: left;
	}

	.suggested-desktop .tagline-desktop {
		display: block;
	}

	.nav-display-desktop{
		display: none;
	}

	/*AI*/
	
	.body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	/*AI Background text*/
	

	.modal-fullscreen .body-fullscreen-3-desktop .background-text {
		bottom: calc(50% - 288px);
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
		bottom: calc(50% + 235px);
	}
}

/* Volodin comp & Mac Air*/

@media (min-width: 1401px) and (max-width: 1500px) {
	
	.after-app-wrap-center-div-desktop, .after-app-wrap-center-div-gotop-desktop {
		width: 76%;
	} 

	.post-div-desktop {
		width: 61.7%;
	}

	.right-div-desktop {
		right: 12%;
		width: 29.1%;
	}

	/*AI*/
	
	.body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text,
	.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
		/* Chrome and Safari has 120px and 160px for 10rem on my mac*/
		/* font-size: 10rem; */
		font-size: 160px;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text{
		bottom: calc(50% - 280px);
	}
}

/* My comp */

@media (min-width: 1501px) and (max-width: 1600px) {

	.post-div-desktop {
		width: 63%;
	}

	.post-div-profile-desktop {
		margin-right: 1%;
	}

	.right-div-profile-desktop .card{
		margin: 0.5rem 0;
	}

	/*AI*/
	
	.body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text,
	.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
		font-size: 10rem;
	}
}

/* Macs with big resolution */
/*My mac*/

@media (min-width: 1601px) and (max-width: 3000px) {

	.login-background .login-form{
		margin: 25% 0;
	}
	
	.post-div-desktop {
		width: 63%;
	}

	.post-div-profile-desktop {
		margin-right: 1%;
	}

	.right-div-profile-desktop .card{
		margin: 0.5rem 0;
	}

	/*AI*/
	
	.body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}
	
	.modal-fullscreen .body-fullscreen-3-desktop .radio-container-parent {
		width: 40%;
  	}
  
	.modal-fullscreen .body-fullscreen-3-desktop .background-text{
		bottom: calc(50% - 300px);
		left: calc(5% + 100px);
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio{
		bottom: calc(50% + 300px);
		right: 2%;
	}
}

/* Huge computers */

@media (min-width: 3001px) and (max-width: 10000px) {
	
	.after-app-wrap-center-div-desktop, .after-app-wrap-center-div-gotop-desktop {
		width: 35%;
	} 

	.right-div-desktop {
		right: 32.5%;
		width: 13%;
	}

	/*new Login*/

	.login-background-desktop .login-form {
		width: 10vw;
		margin: 13% 0;
	}

	.login-background-desktop .imagine {
    	left: 3%;
		top: calc(58% - 150px);
	}

	/*AI*/

	.modal-fullscreen .switch {
		width: 10%;
  	}
	
	.body-fullscreen-3-desktop {
		height: 100vh;
		margin: 0;
		padding: 0!important;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .radio-container-parent {
			width: 25%!important;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text {
		left: 35%;
    	bottom: 20%;
	}

	.modal-fullscreen .body-fullscreen-3-desktop .background-text-studio {
		right: 31%;
     	bottom: 77%;
	}
}