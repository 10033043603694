/*LOGIN BACKGROUND IMAGE AND AFFECTED COLORS ONLY*/
/*All desktops and devices background image*/

.after-app-wrap-center-login-div-walk, 
.after-app-wrap-center-login-div-walk-sign {
   background: 
      /* linear-gradient(to bottom, #056dae 40%, #07a4e2 90%), 
      radial-gradient(at bottom right, rgba(6, 75, 110, 0.695) 30%, transparent 70%), */
      /* url('https://ocaiprod.s3.us-east-2.amazonaws.com/img/ocean-justwater2.png'); */
      url(../images/medeata-intro5.png) top;
   height: 100vh;
   width: 100%;
   background-size: cover;
   background-blend-mode: overlay;
   background-repeat: no-repeat;
   /* background-position: center; */
   /*the below needed for image cover the screen on desktop*/
   position: relative;
}  

.login-form * {
    opacity: 1;
}

.login-form .btn-imitation, .signup-form .btn-imitation, 
.login-form-row-no-label a, 
.a-login-signup {
   /* needs important because it overwrites default <a> color */
   color: #fff !important; /*"forgot password and sign up" on login screen, "login" on signup screen */
   font-weight: 400;
}

.login-form-row-no-label .info {
   /* color: #092854;  */
   /* Triangle */
   color: #fff;
   font-weight: 400;
}

